import { InjectionToken } from '@angular/core';

export const UFM_FEJLHAANDTERING_CONFIG = new InjectionToken<UfmFejlhaandteringConfig>('UFM_FEJLHAANDTERING_CONFIG');

export interface UfmFejlhaandteringConfig {
    environment?: {
        apiPropertyNavn?: string; // api = environment.api. Dvs. navn på api property i environment.json filen
        endpoint?: string // frontend-client-log
    };
    tekster?: {
        popupOverskrift?: string; // Fejl
        // tslint:disable-next-line: max-line-length
        popupIngenForbindelseTekst?: string; // Der er i øjeblikket ingen forbindelse til hjemmesiden. I tilfælde af fejlsøgning kan ID [kortSessionId] oplyses
        popupNetvaerksfejlTekst?: string; // Netværksfejl';
    };
}
