import { NgModule, Optional, SkipSelf, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';

import { UfmFuldmagtConfig, UFM_FULDMAGT_CONFIG } from './ufm-fuldmagt.config';
import { UfmFuldmagtComponent } from './ufm-fuldmagt/ufm-fuldmagt.component';
import { UfmFuldmagtAgerendeComponent } from './ufm-fuldmagt-agerende/ufm-fuldmagt-agerende.component';
import { UfmFuldmagtsValgComponent } from './ufm-fuldmagts-valg/ufm-fuldmagts-valg.component';
import { UfmFuldmagtstypeComponent } from './ufm-fuldmagts-valg/ufm-fuldmagts-type.component';
import { UfmFuldmagtManglerComponent } from './ufm-fuldmagt-mangler/ufm-fuldmagt-mangler.component';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { InterceptorAgerendeCpr } from './interceptor-agerendeCpr';

@NgModule({
  declarations: [
    UfmFuldmagtComponent, UfmFuldmagtAgerendeComponent, UfmFuldmagtsValgComponent, UfmFuldmagtstypeComponent, UfmFuldmagtManglerComponent
  ],
  imports: [CommonModule],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: InterceptorAgerendeCpr, multi: true }
  ],
  exports: [UfmFuldmagtComponent]
})
export class UfmFuldmagtModule {
  constructor(@Optional() @SkipSelf() parentModule?: UfmFuldmagtModule) {
    if (parentModule) {
      throw new Error(
        'UfmFuldmagtModule is already loaded. Import it in the AppModule only');
    }
  }

  static forRoot(ufmFuldmagtConfig?: UfmFuldmagtConfig): ModuleWithProviders<UfmFuldmagtModule> {
    return {
      ngModule: UfmFuldmagtModule,
      providers: [
        { provide: UFM_FULDMAGT_CONFIG, useValue: ufmFuldmagtConfig || {}}
      ]
    };
  }

}
