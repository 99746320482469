import { Component, OnInit, ViewChild, ElementRef, DoCheck } from '@angular/core';
import { Router } from '@angular/router';

import { UfmSessionConfigService } from '../ufm-session-config.service';
import { UfmSessionLoginService } from '../ufm-session-login.service';
import { UfmSessionConfig } from '../ufm-session.config';

@Component({
  templateUrl: './ufm-session-logaf.component.html',
  styleUrls: ['./ufm-session-logaf.component.scss'],
})
export class UfmSessionLogafComponent implements OnInit, DoCheck {

  @ViewChild('htmlOverskrift', { static: false }) htmlOverskrift: ElementRef;
  @ViewChild('htmlTekst1', { static: false }) htmlTekst1: ElementRef;
  @ViewChild('htmlTekst2', { static: false }) htmlTekst2: ElementRef;
  @ViewChild('htmlTekst3', { static: false }) htmlTekst3: ElementRef;
  @ViewChild('htmlLaesFlere', { static: false }) htmlLaesFlere: ElementRef;
  @ViewChild('htmlGodeRaad', { static: false }) htmlGodeRaad: ElementRef;
  private erUfmModulTeksterOpdateret = false;

  constructor(
    private router: Router,
    private ufmSessionConfigService: UfmSessionConfigService,
    private ufmSessionLoginService: UfmSessionLoginService) { }

  ngOnInit() {
    this.ufmSessionLoginService.erLoggetPaa().subscribe(erLoggetPaa => {
      if (erLoggetPaa) {
        // vi er IKKE logget af. Retur til forsiden så brugeren ikke er fejlinformeret
        this.router.navigate(['/']);
      }
    });
    this.ufmSessionConfigService.sessionKonfigurationOpdateret$.subscribe(opdateret => {
      if (opdateret) {
        this.opdaterTeksterPgaOpdateretKonfiguration();
      }
    });
  }

  ngDoCheck(): void {
    if (this.erUfmModulTeksterOpdateret || !this.htmlTekst1) {
      return;
    }
    this.erUfmModulTeksterOpdateret = true;
    this.opdaterTeksterPgaOpdateretKonfiguration();
  }

  private opdaterTeksterPgaOpdateretKonfiguration(): void {
    const ufmSessionConfig: UfmSessionConfig = this.ufmSessionConfigService.getSessionConfig();
    if (ufmSessionConfig && ufmSessionConfig.tekster) {
      if (ufmSessionConfig.tekster.logaf) {
        if (ufmSessionConfig.tekster.logaf.overskrift) {
          this.htmlOverskrift.nativeElement.innerText = ufmSessionConfig.tekster.logaf.overskrift;
        }
        if (ufmSessionConfig.tekster.logaf.tekst1) {
          this.htmlTekst1.nativeElement.innerText = ufmSessionConfig.tekster.logaf.tekst1;
        }
        if (ufmSessionConfig.tekster.logaf.tekst2) {
          this.htmlTekst2.nativeElement.innerText = ufmSessionConfig.tekster.logaf.tekst2;
        }
        if (ufmSessionConfig.tekster.logaf.tekst3) {
          this.htmlTekst3.nativeElement.innerText = ufmSessionConfig.tekster.logaf.tekst3;
        }
      }
    }
    if (ufmSessionConfig.tekster.sideLaesFlere) {
      this.htmlLaesFlere.nativeElement.innerText = ufmSessionConfig.tekster.sideLaesFlere;
    }
    if (ufmSessionConfig.tekster.sideGodeRaad) {
      this.htmlGodeRaad.nativeElement.innerText = ufmSessionConfig.tekster.sideGodeRaad;
    }
    if (ufmSessionConfig.tekster.sideGodeRaadLink) {
      this.htmlGodeRaad.nativeElement.href = ufmSessionConfig.tekster.sideGodeRaadLink;
    }
  }

}
