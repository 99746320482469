import {Component, Input} from '@angular/core';
import {UntypedFormGroup} from '@angular/forms';

@Component({
  selector: 'app-upload-gdpr',
  templateUrl: './upload-gdpr.component.html',
  styleUrls: ['./upload-gdpr.component.scss']
})
export class UploadGdprComponent {

  @Input() form: UntypedFormGroup;

  constructor() { }

}
