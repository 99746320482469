import { Injectable } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';

import {Ansoegningstype} from '../../../utils/ansoegning/ansoegningstype.enum';

@Injectable({
  providedIn: 'root'
})
export class StopArbejdeService {

  constructor(private formBuilder: UntypedFormBuilder) { }

  initialiserAnsoegningFormGroup(): UntypedFormGroup {
    return this.formBuilder.group({
      ansoegning: 'upload',
      ansoegningstype: Ansoegningstype.UploadStopArbejde,
      stopArbejde: this.formBuilder.group({
        sidsteArbejdsdag: [null, Validators.required ],
        bemaerkninger: null,
        godkendt: [null, Validators.requiredTrue]
      })
    });
  }

}
