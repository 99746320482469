import { Component, OnInit, Input, OnChanges } from '@angular/core';

import { AccordionStatus } from '../accordion-status/accordion-status.enum';

@Component({
  selector: 'app-accordion-tekst',
  templateUrl: './accordion-tekst.component.html',
  styleUrls: ['./accordion-tekst.component.scss']
})
export class AccordionTekstComponent implements OnInit, OnChanges {

  @Input() status: AccordionStatus;
  @Input() tekst: string;
  @Input() vis: boolean;

  class: string;

  constructor() { }

  ngOnInit() {
    this.saetTekstStyle();
  }

  ngOnChanges() {
    this.saetTekstStyle();
  }

  private saetTekstStyle(): void {
    if (this.status === AccordionStatus.Fejl) {
      this.class = 'text-danger pl-0';
    } else {
      this.class = null ;
    }
  }

}
