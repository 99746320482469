import {Component, ElementRef, Input, OnChanges, ViewChild} from '@angular/core';
import {foldudAmination} from '../../animationer/animationer';
import {SmartScrollService} from '../smart-scroll/smart-scroll.service';
import {SmartScrollParameter} from '../smart-scroll/smart-scroll-parameter';
import {timer} from 'rxjs';

const foldud = 'foldud';
const foldsammen = 'foldsammen';

@Component({
  selector: 'app-fold-ud',
  templateUrl: './fold-ud.component.html',
  styleUrls: ['./fold-ud.component.scss'],
  animations: [foldudAmination]
})
export class FoldUdComponent implements OnChanges {

  @Input() vis: boolean;
  foldudJaAnimation: string = foldsammen;
  private foldudJaVis = false;
  private smartScrollParameter: SmartScrollParameter;
  @ViewChild('topElement', {static: false}) private topElement: ElementRef;
  @ViewChild('bottomElement', {static: false}) private bottomElement: ElementRef;

  constructor(private smartScrollService: SmartScrollService) {}

  ngOnChanges(): void {
    if (this.vis === undefined) {
      return;
    }
    if (this.vis) {
      this.lavAnimation('J');
    } else {
      this.lavAnimation('N');
    }
  }

  private lavAnimation(vis: string): void {
    const nyFoldudVis = vis === 'J';
    if (this.foldudJaVis === nyFoldudVis) {
      return;
    }
    if (nyFoldudVis) {
      this.foldudJaVis = true;
      timer(0).subscribe(() => {
        this.foldudJaAnimation = foldud;
        timer(400).subscribe(() => {
          // smart scroll into view
          this.smartScrollParameter = {
            top: this.topElement,
            bund: this.bottomElement,
            count: 0
          };
          this.smartScrollService.startSmartScroll(this.smartScrollParameter);
        });
      });
    } else {
      this.foldudJaVis = false;
      timer(450).subscribe(() => {
        this.foldudJaAnimation = foldsammen;
      });
    }
  }

  erElementSynligt(element: HTMLElement): boolean {
    return this.smartScrollService.erElementSynligt(element);
  }

  erBundSynlig(erSynligt: boolean): void {
    return this.smartScrollService.erBundSynlig(erSynligt, this.smartScrollParameter);
  }

  erTopSynlig(erSynligt: boolean): void {
    return this.smartScrollService.erTopSynlig(erSynligt, this.smartScrollParameter);
  }
  
}
