import { Injectable } from '@angular/core';
import { UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { SendAnsoegningService } from '../../utils/service/send-ansoegning.service';

@Injectable({
  providedIn: 'root'
})
export class KvitteringGuard  {

  constructor(private sendAnsoegningService: SendAnsoegningService, private router: Router) { }

  canActivate(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (this.sendAnsoegningService.hentSendtAnsoegning()) {
      return true;
    } else {
      this.router.navigate(['/']);
      return false;
    }
  }

}
