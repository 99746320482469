import { Component, OnInit, OnDestroy, Input, Output, EventEmitter, DoCheck, ViewChild, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';

import { UfmFuldmagtBruger } from '../ufm-fuldmagt-bruger';
import { UfmFuldmagtConfigService } from '../ufm-fuldmagt-config.service';
import { UfmFuldmagtConfig } from '../ufm-fuldmagt.config';
import { UfmFuldmagtService } from '../ufm-fuldmagt.service';
import { UfmSessionLoginService, UfmSessionLogPaaStatus } from '@ufmit/ufm-session';


@Component({
  selector: 'ufm-fuld-valg',
  templateUrl: './ufm-fuldmagts-valg.component.html',
  styleUrls: ['./ufm-fuldmagts-valg.component.scss']
})
export class UfmFuldmagtsValgComponent implements OnInit, DoCheck, OnDestroy {

  @Input() fuldmagtsBruger: UfmFuldmagtBruger;
  @Input() priv: string;
  @Output() skiftAgerendeBruger$: EventEmitter<boolean> = new EventEmitter<boolean>();

  erLoggetPaa = false;
  private subscriptions: Subscription[] = [];
  private selectedCpr: string = null;
  @ViewChild('htmlOverskrift', { static: false }) htmlOverskrift: ElementRef;
  @ViewChild('htmlCvrAgererSomFuldmagtshaver', { static: false }) htmlCvrAgererSomFuldmagtshaver: ElementRef;
  @ViewChild('htmlVaelgPerson', { static: false }) htmlVaelgPerson: ElementRef;
  @ViewChild('htmlDuAgererSomDigSelv', { static: false }) htmlDuAgererSomDigSelv: ElementRef;
  @ViewChild('htmlAnnullerKnap', { static: false }) htmlAnnullerKnap: ElementRef;
  @ViewChild('htmlLogAfKnap', { static: false }) htmlLogAfKnap: ElementRef;
  @ViewChild('htmlVaelgKnap', { static: false }) htmlVaelgKnap: ElementRef;
  @ViewChild('htmlIngenFuldmagt', { static: false }) htmlIngenFuldmagt: ElementRef;
  private erUfmModulTeksterOpdateret = false;

  constructor(
    private router: Router,
    private ufmFuldmagtConfigService: UfmFuldmagtConfigService,
    private ufmFuldmagtService: UfmFuldmagtService,
    private ufmSessionLoginService: UfmSessionLoginService) { }

  ngOnInit() {
    this.subscriptions.push(
      this.ufmFuldmagtService.getCachedFuldmagtsBruger(this.priv).subscribe(
        fuldmagtsBruger => this.fuldmagtsBruger = fuldmagtsBruger)
    );
    this.subscriptions.push(
      this.ufmSessionLoginService.loggetPaa$.subscribe(loggetPaa => {
        this.erLoggetPaa = (loggetPaa === UfmSessionLogPaaStatus.LoggetPaa);
      })
    );
    this.ufmFuldmagtConfigService.fuldmagtKonfigurationOpdateret$.subscribe(opdateret => {
      if (opdateret) {
        this.opdaterTeksterPgaOpdateretKonfiguration();
      }
    });
  }

  ngDoCheck(): void {
    if (this.erUfmModulTeksterOpdateret || !this.htmlOverskrift) {
      return;
    }
    this.erUfmModulTeksterOpdateret = true;
    this.opdaterTeksterPgaOpdateretKonfiguration();
  }

  private opdaterTeksterPgaOpdateretKonfiguration(): void {
    const ufmFuldmagtConfig: UfmFuldmagtConfig = this.ufmFuldmagtConfigService.getFuldmagtConfig();
    if (ufmFuldmagtConfig.tekster.fuldmagtsValg.overskrift) {
      this.htmlOverskrift.nativeElement.innerText = ufmFuldmagtConfig.tekster.fuldmagtsValg.overskrift;
    }
    if (ufmFuldmagtConfig.tekster.fuldmagtsValg.cvrAgererSomFuldmagtshaver) {
      this.htmlCvrAgererSomFuldmagtshaver.nativeElement.innerText =
        ufmFuldmagtConfig.tekster.fuldmagtsValg.cvrAgererSomFuldmagtshaver;
    }
    if (ufmFuldmagtConfig.tekster.fuldmagtsValg.vaelgPerson) {
      this.htmlVaelgPerson.nativeElement.innerText = ufmFuldmagtConfig.tekster.fuldmagtsValg.vaelgPerson;
    }
    if (ufmFuldmagtConfig.tekster.fuldmagtsValg.duAgererSomDigSelv) {
      this.htmlDuAgererSomDigSelv.nativeElement.innerText = ufmFuldmagtConfig.tekster.fuldmagtsValg.duAgererSomDigSelv;
    }
    if (ufmFuldmagtConfig.tekster.fuldmagtsValg.annullerKnap) {
      this.htmlAnnullerKnap.nativeElement.innerText = ufmFuldmagtConfig.tekster.fuldmagtsValg.annullerKnap;
    }
    if (ufmFuldmagtConfig.tekster.fuldmagtsValg.logAfKnap) {
      this.htmlLogAfKnap.nativeElement.innerText = ufmFuldmagtConfig.tekster.fuldmagtsValg.logAfKnap;
    }
    if (ufmFuldmagtConfig.tekster.fuldmagtsValg.vaelgKnap) {
      this.htmlVaelgKnap.nativeElement.innerText = ufmFuldmagtConfig.tekster.fuldmagtsValg.vaelgKnap;
    }
    if (ufmFuldmagtConfig.tekster.fuldmagtsValg.ingenFuldmagt) {
      this.htmlIngenFuldmagt.nativeElement.innerText = ufmFuldmagtConfig.tekster.fuldmagtsValg.ingenFuldmagt;
    }
  }

  ngOnDestroy() {
    let s: Subscription;
    while (this.subscriptions.length) {
      s = this.subscriptions.shift();
      if (!s.closed) {
        s.unsubscribe();
      }
    }
  }

  onSelectionChange(cpr: string) {
    this.selectedCpr = cpr;
  }

  vaelg() {
    const agerendeCpr: string = this.fuldmagtsBruger.getAgerendeCpr();
    if (this.selectedCpr || agerendeCpr) {
      const cpr = this.selectedCpr == null ? agerendeCpr : this.selectedCpr;
      this.fuldmagtsBruger.setAgerendeCpr(cpr);
      this.ufmFuldmagtService.fuldmagtsBruger$.next(this.fuldmagtsBruger);
      this.skiftAgerendeBruger$.emit(true);
      this.router.navigate(['/'], { queryParams: { random: Math.random() } });
    }
  }

  annuller() {
    this.skiftAgerendeBruger$.emit(false);
  }

  logAf() {
    this.ufmSessionLoginService.logAf();
  }

}
