import {Component, OnInit, OnDestroy, Inject, LOCALE_ID} from '@angular/core';
import {timer, Subscription} from 'rxjs';
import {delay, map, distinctUntilChanged, filter, mergeMap, tap} from 'rxjs/operators';
import {ToastrService} from 'ngx-toastr';
import {Router} from '@angular/router';

import {environment} from '../../../environments/environment';

import {AppService} from '../../app.service';
import {SendAnsoegningService} from '../../forretnings-sider/utils/service/send-ansoegning.service';
import {UfmFuldmagtService} from '@ufmit/ufm-fuldmagt';
import {UfmSessionLoginService, UfmSessionLogPaaStatus} from '@ufmit/ufm-session';
import {UfmUserTrackingService} from '@ufmit/ufm-user-tracking';
import {UploadService} from '../../forretnings-sider/upload/upload.service';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit, OnDestroy {

  isCollapsed = true;
  brugernavn: string = null;
  private subscriptions: Subscription[] = [];
  visLogPaa: string;
  visLogAf: string;

  ufmLogo: string;
  constructor(
    private appService: AppService,
    private sendAnsoegningService: SendAnsoegningService,
    @Inject(LOCALE_ID) private localeId: string,
    private ufmSessionLoginService: UfmSessionLoginService,
    private router: Router,
    private toastrService: ToastrService,
    private ufmFuldmagtService: UfmFuldmagtService,
    private ufmUserTrackingService: UfmUserTrackingService,
    private uploadService: UploadService
  ) {
    this.ufmLogo = 'assets/img/Logo_UFM_UK_Hvid.png';
  }

  ngOnInit() {
    this.subscriptions.push(
      this.ufmFuldmagtService.fuldmagtsBruger$.pipe(
        map((ufmFuldmagtBruger) => {
          if (ufmFuldmagtBruger && ufmFuldmagtBruger.agerendeCpr) {
            return ufmFuldmagtBruger.agerendeCpr;
          } else {
            return null;
          }
        }),
        distinctUntilChanged(),
        filter((agerendeCpr) => (agerendeCpr ? true : false)),
        mergeMap(() => {
          return this.uploadService.hentProfil();
        }),
        map(() => {
          return this.uploadService.hentUploadProfil.navn;
        })
      ).subscribe((navn) => {
        this.brugernavn = navn;
      })
    );

    // fix oplæsning af menu links i firefox og edge
    timer(0).subscribe(() => {
      document.getElementById('navbarNavDropdown').setAttribute('aria-hidden', 'false');
    });
    this.subscriptions.push(
      this.ufmSessionLoginService.loggetPaa$.subscribe((erLoggetPaa) => {
        if (erLoggetPaa === UfmSessionLogPaaStatus.LoggetPaa) {
          this.visLogPaa = 'd-block';
          this.visLogAf = 'd-none';
        } else {
          this.visLogPaa = 'd-none';
          this.visLogAf = 'd-block';
        }
      })
    );
  }

  ngOnDestroy() {
    let s: Subscription;
    while (this.subscriptions.length) {
      s = this.subscriptions.shift();
      s.unsubscribe();
    }
  }

  logPaa(): boolean {
    this.ufmSessionLoginService.logPaa();
    return false;
  }

  logAf(): boolean {
    this.ufmSessionLoginService.logAf();
    this.brugernavn = null;
    return false;
  }

  minSU(): boolean {
    this.ufmUserTrackingService.sendUrl('/minSU');
    window.location.href = environment.minsuUrl;
    return false;
  }

}
