<form [formGroup]="form">
  <div class="row">
    <div class="col-12">
      <h3>You are on holiday</h3>
    </div>

    <div class="col-12 pt-3">
      <span>
        If you receive SU because you are a worker in Denmark under EU law,
        you must submit documentation of your holiday here.
        You must upload the documentation and state holiday periods.
      </span>
    </div>

    <div class="col-12 pt-3">
      You must upload a statement of truth from your employer, which specifies the exact holiday period.
      <br />
      <a target="_blank" href="https://su.dk/10964">
         Read more about the conditions for holiday and status as a worker here
      </a>
    </div>

    <div class="col-12 pt-3">
      <div class="form-check">
        <input id="ydeligereInstruktioner" name="ydeligereInstruktioner" type="checkbox" class="form-check-input" formControlName="ydeligereInstruktioner" />
        <label for="ydeligereInstruktioner" class="form-check-label">
          Show detailed upload instructions
        </label>
      </div>
    </div>

    <app-fold-ud class="col-12" [vis]="ydeligereInstruktionerJaVis">
      <div class="row">
        <div class="col-12">
          <fieldset class="border p-2">
            <legend class="float-none w-auto">Detailed instructions</legend>
            <span>
              The documentation must be a file in PDF format. If the file is in another format, you have to convert the file into a PDF format before you upload it. You can only upload one file. If the documentation consists of more than one file, you must convert it into one file.
            </span>
            <br />
            <br />
            <span>
              The holiday periods, which you state, must also appear from your uploaded documentation. When you state which holiday periods you submit documentation of, you must fill in a start date (as of) and an end date (up to and including). If you only take one day of holiday, you must pick the same date as start and end date. You can state up to three holiday periods at a time. If you want to inform us about more than three holiday periods, you must submit the documentation via the form once more.
            </span>
          </fieldset>
        </div>
      </div>
    </app-fold-ud>

    <div class="col-12 pt-3" #uploadLabel>
      * Submit documentation of holiday (max 20MB in PDF format)
    </div>

    <div class="d-none" #uploadAriaLabelTilfoejFiler>
      Add more files with documentation
    </div>
    <div class="d-none" #uploadAriaLabelFil>
      Upload file with documentation
    </div>
    <div class="d-none" #uploadManglerTekst>
      Documentation for holiday is missing
    </div>
    <app-fil
      class="col-12 py-3"
      [form]="form.controls.feFerieDok"
      [index]="0"
      [ariaLabel]="uploadAriaLabelFil.innerText"
      [fjern]="'Remove'"
    >
    </app-fil>

    <div class="col-12 pt-3">
      Fill out the start and end date of each holiday period. You can add up 2 more holiday periods. (3 in total).
    </div>
    <div class="d-none" #fradatoOverskrift>
      From date
    </div>
    <div class="d-none" #tildatoOverskrift>
      To date
    </div>
    <app-ferie-perioder
      class="col-12 pt-3"
      [form]="form.controls.feriePerioder"
      [id]="'feriePerioder'"
      [labelFradato]="fradatoOverskrift.innerHTML"
      [labelTildato]="tildatoOverskrift.innerHTML"
    >
    </app-ferie-perioder>

    <div class="col-12 pt-3">
      <div class="form-check">
        <input id="ydeligereBemaerkninger" name="ydeligereBemaerkninger" type="checkbox"
               class="form-check-input"
               formControlName="ydeligereBemaerkninger" />
        <label for="ydeligereBemaerkninger" class="form-check-label">
          I have comments on my documentation of holiday
        </label>
      </div>
    </div>

    <app-fold-ud class="col-12" [vis]="ydeligereBemaerkningerJaVis">
      <div class="row">
        <div class="col-12">
          <fieldset class="border p-2">
            <legend class="float-none w-auto">Comment</legend>
            <span>
              Here, you have the opportunity to make a brief comment on your holiday documentation. Your comment will be filed on your case and you will not receive an answer to your comment. You cannot ask questions or inform us about other changes regarding your work or SU.<strong>(Max 250 characters)</strong>
            </span>
            <br />
            <br />
            <span>
              Example: You have previously submitted documentation of three weeks of holiday in July as agreed with your employer. Your holiday is changed to August and you therefore submit documentation of your new holiday period and state in the commentary box that the previous submitted documentation is no longer valid.
            </span>
            <textarea type="text" id="bemaerkninger" maxlength="250" class="md-textarea md-textarea-auto form-control" formControlName="bemaerkninger">
            </textarea>
            <span class="float-right form-text text-muted">{{ bemaerkningerTaeller$ | async }}</span>
          </fieldset>
        </div>
      </div>
    </app-fold-ud>

    <app-upload-gdpr class="col-12" [form]="form"></app-upload-gdpr>

    <div class="col-12 pt-3">
      <button (click)="sendAnsoegning()" type="submit" [disabled]="form.invalid || senderAnsoegning" class="btn btn-primary btn-lg btn-block">
        Submit documentation
      </button>
    </div>
    <div *ngIf="senderAnsoegning" class="col-12 pt-3 text-center">
      <fa-icon [icon]="faSpinner" [spin]="true" [size]="'2x'" [pulse]="true"></fa-icon>
    </div>
    <div *ngIf="senderAnsoegning" class="col-12 pt-3 text-center" role="alert">
      Submitting documentation (may take several minutes)
    </div>
  </div>
</form>
