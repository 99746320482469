<div class="row">
  <div class="col-12" role="heading" aria-level="2">
    <h3>Submit information and documentation concerning changes to your circumstances as an EU worker</h3>
  </div>

  <div *ngIf="dioErLukket" class="col-12 pt-5">
    <strong class="ufm-black">
      We are experiencing technical difficulties. Therefore, you are not able to submit
      documentation as EU worker via MinSU at the moment.
      Please try again later.
    </strong>
  </div>

  <div *ngIf="!dioErLukket" class="col-12" role="heading" aria-level="3">
    <div class="row">
      <div class="col-12 pt-3">
        Here you can find information about what to submit if your circumstances related to your equal status as EU
        worker change.
      </div>
      <div class="col-12 pt-2">
        Please see below what to submit depending on the change in question.
      </div>
    </div>
  </div>

  <div *ngIf="!dioErLukket" class="col-12 pt-5">
    <ul role="menu" class="list-group">
      <li role="menuitem" class="list-group-item border-0 px-0 py-3">
        <button (click)="startNytArbejde()" type="submit" id="startNytArbejde" class="btn btn-primary btn-lg d-block w-100">
          You get a new job
        </button>
      </li>
      <li role="menuitem" class="list-group-item border-0 px-0 py-3">
        <button (click)="startStopperArbejde()" type="submit" id="startStopperArbejde" class="btn btn-primary btn-lg d-block w-100">
          You stop working
        </button>
      </li>
      <li role="menuitem" class="list-group-item border-0 px-0 py-3">
        <button (click)="startUfrivilligArbejdsloes()" type="submit" id="startUfrivilligArbejdsloes" class="btn btn-primary btn-lg d-block w-100">
          You become involuntarily unemployed
        </button>
      </li>
      <li role="menuitem" class="list-group-item border-0 px-0 py-3">
        <button (click)="startUarbejdsdygtig()" type="submit" id="startUarbejdsdygtig" class="btn btn-primary btn-lg d-block w-100">
          You become ill
        </button>
      </li>
      <li role="menuitem" class="list-group-item border-0 px-0 py-3">
        <button (click)="startFerie()" type="submit" id="startFerie" class="btn btn-primary btn-lg d-block w-100">
          You are on holiday
        </button>
      </li>
      <li role="menuitem" class="list-group-item border-0 px-0 py-3">
        <button (click)="startOevrigeHenvendelser()" type="submit" id="startOevrigHenvendelse" class="btn btn-primary btn-lg d-block w-100">
          Other changes to your circumstances as an EU worker
        </button>
      </li>
    </ul>
  </div>
</div>
