import {Injectable} from '@angular/core';
import {UntypedFormGroup, ValidatorFn, Validators, UntypedFormBuilder, ValidationErrors} from '@angular/forms';
import {HttpClient} from '@angular/common/http';

import {Ansoegningstype} from '../../../utils/ansoegning/ansoegningstype.enum';
import {SendAnsoegningService, ValiderMindstEnFil} from '../../../utils/service/send-ansoegning.service';

const ValiderLoenOplysninger: ValidatorFn = (fg: UntypedFormGroup) => {
    const loenKr: string = fg.controls.loenKr.value;
    const timeEllerMaanedsLoen: string = fg.controls.timeEllerMaanedsLoen.value;
    const antalTimerPrUge: string = fg.controls.antalTimerPrUge.value;
    const fejl: ValidationErrors = {};

    if (antalTimerPrUge == null || antalTimerPrUge === '') {
      fejl.antalTimerPrUge = {required: true};
    } else {
      // converter antalTimerPrUge til number
      // tslint:disable-next-line:radix
      const antalTimerPrUgeNumber = Number.parseInt(antalTimerPrUge);
      if (antalTimerPrUgeNumber === 0) {
        fejl.antalTimerPrUge = {interval: true};
      }
    }

    if (timeEllerMaanedsLoen == null || timeEllerMaanedsLoen === '') {
      fejl.timeEllerMaanedsLoen = {required: true};
    }

    if (timeEllerMaanedsLoen !== 'v') {
      if (loenKr == null || loenKr === '') {
        fejl.loenKr = {required: true};
      } else {
        // converter loenkr til number
        // tslint:disable-next-line:radix
        const loenkrNumber = Number.parseInt(loenKr);
        if (loenkrNumber === 0) {
          fejl.loenKr = {interval: true};
        }
      }
    }

    if (Object.keys(fejl).length) {
      return fejl;
    }
};

const ValiderLoenOplysningerTidligereArbejde: ValidatorFn = (fg: UntypedFormGroup) => {
    const harStadigvaekTidligereArbejde: boolean = fg.controls.harStadigvaekTidligereArbejde.value;

    if (harStadigvaekTidligereArbejde) {
      return ValiderLoenOplysninger(fg);
    }
};

@Injectable({
  providedIn: 'root'
})
export class NytArbejdeService {

  private pdfKvittering: Blob;

  constructor(private formBuilder: UntypedFormBuilder, private http: HttpClient, private sendAnsoegningService: SendAnsoegningService) {}

  initialiserAnsoegningFormGroup(): UntypedFormGroup {
    return this.formBuilder.group({
      ansoegningstype: Ansoegningstype.UploadNytArbejde,
      nytArbejde: this.formBuilder.group({
        loenoplysninger: this.formBuilder.group({
          antalTimerPrUge: null,
          timeEllerMaanedsLoen: [null, Validators.required],
          loenKr: null
        }, {validators: [ValiderLoenOplysninger]}),
        ansaettelseTidsbegraenset: null,
        arbejdsgiver: [null, [Validators.required]],
        cvrnummer: [null, [Validators.pattern(/[0-9]{8}/)]],
        startdato: [null, [Validators.required]],
        filer: this.formBuilder.array([this.sendAnsoegningService.nyFil('ansaettelseskontrakt')], [ValiderMindstEnFil]),
        loenoplysningerTidligereArbejde: this.formBuilder.group({
          harStadigvaekTidligereArbejde: null,
          antalTimerPrUge: null,
          timeEllerMaanedsLoen: null,
          loenKr: null
        }, {validators: [ValiderLoenOplysningerTidligereArbejde]}),
        bemaerkninger: null,
        godkendt: [null, Validators.requiredTrue]
      }),
    });
  }
}
