<div class="row">
  <div class="col-12">
    {{ hours }}<strong class="ufm-black">{{ loenoplysninger.antalTimerPrUge }}</strong>
  </div>

  <div class="col-12" *ngIf="loenoplysninger.timeEllerMaanedsLoen === 't'">
    {{ wage }}<strong class="ufm-black">DKK. {{ loenoplysninger.loenKr }} per hour</strong>
  </div>

  <div class="col-12" *ngIf="loenoplysninger.timeEllerMaanedsLoen === 'm'">
    {{ wage }}<strong class="ufm-black">DKK. {{ loenoplysninger.loenKr }} per month</strong>
  </div>

  <div class="col-12" *ngIf="loenoplysninger.timeEllerMaanedsLoen === 'v'">
    {{ wage }}<strong class="ufm-black">{{ variable }}</strong>
  </div>
</div>
