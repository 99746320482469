import { Component, OnInit, Input, ViewChild, ElementRef, EventEmitter, OnChanges } from '@angular/core';
import { UntypedFormGroup, UntypedFormArray } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';

import { AppService } from '../../../../app.service';
import {SendAnsoegningService} from '../../service/send-ansoegning.service';

@Component({
  selector: 'app-fil',
  templateUrl: './fil.component.html',
  styleUrls: ['./fil.component.scss']
})
export class FilComponent implements OnInit, OnChanges {

  @Input() form: UntypedFormGroup;
  @Input() index: number;
  @Input() ariaLabel: string;
  @Input() fjern: string;
  @ViewChild('fil', { static: false }) private filElement: ElementRef;

  id: string;
  stoerrelse: string;
  fakefilnavn = {
    'white-space': 'nowrap',
    overflow: 'hidden',
    'text-overflow': 'ellipsis'
  };

  constructor(private toastrService: ToastrService, private appService: AppService, private sendAnsoegningService: SendAnsoegningService) { }

  ngOnInit() {
    this.id = this.form.controls.gruppe.value + '_fil' + this.index;
    this.saetStoerrelse();
  }

  ngOnChanges() {
    this.id = this.form.controls.gruppe.value + '_fil' + this.index;
    this.saetStoerrelse();
  }

  private saetStoerrelse() {
    if (!this.form.controls.data.value) {
      this.stoerrelse = null ;
    } else {
      const kb: number = 2 ** 10;
      const mb: number = 1024 ** 2;
      const bytes: number = this.form.controls.data.value.size ;
      if (bytes >= mb) {
        this.stoerrelse = Math.round(bytes / mb).toString() + 'Mb';
      } else if (bytes > kb) {
        this.stoerrelse = Math.round(bytes / kb).toString() + 'Kb' ;
      } else {
        this.stoerrelse = bytes.toString() + 'b';
      }
    }
  }

  uploadFil(event: any): void {
    if (event.target.files && event.target.files[0]) {
      const filnavn: string = event.target.files[0].name;
      const filtype: string = filnavn.split('.').slice(-1)[0].toLowerCase();
      if (filtype !== 'pdf') {
        const fejlTekst: string = this.appService.hentKunPdfFilerTekst();
        const fejlType: string = this.appService.hentFejlTekst();
        this.toastrService.error(fejlTekst, fejlType, { tapToDismiss: true, disableTimeOut: true, closeButton: true });
        this.fjernFil();
        return;
      }
      this.sendAnsoegningService.ValiderPDF(event.target.files[0]).subscribe((data: any) => {
        if (data.validationStatus === 'ERROR') {
          this.toastrService.error(data.details, 'Fejl', { tapToDismiss: true, disableTimeOut: true, closeButton: true });
          this.fjernFil();
          return;
        }
      });

      const reader = new FileReader();
      reader.onload = () => {
        this.form.controls.filnavn.setValue(event.target.files[0].name);
        this.form.controls.data.setValue(new Blob([reader.result], { type: event.target.files[0].type }));
        this.saetStoerrelse();
      };
      reader.readAsArrayBuffer(event.target.files[0]);
    } else {
      this.form.controls.filnavn.setValue(null);
      this.form.controls.data.setValue(null);
      this.stoerrelse = null;
    }
  }

  fjernFil(): void {
    const filer: UntypedFormArray = this.form.parent as UntypedFormArray;
    if (filer.length > 1) {
      filer.removeAt(this.index);
    } else {
      this.form.controls.fil.setValue('');
      this.filElement.nativeElement.value = '';
      this.form.controls.filnavn.setValue(null);
      this.form.controls.data.setValue(null);
      this.stoerrelse = null;
    }
  }

  fjernFilDisabled(): boolean {
    const filer: UntypedFormArray = this.form.parent as UntypedFormArray;
    if (filer.length > 1) {
      return false;
    }
    return !this.form.controls.data.value;
  }

  fakeFileInput(): boolean {
    return this.form.controls.data.value && !this.form.controls.fil.value;
  }

  fileclass(): string {
    if (this.fakeFileInput()) {
      return 'col-2 col-md-1';
    } else {
      return 'col-8 col-md-10';
    }
  }

}
