import { Component, Input, Output, EventEmitter, OnInit, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { UntypedFormArray, UntypedFormGroup } from '@angular/forms';
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { Subscription, interval } from 'rxjs';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

import { SendAnsoegningService } from '../service/send-ansoegning.service';

@Component({
  selector: 'app-filer',
  templateUrl: './filer.component.html',
  styleUrls: ['./filer.component.scss']
})
export class FilerComponent implements OnInit, OnDestroy {

  @Input() form: UntypedFormArray;
  @Input() label: string;
  @Input() idPrefix: string;
  @Input() accept: string;
  @Input() ariaLabelFil: string;
  @Input() ariaLabelTilfoejFiler: string;
  @Input() filManglerTekst: string;
  @Input() fjern = 'Fjern';
  @Output() filManglerTekst$: EventEmitter<string> = new EventEmitter();

  private subscriptions: Subscription[] = [];
  faPlusCircle = faPlusCircle;
  labelSafe: SafeHtml;

  constructor(private sendAnsoegningService: SendAnsoegningService, private sanitizer: DomSanitizer) { }

  ngOnInit() {
    this.labelSafe = this.sanitizer.bypassSecurityTrustHtml(this.label);
    if (!this.filManglerTekst) {
      this.filManglerTekst = 'Dokumentation mangler' ;
    }
    this.subscriptions.push(
      this.form.valueChanges.subscribe(() => {
        this.emitFilManglerTekst$();
      })
    );
    const touchedFiler: Subscription = interval(50).subscribe(() => {
      if (this.form.touched) {
        this.emitFilManglerTekst$();
        touchedFiler.unsubscribe();
      }
    });
    this.subscriptions.push(touchedFiler);
  }

  ngOnDestroy() {
    for (let i = 0 ; i < this.form.length ; i++) {
      const fil: UntypedFormGroup = this.form.at(i) as UntypedFormGroup;
      fil.controls.fil.setValue('') ;
      fil.controls.filnavn.setValue(null);
      fil.controls.data.setValue(null);
    }
    let s: Subscription;
    while (this.subscriptions.length) {
      s = this.subscriptions.shift();
      if (!s.closed) {
        s.unsubscribe();
      }
    }
  }

  private emitFilManglerTekst$(): void {
    if (this.form.errors && this.form.errors.required) {
      this.filManglerTekst$.emit(this.filManglerTekst);
    } else {
      this.filManglerTekst$.emit(null);
    }
  }

  private tilfoejFil() {
    this.form.push(this.sendAnsoegningService.nyFil(this.idPrefix));
  }

  uploadFlereDokumenter(): boolean {
    this.tilfoejFil();
    return false;
  }

}
