import { Injectable, Injector, Inject } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { UfmBackendtid1Config, UFM_BACKENDTID1_CONFIG } from './ufm-backendtid1.config';
import { UFM_PROPERTIES_CONFIG, UfmPropertiesConfig } from '@ufmit/ufm-properties';

@Injectable({
  providedIn: 'root'
})
export class UfmBackendtid1ConfigService {

  public backendtid1KonfigurationOpdateret$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private ufmBackendtid1Config: UfmBackendtid1Config = {};
  private ufmPropertiesConfig: UfmPropertiesConfig = {};

  constructor(
    @Inject(Injector) private injector: Injector) {
    // hent ufmBackendtidConfig (apiPropertyNavn)
    try {
      this.ufmBackendtid1Config = this.injector.get(UFM_BACKENDTID1_CONFIG);
    } catch (e) { }
    // hent ufmPropertiesConfig (profilRequestUrl, sessionRequestUrl, frontendClientLogRequestUrl)
    try {
      this.ufmPropertiesConfig = this.injector.get(UFM_PROPERTIES_CONFIG);
    } catch (e) { }
  }

  // dynamisk config getters
  public getApiPropertyNavn(): string {
    if (this.ufmBackendtid1Config.environment && this.ufmBackendtid1Config.environment.apiPropertyNavn) {
      return this.ufmBackendtid1Config.environment.apiPropertyNavn;
    } else {
      return 'api';
    }
  }
  public getApi(): string {
    const apiPropertyNavn = this.getApiPropertyNavn();
    const environment: any = this.ufmPropertiesConfig.environment || {};
    const api: string = environment[apiPropertyNavn] ? environment[apiPropertyNavn] : '/api';
    return api;
  }
  public getEndpoint(): string {
    if (this.ufmBackendtid1Config.environment && this.ufmBackendtid1Config.environment.endpoint) {
      return this.ufmBackendtid1Config.environment.endpoint;
    } else {
      return '/profil';
    }
  }
  public getRequestParams(): string {
    const environment: any = this.ufmPropertiesConfig.environment || {};
    if (environment.version) {
      return '?feVersion=' + environment.version;
    } else {
      return '';
    }
  }
  public getProfilRequestUrl(): string {
    const endpoint = this.getEndpoint();
    const profilRequestUrl: string = this.getApi() + endpoint + this.getRequestParams();
    return profilRequestUrl;
  }
  public getSessionRequestUrl(): string {
    const sessionRequestUrl: string = this.getApi() + '/session';
    return sessionRequestUrl;
  }
  public getFrontendClientLogRequestUrl(): string {
    const frontendClientLogRequestUrl: string = this.getApi() + '/frontend-client-log';
    return frontendClientLogRequestUrl;
  }

  public genindlaesOpdateretKonfiguration(): void {
    this.backendtid1KonfigurationOpdateret$.next(true);
  }

  public getBackendtid1Config(): UfmBackendtid1Config {
    return {
      environment: {
        apiPropertyNavn: this.getApiPropertyNavn(),
        endpoint: this.getEndpoint()
      },
      tekster: this.ufmBackendtid1Config.tekster || {}
    };
  }

}
