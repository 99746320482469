import * as moment from 'moment/moment';
import { KeycloakTokenParsed } from 'keycloak-js';

export class UfmSessionInfo {

  readonly encryptedJsonWebToken: string;
  readonly exp: number;
  readonly iat: number;
  readonly authTime: number;
  readonly refreshExpiresIn: number;
  readonly cpr: string;

  constructor(encryptedJsonWebToken: string, json: any, keycloakInstance: Keycloak.KeycloakInstance) {
    this.encryptedJsonWebToken = encryptedJsonWebToken;
    this.exp = json.exp;
    this.iat = json.iat;
    this.authTime = json.auth_time;
    const refreshTokenParsed: KeycloakTokenParsed = keycloakInstance.refreshTokenParsed;
    this.refreshExpiresIn = refreshTokenParsed.exp - refreshTokenParsed.iat ;
    this.cpr = json.cpr;
  }

  public erGyldig(): boolean {
    return moment(this.exp) > moment();
  }
}
