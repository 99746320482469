import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {environment} from '../../../../environments/environment';
import {SendAnsoegningService} from '../../utils/service/send-ansoegning.service';
import {UploadService} from '../upload.service';

@Component({
  selector: 'app-upload-forside',
  templateUrl: './upload-forside.component.html',
  styleUrls: ['./upload-forside.component.scss']
})
export class UploadForsideComponent implements OnInit {

  public test: boolean;
  public dioErLukket = false;
  public demo = false;

  constructor(private sendAnsoegningService: SendAnsoegningService, private router: Router, private uploadService: UploadService) {}

  ngOnInit() {
    this.sendAnsoegningService.deleteCookie('modul');
    this.uploadService.hentProfil().subscribe((profil) => {
      if (profil) {
        this.dioErLukket = !profil.dioConnectAlive;
      }
    });
    this.demo = environment.demo;
  }

  startNytArbejde(): void {
    this.router.navigate(['/upload/nyt-arbejde']);
  }

  startStopperArbejde(): void {
    this.router.navigate(['/upload/stop-arbejde']);
  }

  startUfrivilligArbejdsloes(): void {
    this.router.navigate(['/upload/afskedigelse']);
  }

  startUarbejdsdygtig(): void {
    this.router.navigate(['/upload/uarbejdsdygtig']);
  }

  startOevrigeHenvendelser(): void {
    this.router.navigate(['/upload/oevrige-henvendelser']);
  }

  startFerie(): void {
    this.router.navigate(['/upload/ferie']);
  }

}
