import { NgModule, ModuleWithProviders, Optional, SkipSelf } from '@angular/core';
import { DatePipe, CommonModule } from '@angular/common';

import { UfmBackendtid1Component } from './ufm-backendtid1.component';
import { UFM_BACKENDTID1_CONFIG, UfmBackendtid1Config } from './ufm-backendtid1.config';

@NgModule({
  declarations: [UfmBackendtid1Component],
  providers: [DatePipe],
  imports: [CommonModule],
  exports: [UfmBackendtid1Component]
})
export class UfmBackendtid1Module {
  constructor(@Optional() @SkipSelf() parentModule?: UfmBackendtid1Module) {
    if (parentModule) {
      throw new Error(
        'UfmBackendtid1Module is already loaded. Import it in the AppModule only');
    }
  }

  static forRoot(config?: UfmBackendtid1Config): ModuleWithProviders<UfmBackendtid1Module> {
    return {
      ngModule: UfmBackendtid1Module,
      providers: [
        {provide: UFM_BACKENDTID1_CONFIG, useValue: config || {}}
      ]
    };
  }
}
