import { ErrorHandler, Injectable } from '@angular/core';

import { UfmFejlhaandteringService } from './ufm-fejlhaandtering.service';

@Injectable()
export class UfmErrorHandlerMedLogning implements ErrorHandler {

  constructor(private ufmFejlhaandteringService: UfmFejlhaandteringService) { }

  /**
   * En særlig Fejl håndtering der udover at skrive fejlen i browser loggen også
   *  giver besked til API'et om at klienten oplevede en fejl. Denne besked logges i API'ets log.
   */
  handleError(error: any): void {
    if ( (error.name !== 'TimeoutError') && (error.name !== 'HttpErrorResponse') ) {
      console.error(error);
      if (this.ufmFejlhaandteringService) {
        this.ufmFejlhaandteringService.sendFejlTilAPI(error);
      }
    }
  }
}
