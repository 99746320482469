import { Component, DoCheck, ElementRef, OnInit, ViewChild } from '@angular/core';

import { UfmBrowsercacheConfigService } from './ufm-browsercache-config.service';
import { UfmBrowsercacheConfig } from './ufm-browsercache.config';
import { UfmBrowsercacheService } from './ufm-browsercache.service';

@Component({
  selector: 'ufm-vis-browsercache',
  templateUrl: './ufm-vis-browsercache.component.html',
  styleUrls: ['./ufm-vis-browsercache.component.scss']
})
export class UfmVisBrowsercacheComponent implements OnInit, DoCheck {

  version: string = 'n/a';
  feVersions: string = 'n/a;'

  @ViewChild('htmlOverskrift', { static: false }) htmlOverskrift: ElementRef;
  @ViewChild('htmlForklaring', { static: false }) htmlForklaring: ElementRef;
  @ViewChild('htmlDinVersion', { static: false }) htmlDinVersion: ElementRef;
  @ViewChild('htmlKraevetVersion', { static: false }) htmlKraevetVersion: ElementRef;
  @ViewChild('htmlLinkTekst', { static: false }) htmlLinkTekst: ElementRef;
  private erUfmModulTeksterOpdateret = false;

  constructor(private ufmBrowsercacheService: UfmBrowsercacheService, private ufmBrowsercacheConfigService: UfmBrowsercacheConfigService) { }

  ngOnInit(): void {
    this.ufmBrowsercacheConfigService.browsercacheKonfigurationOpdateret$.subscribe(opdateret => {
      if (opdateret) {
        this.opdaterTeksterPgaOpdateretKonfiguration();
      }
    });
    // vis nuværende FE version og supporterede FE versions
    this.version = this.ufmBrowsercacheConfigService.getVersion();
    this.ufmBrowsercacheService.hentBrowsercache().subscribe(browsercache => {
      if (!browsercache || !browsercache.feVersions) {
        return;
      }
      this.feVersions = browsercache.feVersions;
    });
  }

  ngDoCheck(): void {
    if (this.erUfmModulTeksterOpdateret || !this.htmlOverskrift) {
      return;
    }
    this.erUfmModulTeksterOpdateret = true;
    this.opdaterTeksterPgaOpdateretKonfiguration();
  }

  private opdaterTeksterPgaOpdateretKonfiguration(): void {
    const ufmBrowsercacheConfig: UfmBrowsercacheConfig = this.ufmBrowsercacheConfigService.getBrowsercacheConfig();
    if (ufmBrowsercacheConfig.tekster && ufmBrowsercacheConfig.tekster.visBrowsercache) {
      if (ufmBrowsercacheConfig.tekster.visBrowsercache.overskrift){
        this.htmlOverskrift.nativeElement.innerText = ufmBrowsercacheConfig.tekster.visBrowsercache.overskrift;
      }
      if (ufmBrowsercacheConfig.tekster.visBrowsercache.forklaring){
        this.htmlForklaring.nativeElement.innerText = ufmBrowsercacheConfig.tekster.visBrowsercache.forklaring;
      }
      if (ufmBrowsercacheConfig.tekster.visBrowsercache.dinVersion){
        this.htmlDinVersion.nativeElement.innerText = ufmBrowsercacheConfig.tekster.visBrowsercache.dinVersion;
      }
      if (ufmBrowsercacheConfig.tekster.visBrowsercache.kraevetVersion){
        this.htmlKraevetVersion.nativeElement.innerText = ufmBrowsercacheConfig.tekster.visBrowsercache.kraevetVersion;
      }
      if (ufmBrowsercacheConfig.tekster.visBrowsercache.linkTekst){
        this.htmlLinkTekst.nativeElement.innerText = ufmBrowsercacheConfig.tekster.visBrowsercache.linkTekst;
      }
    }

  }

}
