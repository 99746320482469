<form [formGroup]="form">
  <div class="row">
    <div *ngIf="label" class="col-12 pb-2" [innerHTML]="labelSafe"></div>
    <div *ngFor="let fil of form.controls; let i = index" class="pt-1 col-12 mb-3 bg-white mb-0 py-0">
      <app-fil [form]="fil" [index]="i" [ariaLabel]="ariaLabelFil" [fjern]="fjern"></app-fil>
    </div>
    <div class="col-12 pt-2">
      <a href="#" (click)="uploadFlereDokumenter()" (keyup.enter)="uploadFlereDokumenter()" [attr.aria-label]="ariaLabelTilfoejFiler">
        <fa-icon [icon]="faPlusCircle"></fa-icon>
        <span i18n="@@utils filer link flere filer">More files</span>
      </a>
    </div>
    <div class="d-none" i18n="@@utils filer upload fil mangler default tekst">Dokumentation mangler</div>
    <div *ngIf="form.touched && form.errors && form.errors.required" class="col text-danger" #filerError>
      {{ filManglerTekst || 'Dokumentation mangler' }}
    </div>
  </div>
</form>
