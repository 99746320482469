import { NgModule, Optional, SkipSelf, ModuleWithProviders } from '@angular/core';

import { UFM_PROPERTIES_CONFIG, UfmPropertiesConfig } from './ufm-properties.config';

@NgModule()
export class UfmPropertiesModule {
  constructor(@Optional() @SkipSelf() parentModule?: UfmPropertiesModule) {
    if (parentModule) {
      throw new Error(
        'UfmPropertiesModule is already loaded. Import it in the AppModule only');
    }
  }

  static forRoot(config?: UfmPropertiesConfig): ModuleWithProviders<UfmPropertiesModule> {
    return {
      ngModule: UfmPropertiesModule,
      providers: [
        { provide: UFM_PROPERTIES_CONFIG, useValue: config || {} }
      ]
    };
  }
}
