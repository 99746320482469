import { Component, Input, EventEmitter, OnInit, Output, ViewChild, ElementRef, DoCheck } from '@angular/core';

import { UfmFuldmagtBruger } from '../ufm-fuldmagt-bruger';
import { UfmFuldmagtConfig } from '../ufm-fuldmagt.config';
import { UfmFuldmagtConfigService } from '../ufm-fuldmagt-config.service';

@Component({
  selector: 'ufm-fuld-agerende',
  templateUrl: './ufm-fuldmagt-agerende.component.html',
  styleUrls: ['./ufm-fuldmagt-agerende.component.scss']
})
export class UfmFuldmagtAgerendeComponent implements OnInit, DoCheck {

  @Input() fuldmagtsBruger: UfmFuldmagtBruger;
  @Output() visFuldmagtsvalg$: EventEmitter<boolean> = new EventEmitter<boolean>();

  private visFuldmagtsvalg: boolean;
  @ViewChild('htmlOverskrift', { static: false }) htmlOverskrift: ElementRef;
  @ViewChild('htmlCvrAgererSomFuldmagtshaver', { static: false }) htmlCvrAgererSomFuldmagtshaver: ElementRef;
  @ViewChild('htmlCprAgererSomFuldmagtshaver', { static: false }) htmlCprAgererSomFuldmagtshaver: ElementRef;
  @ViewChild('htmlHarFuldmagt', { static: false }) htmlHarFuldmagt: ElementRef;
  @ViewChild('htmlSkiftBrugerKnap', { static: false }) htmlSkiftBrugerKnap: ElementRef;
  private erUfmModulTeksterOpdateret = false;

  constructor(private ufmFuldmagtConfigService: UfmFuldmagtConfigService) { }

  ngOnInit() {
    this.ufmFuldmagtConfigService.fuldmagtKonfigurationOpdateret$.subscribe(opdateret => {
      if (opdateret) {
        this.opdaterTeksterPgaOpdateretKonfiguration();
      }
    });
  }

  ngDoCheck(): void {
    if (this.erUfmModulTeksterOpdateret || !this.htmlOverskrift) {
      return;
    }
    this.erUfmModulTeksterOpdateret = true;
    this.opdaterTeksterPgaOpdateretKonfiguration();
  }

  private opdaterTeksterPgaOpdateretKonfiguration(): void {
    const ufmFuldmagtConfig: UfmFuldmagtConfig = this.ufmFuldmagtConfigService.getFuldmagtConfig();
    if (ufmFuldmagtConfig.tekster.fuldmagt) {
      if (ufmFuldmagtConfig.tekster.fuldmagt.overskrift) {
        this.htmlOverskrift.nativeElement.innerText = ufmFuldmagtConfig.tekster.fuldmagt.overskrift;
      }
      if (ufmFuldmagtConfig.tekster.fuldmagt.cvrAgererSomFuldmagtshaver) {
        this.htmlCvrAgererSomFuldmagtshaver.nativeElement.innerText = ufmFuldmagtConfig.tekster.fuldmagt.cvrAgererSomFuldmagtshaver;
      }
      if (ufmFuldmagtConfig.tekster.fuldmagt.cprAgererSomFuldmagtshaver) {
        this.htmlCprAgererSomFuldmagtshaver.nativeElement.innerText = ufmFuldmagtConfig.tekster.fuldmagt.cprAgererSomFuldmagtshaver;
      }
      if (ufmFuldmagtConfig.tekster.fuldmagt.harFuldmagt) {
        this.htmlHarFuldmagt.nativeElement.innerText = ufmFuldmagtConfig.tekster.fuldmagt.harFuldmagt;
      }
      if (ufmFuldmagtConfig.tekster.fuldmagt.skiftBrugerKnap) {
        this.htmlSkiftBrugerKnap.nativeElement.value = ufmFuldmagtConfig.tekster.fuldmagt.skiftBrugerKnap;
      }
    }
  }

  skift() {
    this.visFuldmagtsvalg = !this.visFuldmagtsvalg;
    this.visFuldmagtsvalg$.emit(this.visFuldmagtsvalg);
  }

}
