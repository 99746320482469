import { Component, OnInit, Input, ViewChild, ElementRef, DoCheck } from '@angular/core';

import { UfmFuldmagtConfig } from '../ufm-fuldmagt.config';
import { UfmFuldmagtBruger } from '../ufm-fuldmagt-bruger';
import { UfmSessionLoginService } from '@ufmit/ufm-session';
import { UfmFuldmagtConfigService } from '../ufm-fuldmagt-config.service';

@Component({
  selector: 'ufm-fuld-mangler',
  templateUrl: './ufm-fuldmagt-mangler.component.html',
  styleUrls: ['./ufm-fuldmagt-mangler.component.scss']
})
export class UfmFuldmagtManglerComponent implements OnInit, DoCheck {

  @Input() fuldmagtsBruger: UfmFuldmagtBruger;
  @ViewChild('htmlTekst1', { static: false }) htmlTekst1: ElementRef;
  @ViewChild('htmlTekst2', { static: false }) htmlTekst2: ElementRef;
  @ViewChild('htmlLogAfKnap', { static: false }) htmlLogAfKnap: ElementRef;
  private erUfmModulTeksterOpdateret = false;

  constructor(
    private ufmFuldmagtConfigService: UfmFuldmagtConfigService,
    private ufmSessionLoginService: UfmSessionLoginService) { }

  ngOnInit() {
    this.ufmFuldmagtConfigService.fuldmagtKonfigurationOpdateret$.subscribe(opdateret => {
      if (opdateret) {
        this.opdaterTeksterPgaOpdateretKonfiguration();
      }
    });
  }

  ngDoCheck(): void {
    if (this.erUfmModulTeksterOpdateret || !this.htmlTekst1) {
      return;
    }
    this.erUfmModulTeksterOpdateret = true;
    this.opdaterTeksterPgaOpdateretKonfiguration();
  }

  private opdaterTeksterPgaOpdateretKonfiguration(): void {
    const ufmFuldmagtConfig: UfmFuldmagtConfig = this.ufmFuldmagtConfigService.getFuldmagtConfig();
    if (ufmFuldmagtConfig.tekster.fuldmagtMangler) {
      if (ufmFuldmagtConfig.tekster.fuldmagtMangler.tekst1) {
        this.htmlTekst1.nativeElement.innerText = ufmFuldmagtConfig.tekster.fuldmagtMangler.tekst1;
      }
      if (ufmFuldmagtConfig.tekster.fuldmagtMangler.tekst2) {
        this.htmlTekst2.nativeElement.innerText = ufmFuldmagtConfig.tekster.fuldmagtMangler.tekst2;
      }
      if (ufmFuldmagtConfig.tekster.fuldmagtMangler.logAfKnap) {
        this.htmlLogAfKnap.nativeElement.innerText = ufmFuldmagtConfig.tekster.fuldmagtMangler.logAfKnap;
      }
    }
  }

  logAf() {
    this.ufmSessionLoginService.logAf();
  }
}
