import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { KeycloakService } from 'keycloak-angular';
import { HttpClient } from '@angular/common/http';
import { catchError, delay, distinctUntilChanged, filter, map, mergeMap, timeout } from 'rxjs/operators';

import { DemoPostRequest } from './ufm-session-timeout/demo-post-request';
import { UfmSessionLogPaaStatus } from './ufm-session-log-paa-status.enum';
import { UfmUserTrackingService } from '@ufmit/ufm-user-tracking';
import { UfmSessionConfigService } from './ufm-session-config.service';

@Injectable({
  providedIn: 'root'
})
export class UfmSessionLoginService {

  loggetPaa$ = new BehaviorSubject<UfmSessionLogPaaStatus>(UfmSessionLogPaaStatus.EndnuIkkeTestet);

  private erVirksomhed = false;
  private agerendeCpr = "";

  constructor(
    private http: HttpClient,
    private keycloakService: KeycloakService,
    private ufmSessionConfigService: UfmSessionConfigService,
    private userTrackingService: UfmUserTrackingService) {
      if (this.ufmSessionConfigService.getKaldLogin()) {
        const loginUrl: string = this.ufmSessionConfigService.getApiLoginUrl();
        this.loggetPaa$.pipe(
          distinctUntilChanged(),
          filter(logPaaStatus => logPaaStatus === UfmSessionLogPaaStatus.LoggetPaa),
          mergeMap(() => {
            // tjek om ipadr og agerendeCpr er klar før kald af /api/loginsb endpoint
            const ipadr = (window as any).ipadr as string ; // fra index.html
            if (this.erVirksomhed) {
              return of<boolean>(false);
            } else if (ipadr === "0.0.0.0") {
              // ipadr er 0.0.0.0. Vent 100 ms før kald af /api/loginsb
              return of<boolean>(true).pipe(delay(100));
            } else if (!this.agerendeCpr) {
              // agerendeCpr er blank. Vent 100 ms før kald af /api/loginsb (demo login)
              return of<boolean>(true).pipe(delay(100));
            } else {
              // ipadr og agerendeCpr er klar. kald /api/loginsb nu
              return of<boolean>(false);
            }
          }),
          mergeMap(() => {
            const ipadr = (window as any).ipadr as string ; // fra index.html
            if (this.erVirksomhed) {
              // kald af /api/loginsb sker fra fuldmagt modul når agerende bruger vælges
              return of();
            } else {
              // kald /api/loginsb efter login
              return this.http.get(loginUrl, { responseType: 'text', headers: { NoInterceptorError: 'x', 'HTTP_X_FORWARDED_FOR': ipadr } });
            }
          }),
          catchError((error) => {
            if (typeof error === 'string' && error.indexOf('401 Unauthorized')) {
              this.logAf();
            }
            throw error;
          })
        ).subscribe();  
      }
  }

  public logPaaSignal(): void {
    this.loggetPaa$.next(UfmSessionLogPaaStatus.LoggetPaa);
  }

  public logAfSignal(): void {
    this.loggetPaa$.next(UfmSessionLogPaaStatus.IkkeLoggetPaa);
  }

  public erLoggetPaa(): Observable<boolean> {
    // inject nødvendige services ind i f så services og logning fungerer der
    const keycloakService2 = this.keycloakService;
    const loggetPaa$2 = this.loggetPaa$;
    const console2 = console;
    const f = (obs) => {
      keycloakService2.isLoggedIn().then(loggetPaa => {
        if (loggetPaa) {
          loggetPaa$2.next(UfmSessionLogPaaStatus.LoggetPaa);
        } else {
          loggetPaa$2.next(UfmSessionLogPaaStatus.IkkeLoggetPaa);
        }
        obs.next(loggetPaa);
      }).catch(error => {
        console2.error('keycloakService.isLoggedIn() fejlede med', error);
        obs.next(false);
      });
    };
    const observable: Observable<boolean> = new Observable(f);
    return observable;
  }

  public logAf(redirectUri?: string): void {
    this.userTrackingService.sendUrl('/logaf');
    const logAfUrl: string = this.ufmSessionConfigService.getLogAfUrl();
    if (!redirectUri) {
      if (logAfUrl.substr(0, 4) === 'http') {
        redirectUri = logAfUrl;
      } else {
        redirectUri = window.location.origin + logAfUrl;
      }
    }
    // send logaf signal til API, post-get-proxy og keycloak server
    this.apiLogout().subscribe(
      () => {
        this.demoLogout().subscribe(
          () => {
            this.keycloakService.logout(redirectUri);
          }
        );
      }
    );
  }

  // send logaf til api. Alle fejl http fejl ignoreres, inkl. 401 Unauhtorized, således at FE logout flow ikke afbrydes
  public apiLogout(encryptedJsonWebToken?: string): Observable<true> {
    const headers: { [key: string]: string } = { NoInterceptorError: 'x' };
    if (encryptedJsonWebToken) {
      headers.Authorization = 'Bearer ' + encryptedJsonWebToken;
    }
    const apiLogoutUrl: string = this.ufmSessionConfigService.getApiLogoutUrl();
    return this.http.get(apiLogoutUrl, { headers })
      .pipe(
        catchError(() => of(true)),
        map(() => true));
  }

  public getUs2000RequestId(): string {
    return sessionStorage.getItem('us2000RequestId');
  }

  public findesUs2000RequestId(): boolean {
    const findes: boolean = this.getUs2000RequestId() ? true : false;
    return findes;
  }

  // send logaf til demo. gammel post data request slettes
  public demoLogout(): Observable<any> {
    const demo: boolean = this.ufmSessionConfigService.getDemo();
    if (!demo || !this.findesUs2000RequestId()) {
      return of(true);
    }
    const us2000RequestId: string = this.getUs2000RequestId();
    const headers: { [key: string]: string } = { NoInterceptorError: 'x', NoInterceptorAgerendeCpr: 'x' };
    sessionStorage.removeItem('us2000RequestId');
    return this.http.delete<DemoPostRequest>('/demo/login?requestId=' + us2000RequestId, { headers });
  }

  public logPaa(redirectUri?: string): void {
    if (!redirectUri) {
      redirectUri = window.location.origin;
    }
    this.userTrackingService.sendUrl('/logpaa');
    this.keycloakService.login({ redirectUri });
  }

  public setErVirksomhed(erVirksomhed: boolean): void {
    this.erVirksomhed = erVirksomhed;
  }

  public setAgerendeCpr(agerendeCpr: string): void {
    this.agerendeCpr = agerendeCpr;
  }
}
