import { Component, Inject, LOCALE_ID, Renderer2, ViewChild, ElementRef, OnInit, Input, DoCheck, Injector } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import * as moment from 'moment/moment';
import { timer } from 'rxjs';

import { AppService } from './app.service';
import { environment } from '../environments/environment';
import { SendAnsoegningService } from './forretnings-sider/utils/service/send-ansoegning.service';
import { UfmSessionConfig, UFM_SESSION_CONFIG } from '@ufmit/ufm-session';
import { UfmFejlhaandteringConfig, UFM_FEJLHAANDTERING_CONFIG } from '@ufmit/ufm-fejlhaandtering';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, DoCheck {

  @Input() kortSessionId: string;
  @Input() tidspunkt: string;

  priv: string = environment.fuldmagtsprivilege;

  @ViewChild('titel', { static: false }) titel: ElementRef;
  @ViewChild('naesteSektion', { static: false }) naesteSektion: ElementRef;
  @ViewChild('netvaerksfejl', { static: false }) netvaerksfejl: ElementRef;
  @ViewChild('sessionErUdloebet', { static: false }) sessionErUdloebet: ElementRef;
  @ViewChild('sessionUdloeberSnart', { static: false }) sessionUdloeberSnart: ElementRef;
  @ViewChild('kunPdfFiler', { static: false }) kunPdfFiler: ElementRef;
  @ViewChild('maxStoerrelse', { static: false }) maxStoerrelse: ElementRef;
  @ViewChild('svarJa', { static: false }) svarJa: ElementRef;
  @ViewChild('svarNej', { static: false }) svarNej: ElementRef;
  @ViewChild('svarMangler', { static: false }) svarMangler: ElementRef;
  @ViewChild('ugyldigDato', { static: false }) ugyldigDato: ElementRef;
  @ViewChild('skiftSprogVent', { static: false }) skiftSprogVent: ElementRef;
  @ViewChild('skiftSprogFejl', { static: false }) skiftSprogFejl: ElementRef;

  // UfmSessionModule popup tekster. Tekster i timeout og logaf sider opdateres forRoot (dansk) og messages.en.xlf (engelsk)
  @ViewChild('sessionPopupAdvarsel', { static: false }) sessionPopupAdvarsel: ElementRef;
  @ViewChild('sessionPopupTimeout', { static: false }) sessionPopupTimeout: ElementRef;
  @ViewChild('sessionPopupUdloeberSnart', { static: false }) sessionPopupUdloeberSnart: ElementRef;
  @ViewChild('sessionPopupErUdloebet', { static: false }) sessionPopupErUdloebet: ElementRef;

  // UfmFejlhaandteringModule popup tekster
  @ViewChild('fejlhaandteringPopupOverskrift', { static: false }) fejlhaandteringPopupOverskrift: ElementRef;
  @ViewChild('fejlhaandteringPopupIngenForbindelseTekst', { static: false }) fejlhaandteringPopupIngenForbindelseTekst: ElementRef;
  @ViewChild('fejlhaandteringPopupNetvaerksfejlTekst', { static: false }) fejlhaandteringPopupNetvaerksfejlTekst: ElementRef;

  maxFilUploadTekst: string;
  private erUfmModulTeksterOpdateret = false;

  constructor(
    private appService: AppService,
    @Inject(DOCUMENT) doc: Document,
    @Inject(Injector) private injector: Injector,
    private sendAnsoegningService: SendAnsoegningService,
    renderer: Renderer2
  ) {
    let locale = 'en';
    if (window.location.href.match(/\/da\//)) {
      locale = 'da';
    }
    renderer.setAttribute(doc.documentElement, 'lang', locale);
    this.sendAnsoegningService.setCookie('language', locale, moment().add(1, 'day').toDate());
  }

  ngOnInit() {
    this.appService.injectAppComponent(this);
    this.maxFilUploadTekst = environment.maxFilUploadTekst;
    timer(0).subscribe(() => {
      if (this.titel) {
        document.getElementById('titel').innerText = this.titel.nativeElement.innerText;
      }
    });
  }

  ngDoCheck() {
    if (this.erUfmModulTeksterOpdateret || !this.sessionPopupUdloeberSnart) {
      return;
    }
    this.erUfmModulTeksterOpdateret = true;

    // inject tekster fra app.compoment.html i UfmSessionModule
    let session: UfmSessionConfig;
    try {
      session = this.injector.get(UFM_SESSION_CONFIG);
    } catch (e) { }
    if (session) {
      if (!session.tekster) {
        session.tekster = {};
      }
      if (!session.tekster.popup) {
        session.tekster.popup = {};
      }
      session.tekster.popup.popupAdvarsel = this.sessionPopupAdvarsel.nativeElement.innerText;
      session.tekster.popup.popupTimeout = this.sessionPopupTimeout.nativeElement.innerText;
      session.tekster.popup.popupSessionUdloeberSnart = this.sessionPopupUdloeberSnart.nativeElement.innerText;
      session.tekster.popup.popupSessionErUdloebet = this.sessionPopupErUdloebet.nativeElement.innerText;
    }

    // inject tekster fra app.component.html i UfmFejlhaandteringModule
    let fejlhaandtering: UfmFejlhaandteringConfig;
    try {
      fejlhaandtering = this.injector.get(UFM_FEJLHAANDTERING_CONFIG);
    } catch (e) { }
    if (fejlhaandtering) {
      if (!fejlhaandtering.tekster) {
        fejlhaandtering.tekster = {};
      }
      fejlhaandtering.tekster.popupOverskrift = this.fejlhaandteringPopupOverskrift.nativeElement.innerText;
      fejlhaandtering.tekster.popupIngenForbindelseTekst = this.fejlhaandteringPopupIngenForbindelseTekst.nativeElement.innerText;
      fejlhaandtering.tekster.popupNetvaerksfejlTekst = this.fejlhaandteringPopupNetvaerksfejlTekst.nativeElement.innerText;
    }
  }

}
