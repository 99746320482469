import { Component, DoCheck, ElementRef, OnInit, ViewChild } from '@angular/core';

import { UfmSessionConfigService } from '../ufm-session-config.service';
import { UfmSessionConfig } from '../ufm-session.config';

@Component({
  templateUrl: './ufm-session-timeout.component.html',
  styleUrls: ['./ufm-session-timeout.component.scss'],
})
export class UfmSessionTimeoutComponent implements OnInit, DoCheck {

  public popupSessionErUdloebet: string;
  @ViewChild('htmlTekst1', { static: false }) htmlTekst1: ElementRef;
  @ViewChild('htmlTekst2', { static: false }) htmlTekst2: ElementRef;
  @ViewChild('htmlTekst3', { static: false }) htmlTekst3: ElementRef;
  @ViewChild('htmlTekst4', { static: false }) htmlTekst4: ElementRef;
  @ViewChild('htmlLaesFlere', { static: false }) htmlLaesFlere: ElementRef;
  @ViewChild('htmlGodeRaad', { static: false }) htmlGodeRaad: ElementRef;
  private erUfmModulTeksterOpdateret = false;

  constructor(private ufmSessionConfigService: UfmSessionConfigService) { }

  ngOnInit() {
    this.popupSessionErUdloebet = this.ufmSessionConfigService.getPopupSessionErUdloebetTekst();
    this.ufmSessionConfigService.sessionKonfigurationOpdateret$.subscribe(opdateret => {
      if (opdateret) {
        this.opdaterTeksterPgaOpdateretKonfiguration();
      }
    });
  }

  ngDoCheck(): void {
    if (this.erUfmModulTeksterOpdateret || !this.htmlTekst1) {
      return;
    }
    this.erUfmModulTeksterOpdateret = true;
    this.opdaterTeksterPgaOpdateretKonfiguration();
  }

  private opdaterTeksterPgaOpdateretKonfiguration(): void {
    this.popupSessionErUdloebet = this.ufmSessionConfigService.getPopupSessionErUdloebetTekst();
    const ufmSessionConfig: UfmSessionConfig = this.ufmSessionConfigService.getSessionConfig();
    if (ufmSessionConfig && ufmSessionConfig.tekster) {
      if (ufmSessionConfig.tekster.timeout) {
        if (ufmSessionConfig.tekster.timeout.tekst1) {
          this.htmlTekst1.nativeElement.innerText = ufmSessionConfig.tekster.timeout.tekst1;
        }
        if (ufmSessionConfig.tekster.timeout.tekst2) {
          this.htmlTekst2.nativeElement.innerText = ufmSessionConfig.tekster.timeout.tekst2;
        }
        if (ufmSessionConfig.tekster.timeout.tekst3) {
          this.htmlTekst3.nativeElement.innerText = ufmSessionConfig.tekster.timeout.tekst3;
        }
        if (ufmSessionConfig.tekster.timeout.tekst4) {
          this.htmlTekst4.nativeElement.innerText = ufmSessionConfig.tekster.timeout.tekst4;
        }
      }
    }
    if (ufmSessionConfig.tekster.sideLaesFlere) {
      this.htmlLaesFlere.nativeElement.innerText = ufmSessionConfig.tekster.sideLaesFlere;
    }
    if (ufmSessionConfig.tekster.sideGodeRaad) {
      this.htmlGodeRaad.nativeElement.innerText = ufmSessionConfig.tekster.sideGodeRaad;
    }
    if (ufmSessionConfig.tekster.sideGodeRaadLink) {
      this.htmlGodeRaad.nativeElement.href = ufmSessionConfig.tekster.sideGodeRaadLink;
    }
  }

}
