import { Component, OnInit, Input, OnChanges, ViewChild, ElementRef, EventEmitter, Output } from '@angular/core';
import { timer, interval, Subscription } from 'rxjs';

import { AccordionStatus } from './accordion-status/accordion-status.enum';
import { foldudAmination } from '../../animationer/animationer';
import { ScrollInfoViewInput } from './scroll-into-view-input';
import { SmartScrollParameter } from '../smart-scroll/smart-scroll-parameter';
import { SmartScrollService } from '../smart-scroll/smart-scroll.service';
import { UfmUserTrackingService } from '@ufmit/ufm-user-tracking';

const foldud = 'foldud';
const foldsammen = 'foldsammen';

@Component({
  selector: 'app-accordion',
  templateUrl: './accordion.component.html',
  styleUrls: ['./accordion.component.scss'],
  animations: [foldudAmination]
})
export class AccordionComponent implements OnInit, OnChanges {

  @Input() overskrift: string;
  @Input() status: AccordionStatus;
  @Input() statusTekst: string;
  @Input() ariaLabel: string;
  @Input() userTrackingUrl: string;
  @Input() vis: boolean;
  @Input() scrollIntoView: ScrollInfoViewInput;
  @Input() h: 1 | 2 | 3 | 4 | 5 | 6;
  @Input() disabled: boolean;
  @Output() vis$: EventEmitter<boolean> = new EventEmitter<boolean>(null);
  @Output() validerFoerFoldSammen$: EventEmitter<null> = new EventEmitter(null);

  accordionAmination: string = foldsammen;
  accordionVis = false;
  h2: boolean;
  h3: boolean;
  h4: boolean;
  h5: boolean;
  h6: boolean;
  buttonStyle: { [key: string]: number | string };
  buttonClass: string;
  statusClass: string;

  private gammelVis: boolean;
  @ViewChild('topElement', { static: false }) private topElement: ElementRef;
  @ViewChild('bottomElement', { static: false }) private bottomElement: ElementRef;
  @ViewChild('accordionButton', { static: false }) accordionButton: ElementRef;
  smartScrollParameter: SmartScrollParameter;

  private accordionKlar = true;
  accordionAriaLabelMedStatusOgStatusTekst: string;
  private gammelScrollIntoView: ScrollInfoViewInput;

  constructor(private smartScrollService: SmartScrollService, private ufmUserTrackingService: UfmUserTrackingService) { }

  ngOnInit() {
    if (this.vis === true) {
      this.accordionVis = true;
      this.accordionAmination = foldud;
    }
    this.gammelVis = this.vis || false;
    // h2-h6 headings
    if (this.h === 2) {
      this.h2 = true;
      this.buttonStyle = { 'font-size': '26px' };
      this.buttonClass = 'btn-primary';
    }
    if (this.h === 3) {
      this.h3 = true;
      this.buttonStyle = { 'font-size': '24px' };
      this.buttonClass = 'btn-secondary';
      this.statusClass = 'text-dark';
    }
    if (this.h === 4) {
      this.h4 = true;
      this.buttonStyle = { 'font-size': '22px' };
      this.buttonClass = 'btn-light border';
      this.statusClass = 'text-dark';
    }
    if (this.h === 5) {
      this.h5 = true;
      this.buttonStyle = { 'font-size': '20px' };
      this.buttonClass = 'btn-light border';
      this.statusClass = 'text-dark';
    }
    if (this.h === 6) {
      this.h6 = true;
      this.buttonStyle = { 'font-size': '1rem' };
      this.buttonClass = 'btn-light border';
      this.statusClass = 'text-dark';
    }
    if (!this.h2 && this.h3 && this.h4 && this.h5 && this.h6) {
      this.h = null;
      this.buttonStyle = { 'font-size': '28px' };
      this.buttonClass = 'btn-primary';
    }
  }

  ngOnChanges() {
    if (this.gammelScrollIntoView && this.scrollIntoView !== this.gammelScrollIntoView) {
      if (this.scrollIntoView === 'noop') {
      } else if (this.scrollIntoView === 'top') {
        this.topElement.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
      } else if (this.scrollIntoView === 'bottom') {
        this.bottomElement.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'nearest' });
      } else if (this.scrollIntoView === 'smart') {
        this.smartScrollParameter = {
          top: this.topElement,
          bund: this.bottomElement,
          count: 0,
          tekst: this.overskrift
        };
        this.smartScrollService.startSmartScroll(this.smartScrollParameter);
      }
    }
    this.gammelScrollIntoView = this.scrollIntoView;

    const tekster: string[] = [];
    tekster.push(this.ariaLabel + (this.accordionVis ? ' expanded' : ' collapsed'));
    if (this.status === AccordionStatus.SkalUdfyldes) {
      tekster.push('Formular skal udfyldes');
    }
    if (this.status === AccordionStatus.Fejl) {
      tekster.push('Formular indeholder fejl');
    }
    if (this.statusTekst && !this.accordionVis) {
      tekster.push(this.statusTekst);
    }
    this.accordionAriaLabelMedStatusOgStatusTekst = tekster.join('. ');

    if (this.vis === undefined) {
      return;
    }
    const s: Subscription = interval(50).subscribe(() => {
      if (!this.accordionKlar) { return; }
      s.unsubscribe();
      if (this.vis === this.gammelVis) {
        return;
      }
      this.gammelVis = this.vis;
      this.accordion(false);
    });
  }

  accordion(click: boolean): void {
    this.accordionKlar = false;
    if (this.accordionAmination === foldsammen) {
      this.accordionVis = true;
      timer(0).subscribe(() => {
        this.accordionAmination = foldud;
        this.accordionKlar = true;
        if (click) {
          this.gammelVis = this.accordionVis;
          this.vis$.emit(this.accordionVis);
        }
        if (this.scrollIntoView) {
          timer(400).subscribe(() => {
            if (this.scrollIntoView === 'top') {
              this.topElement.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
            } else if (this.scrollIntoView === 'bottom') {
              this.bottomElement.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'nearest' });
            } else if (this.scrollIntoView === 'smart') {
              this.smartScrollParameter = {
                top: this.topElement,
                bund: this.bottomElement,
                count: 0,
                tekst: this.overskrift
              };
              this.smartScrollService.startSmartScroll(this.smartScrollParameter);
            }
          });
        }
      });
      if (this.userTrackingUrl) {
        this.ufmUserTrackingService.sendUrl(this.userTrackingUrl + '/foldud');
      }
    } else {
      this.accordionAmination = foldsammen;
      this.validerFoerFoldSammen$.emit();
      timer(450).subscribe(() => {
        this.accordionVis = false;
        this.accordionKlar = true;
        if (click) {
          this.gammelVis = this.accordionVis;
          this.vis$.emit(this.accordionVis);
        }
      });
      if (this.userTrackingUrl) {
        this.ufmUserTrackingService.sendUrl(this.userTrackingUrl + '/foldsammen');
      }
    }
  }

  erElementSynligt(element: HTMLElement): boolean {
    return this.smartScrollService.erElementSynligt(element);
  }

  erBundSynlig(erSynligt: boolean): void {
    return this.smartScrollService.erBundSynlig(erSynligt, this.smartScrollParameter);
  }

  erTopSynlig(erSynligt: boolean): void {
    return this.smartScrollService.erTopSynlig(erSynligt, this.smartScrollParameter);
  }

}
