<div class="lilla-baggrund p-0">
  <nav class="container navbar navbar-expand-md navbar-dark">
    <a class="navbar-brand rounded pt-0 pb-0" routerLink="/" routerLinkActive="active">
      <img [src]="ufmLogo" height="50" class="d-inline-block align-top" i18n-alt="@@menu logo title" i18n-title="@@menu logo title" />
    </a>
    <button
      class="navbar-toggler"
      type="button"
      data-toggle="collapse"
      (click)="isCollapsed = !isCollapsed"
      aria-expanded="false"
      data-target="#navbarNavDropdown"
      aria-controls="navbarNavDropdown"
      [attr.aria-expanded]="!isCollapsed"
      aria-label="Menu knap"
      i18n-aria-label="@@menu knap skærmlæser"
    >
      <span class="navbar-toggler-icon"></span>
    </button>

    <div class="collapse navbar-collapse" id="navbarNavDropdown" [collapse]="isCollapsed" [attr.aria-hidden]="false">
      <ul class="navbar-nav me-auto"></ul>
      <ul class="navbar-nav">
        <li *ngIf="brugernavn">
          <span class="nav-text pe-5 text-white"><strong>{{ brugernavn }}</strong></span>
        </li>
        <li class="nav-item pe-3">
          <a class="text-white text-end" href="#" (click)="minSU()" aria-label="Gå til min S U selvbetjening" i18n-aria-label="@@menu minsu skærmlæser">
            minSU
          </a>
        </li>
        <li class="nav-item" [ngClass]="visLogPaa">
          <a class="text-white text-end" href="#" (click)="logAf()" aria-label="Log ud af selvbetjening" i18n-aria-label="@@menu log af skærmlæser" i18n="@@menu log af">
            Log af
          </a>
        </li>
        <li class="nav-item" [ngClass]="visLogAf">
          <a class="text-white text-end" href="#" (click)="logPaa()" aria-label="Log på selvbetjening" i18n-aria-label="@@menu log på skærmlæser" i18n="@@menu log på">
            Log på
          </a>
        </li>
      </ul>
    </div>
  </nav>
</div>
