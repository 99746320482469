<div class="row notranslate" translate="no" id="kvittering">
  <div class="col-8 pb-3">
    <h3 class="pt-5" role="heading" aria-level="2">Receipt</h3>
  </div>
  <div class="col-4 pb-3">
    <img  [src]="ufmLogo" width="200" alt="logo for Danish Agency for Higher Education and Science"
         title="logo for Danish Agency for Higher Education and Science" />
  </div>

  <div class="col-12" role="heading" aria-level="3">
    <div class="row">
      <div class="col-12 pt-2">
        <strong class="ufm-black">We recommend that you save or print this receipt</strong>
      </div>

      <div class="col-12 pt-3">
        Name: <strong class="ufm-black">{{ uploadProfil.navn }} ,  {{ uploadProfil.cpr }}</strong>
      </div>

      <div class="col-12 pt-3">
        Recevied: <strong class="ufm-black">{{ modtagetDato }}</strong>
      </div>

      <div *ngIf="apiResponse" class="col-12 pt-3">
        ReceiptId: <strong class="ufm-black">{{ apiResponse.receiptId }}</strong>
      </div>

      <div class="col-12 pt-3">
        We have received your inquiry, and you will receive an answer as soon as possible.
        <span class="text-danger">We expect to respond to your inquiry within 4-6 weeks.</span>
      </div>
    </div>
  </div>
</div>
