<form [formGroup]="form">
  <div class="row">
    <div *ngIf="label" class="col-12 pt-2">{{ label }}</div>
    <div *ngIf="labelFradato || labelTildato" class="col-12 pt-2">
      <div class="row">
        <div class="col-3">{{ labelFradato }}</div>
        <div class="col-3">{{ labelTildato }}</div>
        <div class="col-2"></div>
      </div>
    </div>
    <div *ngFor="let periode of form.controls; let i = index" class="pt-1 col-12 form-group bg-white mb-0 py-3">
      <app-ferie-periode [form]="periode" [id]="id" [index]="i"></app-ferie-periode>
    </div>
    <div *ngIf="form.touched && form.errors" class="col-12" #perioderFejl>
      <div *ngIf="form.errors.required" class="text-danger">
        At least one period must be filled out
      </div>
    </div>
    <div class="col-12" *ngIf="antalPerioder() < 3 ">
      <a href="#" (click)="tilfoejFlerePerioder()">
        <fa-icon [icon]="faPlusCircle"></fa-icon>
        <span  aria-label="Tilføj periode">
          Add another period
        </span>
      </a>
    </div>
  </div>
</form>
