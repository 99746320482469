<form [formGroup]="form">
  <div class="row">
    <div class="col-12 pt-3">
      <div class="form-check">
        <input id="godkendt" name="godkendt" type="checkbox" class="form-check-input" formControlName="godkendt" />
        <label for="godkendt" class="form-check-label">
          <p>
            I have been informed that the Danish Agency for Higher Education and Science will manage my personal information in processing my case. I also certify that the information I have provided is correct. I am aware that incorrect information can result in having to pay back my SU.<br />
            <a href="https://su.dk/11907" target="_blank">Read more about the Agency processing personal information on su.dk.</a>
          </p>
        </label>
        <div
          *ngIf="form.controls.godkendt.touched && form.controls.godkendt.errors && form.controls.godkendt.errors.required"
          class="text-danger" role="alert" aria-atomic="true" #samtykkeError>
          You must accept the terms.
        </div>
      </div>
    </div>
  </div>
</form>
