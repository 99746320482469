import {Component, OnDestroy, OnInit} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {Observable, Subscription, timer} from 'rxjs';
import {map} from 'rxjs/operators';
import {Router} from '@angular/router';
import {DatoService} from '../../../../shared/dato/dato.service';
import {SendAnsoegningService} from '../../../utils/service/send-ansoegning.service';
import {AppService} from '../../../../app.service';
import {foldudAmination} from '../../../../shared/animationer/animationer';
import {FerieService} from './ferie.service';
import {UfmUserTrackingService} from '@ufmit/ufm-user-tracking';
import {faSpinner} from '@fortawesome/free-solid-svg-icons';
import {UfmFejlhaandteringService} from '@ufmit/ufm-fejlhaandtering';
import {ToastrService} from 'ngx-toastr';

@Component({
  selector: 'app-ferie',
  templateUrl: './ferie.component.html',
  styleUrls: ['./ferie.component.scss'],
  animations: [foldudAmination]
})
export class FerieComponent implements OnInit, OnDestroy {

  form: UntypedFormGroup;
  private formWithEnvelope: UntypedFormGroup;
  bemaerkningerTaeller$: Observable<string>;
  dannerKvittering: boolean;
  private formData: FormData;
  senderAnsoegning = false;

  faSpinner = faSpinner;
  fraDatovaeglerTilstand = 0;
  tilDatovaeglerTilstand = 0;
  private subscriptions: Subscription[] = [];
  ydeligereInstruktionerJaVis = false;
  ydeligereBemaerkningerJaVis = false;
  private ydeligereInstruktioner: UntypedFormControl;
  private ydeligereBemaerkninger: UntypedFormControl;

  constructor(private appService: AppService,
              private datoService: DatoService,
              private sendAnsoegningService: SendAnsoegningService,
              private router: Router,
              private ferieService: FerieService,
              private toastrService: ToastrService,
              private ufmFejlhaandteringService: UfmFejlhaandteringService,
              private ufmUserTrackingService: UfmUserTrackingService) {
  }

  ngOnInit() {
    this.formWithEnvelope = this.ferieService.initialiserAnsoegningFormGroup();
    this.form = this.formWithEnvelope.controls.ferie as UntypedFormGroup;
    this.ydeligereInstruktioner = this.form.controls.ydeligereInstruktioner as UntypedFormControl;
    this.ydeligereBemaerkninger = this.form.controls.ydeligereBemaerkninger as UntypedFormControl;
    console.log('form=', this.form);
    this.bemaerkningerTaeller$ = this.form.controls.bemaerkninger.valueChanges
    .pipe(
      map((bemaerkninger) => {
        if (!bemaerkninger) {
          bemaerkninger = '';
        }
        return JSON.stringify(bemaerkninger.length) + '/250';
      })
    );

    this.subscriptions.push(this.ydeligereInstruktioner.valueChanges.subscribe((value) => {
      if (value) {
        this.ydeligereInstruktionerJaVis = true;
      } else {
        this.ydeligereInstruktionerJaVis = false;
      }
      this.form.updateValueAndValidity();
    }));

    this.subscriptions.push(this.ydeligereBemaerkninger.valueChanges.subscribe((value) => {
      if (value) {
        this.ydeligereBemaerkningerJaVis = true;
      } else {
        this.ydeligereBemaerkningerJaVis = false;
      }
      this.form.updateValueAndValidity();
    }));
  }

  ngOnDestroy(): void {
    let s: Subscription;
    while (this.subscriptions.length) {
      s = this.subscriptions.shift();
      if (!s.closed) {
        s.unsubscribe();
      }
    }
  }

  fraDatoVaeglerSkjul(): void {
    this.fraDatovaeglerTilstand++;
  }

  fraDatoVaeglerVis(): void {
    this.fraDatovaeglerTilstand++;
  }

  tilDatoVaeglerSkjul(): void {
    this.tilDatovaeglerTilstand++;
  }

  tilDatoVaeglerVis(): void {
    this.tilDatovaeglerTilstand++;
  }

  sendAnsoegning(): void {
    this.senderAnsoegning = true;
    this.formData = this.sendAnsoegningService.initialiserFormData(this.formWithEnvelope, true);
    if (!this.formData) {
      console.log('ingen formData. stopper');
      this.senderAnsoegning = false;
      return;
    }
    console.log('danner pdf kvittering');
    this.dannerKvittering = true;
    this.subscriptions.push(
      this.appService.ferieKvitteringErKlar$.subscribe(() => {
        this.ferieKvitteringPdfErklar();
      })
    );
    this.appService.danFerieKvittering();
  }

  public ferieKvitteringPdfErklar(): void {
    // console.log('pdf er klar. sender ansøgning. sætter dannerKvittering til false');
    // this.dannerKvittering = false;
    timer(0).subscribe(() => {
      this.sendAnsoegningService.sendAnsoegning(this.formData).subscribe(
        (ok) => {
          this.senderAnsoegning = false;
          if (ok && ok.receiptId) {
            // alt OK. Ansøgning findes i US2000 og på filserveren
            console.log('ok=', ok);
            this.ufmUserTrackingService.sendUrl('upload/ferie/ok');
            this.router.navigate(['upload/kvittering/ferie']);
          } else {
            // fejl. OK svar fra POST, men ansøgning findes ikke i US2000.
            this.sendAnsoegningService.enableFormGroup(this.form);
            this.ufmUserTrackingService.sendUrl('upload/ferie/fejl/receipt-id-mangler');
            const msg: string =
              'Unfortunately, a technical error occurred and we could not receive the application. user agent = ' +
              window.navigator.userAgent;
            console.error(msg);
            this.ufmFejlhaandteringService.sendFejlTilAPI(msg);
            this.toastrService.error(msg, 'Error', {tapToDismiss: true, disableTimeOut: true, closeButton: true});
          }
        },
        (error) => {
          console.log('error=', error);
          this.senderAnsoegning = false;
          this.sendAnsoegningService.enableFormGroup(this.form);
          this.ufmUserTrackingService.sendUrl('upload/ferie/fejl');
        },
        () => {
          if (this.senderAnsoegning) {
            this.senderAnsoegning = false;
            this.sendAnsoegningService.enableFormGroup(this.form);
            this.ufmUserTrackingService.sendUrl('upload/ferie/fejl');
          }
          this.senderAnsoegning = false;
        }
      );
    });
  }
}
