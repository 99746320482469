import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, filter, distinctUntilChanged, tap } from 'rxjs/operators';
import { KeycloakService } from 'keycloak-angular';
import { HttpClient } from '@angular/common/http';

import { JwtService } from './jwt.service';
import { UfmFuldmagtBruger } from './ufm-fuldmagt-bruger';
import { UfmSessionTimeoutService } from '@ufmit/ufm-session';
import { UfmFuldmagtConfigService } from './ufm-fuldmagt-config.service';

@Injectable({
  providedIn: 'root'
})
export class UfmFuldmagtService {

  private cachedFuldmagtsBruger = false;
  fuldmagtsBruger$: BehaviorSubject<UfmFuldmagtBruger> = new BehaviorSubject<UfmFuldmagtBruger>(null);
  skiftetFuldmagtsBruger$: Observable<boolean> = this.fuldmagtsBruger$.pipe(
    map(ufmFuldmagtBruger => {
      if (ufmFuldmagtBruger && ufmFuldmagtBruger.agerendeCpr) {
        return ufmFuldmagtBruger.agerendeCpr;
      } else {
        return null;
      }
    }),
    distinctUntilChanged(),
    filter(agerendeCpr => agerendeCpr ? true : false),
    map(() => true));

  constructor(
    private http: HttpClient,
    private jwtService: JwtService,
    private keycloakService: KeycloakService,
    private ufmFuldmagtConfigService: UfmFuldmagtConfigService,
    private ufmSessionTimeoutService: UfmSessionTimeoutService) {
      if (ufmFuldmagtConfigService.getKaldLogin()) {
        // /api/loginsb end-point skal kaldes ved skift af agerendeCpr
        let kaldLoginMedNytAgerendeCpr = false;
        this.fuldmagtsBruger$
          .pipe(
            filter(fuldmagtsbruger => fuldmagtsbruger && fuldmagtsbruger.agerendeCpr ? true : false)
          )
          .subscribe(fuldmagtsBruger => {
            if (!kaldLoginMedNytAgerendeCpr) {
              if (!fuldmagtsBruger.agererMedFuldmagt && fuldmagtsBruger.egetCpr === fuldmagtsBruger.agerendeCpr) {
                // cpr login. /api/login er allerede kaldt fra ufm-session module og vi skipper derfor kald af /api/loginsb endpoint her (ufm-fuldmagt module)
              } else {
                kaldLoginMedNytAgerendeCpr = true;
              }
            }
            if (kaldLoginMedNytAgerendeCpr) {
              const loginUrl: string = this.ufmFuldmagtConfigService.getApiLoginUrl();
              const ipadr = (window as any).ipadr as string ; // fra index.html
              this.http.get(loginUrl, { responseType: 'text', headers: { 'HTTP_X_FORWARDED_FOR': ipadr } }).subscribe();  
            }
            // herefter kalder vi altid /api/login når agerendeCpr skiftes
            kaldLoginMedNytAgerendeCpr = true;
          });
      } 
  }

  private getFuldmagtsBruger(priv: string): Observable<UfmFuldmagtBruger> {
    // konverter 2 nestede promise kald til en observable der returnerer FuldmagtsBruger eller null
    this.keycloakService.isLoggedIn().then(loggedPaa => {
      if (!loggedPaa) {
        return this.fuldmagtsBruger$.next(null);
      }
      this.keycloakService.getToken().then(token => {
        if (!token) {
          console.error('UfmFuldmagtService/getFuldmagtsBruger: 2) keycloakService.getToken() returnerede en tom token');
          this.fuldmagtsBruger$.next(null);
        }
        const json: any = this.jwtService.decodeToken(token);
        // tjek at cpr er korrekt hvis der anvendes demo login fra us2000
        const jwtCpr: string = json.cpr;
        this.ufmSessionTimeoutService.erLoginOk(jwtCpr).subscribe(ok => {
          if (!ok) {
            this.keycloakService.logout();
            return;
          }
          // alt ok. returnerer fuldmagtsbruger.
          const fuldmagtsPrivilegier: string[] = json[priv] || [];
          const ufmFuldmagtBruger: UfmFuldmagtBruger = new UfmFuldmagtBruger(json.cpr, json.cvr, fuldmagtsPrivilegier);
          this.fuldmagtsBruger$.next(ufmFuldmagtBruger);
          this.ufmSessionTimeoutService.setErVirksomhed(ufmFuldmagtBruger.erVirksomhed);
          this.ufmSessionTimeoutService.setAgerendeCpr(ufmFuldmagtBruger.agerendeCpr);
        });
      }).catch(error => {
        console.error('jwt.getToken() fejlede med', error);
        this.fuldmagtsBruger$.next(null);
      });
    }).catch(error => {
      console.error('keycloak.isLoggedIn() fejlede med', error);
      this.fuldmagtsBruger$.next(null);
    });
    return this.fuldmagtsBruger$;
  }

  public getCachedFuldmagtsBruger(priv: string): Observable<UfmFuldmagtBruger> {
    if (!this.cachedFuldmagtsBruger) {
      this.getFuldmagtsBruger(priv).subscribe();
      this.cachedFuldmagtsBruger = true;
    }
    return this.fuldmagtsBruger$.pipe(
      filter(fuldmagtsbruger => fuldmagtsbruger ? true : false));
  }

  public reloadFuldmagtsBruger(priv: string): Observable<UfmFuldmagtBruger> {
    return this.getFuldmagtsBruger(priv).pipe(map(fuldmagtsBruger => {
      this.cachedFuldmagtsBruger = true;
      this.fuldmagtsBruger$.next(fuldmagtsBruger);
      return fuldmagtsBruger;
    }));
  }

}
