import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, BehaviorSubject } from 'rxjs';
import { filter, first, map, tap, distinctUntilChanged, mergeMap } from 'rxjs/operators';
import * as moment from 'moment/moment';

import { Backendtid } from './backendtid';
import { UfmBackendtid1ConfigService } from './ufm-backendtid1-config.service';
import { UfmFuldmagtService } from '@ufmit/ufm-fuldmagt';

@Injectable({
  providedIn: 'root'
})
export class UfmBackendtid1Service {

  public backendtid$: BehaviorSubject<Date> = new BehaviorSubject<Date>(null);
  private apiRequestIgang$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private backendDato: Date;

  constructor(
    private ufmBackendtid1ConfigService: UfmBackendtid1ConfigService,
    private http: HttpClient,
    private ufmFuldmagtService: UfmFuldmagtService) {
  }

  private konverterTilUTCDato(dato: Date): Date {
    return moment.utc([dato.getFullYear(), dato.getMonth(), dato.getDate()]).toDate();
  }

  private getBackendtid(): Observable<Backendtid> {
    return this.http.get<Backendtid>(this.ufmBackendtid1ConfigService.getProfilRequestUrl()).pipe(
      tap(profil => {
        // er der sat en backend test dato?
        const backendtid: moment.Moment = moment.utc(profil.backendtid);
        const nu: moment.Moment = moment.utc();
        if (nu.isSame(backendtid, 'date')) {
          this.backendDato = null;
        } else {
          this.backendDato = this.konverterTilUTCDato(backendtid.toDate());
        }
        this.backendtid$.next(this.nu());
        this.apiRequestIgang$.next(false);
      })
    );
  }

  private hentBackendtidVoid(): void {
    this.getBackendtid().subscribe();
  }
  private hentBackendtidObservable(): Observable<boolean> {
    if (this.apiRequestIgang$.value) {
      return this.apiRequestIgang$.pipe(
        filter(igang => !igang),
        first(),
        map(() => true),
      );
    }
    this.apiRequestIgang$.next(true);
    return this.getBackendtid()
      .pipe(
        map(() => true),
      );
  }
  hentBackendtid(returnerObservable?: boolean): Observable<boolean> {
    if (returnerObservable) {
      return this.hentBackendtidObservable();
    } else {
      this.hentBackendtidVoid();
    }
  }

  erBackendDatoSat(): boolean {
    return this.backendDato ? true : false;
  }

  dagsdato(): Date {
    if (this.erBackendDatoSat()) {
      return this.backendDato;
    } else {
      return this.konverterTilUTCDato(moment.utc().toDate());
    }
  }

  nu(): Date {
    if (this.erBackendDatoSat()) {
      const nu: Date = moment.utc().toDate();
      const dagsdato: Date = this.konverterTilUTCDato(nu);
      const diff: number = moment.utc(nu).diff(moment.utc(dagsdato));
      return moment.utc(this.backendDato).add(diff, 'milliseconds').toDate();
    } else {
      return moment.utc().toDate();
    }
  }

  hentBackendtidForAgerendeBruger(): Observable<Date> {
    return this.ufmFuldmagtService.fuldmagtsBruger$.pipe(
      map(ufmFuldmagtBruger => {
        if (ufmFuldmagtBruger && ufmFuldmagtBruger.agerendeCpr) {
          return ufmFuldmagtBruger.agerendeCpr;
        } else {
          return null;
        }
      }),
      distinctUntilChanged(),
      filter(agerendeCpr => agerendeCpr ? true : false),
      mergeMap(() => this.getBackendtid()),
      map(() => this.backendtid$.value)
    );
  }
}
