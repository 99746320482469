<form [formGroup]="form">
  <div class="row" (keydown.enter)="$event.preventDefault()">
    <label [attr.for]="fradatoId" class="sr-only">
      <span>Period</span> {{ index + 1 }}
      <span>out of</span> {{ antalPerioder }}.
      <span>must be filled. </span>
      <span>Enter date as d d - m m - y y y y</span>
      <span>From date. </span>
    </label>
    <div class="col-12">
      * 
      <input
        [attr.name]="fradatoId"
        [attr.id]="fradatoId"
        type="text"
        maxlength="10"
        placeholder="dd-mm-yyyy"
        autocomplete="off"
        class="form-control col-2"
        formControlName="fradato"
        bsDatepicker
        placement="bottom"
        [bsConfig]="{ dateInputFormat: 'DD-MM-YYYY', adaptivePosition: true }"
        class="col-3"
      />
      <label [attr.for]="tildatoId" class="sr-only">
        <span>Enter date as d d - m m - y y y y</span>
        <span>To date. </span>
      </label>
      <input
        [attr.name]="tildatoId"
        [attr.id]="tildatoId"
        type="text"
        maxlength="10"
        placeholder="dd-mm-yyyy"
        autocomplete="off"
        class="form-control col-2"
        formControlName="tildato"
        bsDatepicker
        placement="bottom"
        [bsConfig]="{ dateInputFormat: 'DD-MM-YYYY', adaptivePosition: true }"
        class="col-3"
      />
      <button (click)="fjernPeriode()" [hidden]="hideButton" [disabled]="!index" class="col-2 col-md-1 btn btn-dark btn-sm" (keydown.enter)="$event.target.click()">
        Remove
      </button>
    </div>
    <div class="col-5">
      <div *ngIf="form.controls.fradato.touched">
        <div *ngIf="form.errors && form.errors.fradato">
          <div *ngIf="form.errors.fradato.required" class="text-danger">
            From date must be filled out
          </div>
        </div>
        <div *ngIf="form.controls.fradato.errors" class="text-danger">
          From date is invalid. Use format DD-MM-YYYY
        </div>
      </div>
    </div>
    <div class="col-5">
      <div *ngIf="form.controls.tildato.touched">
        <div *ngIf="form.errors && form.errors.tildato">
          <div *ngIf="form.errors.tildato.required" class="text-danger">
            To date must be filled out
          </div>
        </div>
        <div *ngIf="form.controls.tildato.errors" class="text-danger">
          To date is invalid. Use format DD-MM-YYYY
        </div>
      </div>
    </div>
    <div class="col-2"></div>
    <div *ngIf="form.errors && form.errors.ugyldigPeriode" class="col-12 text-danger">
      From date must not be later than to date
    </div>
    <div *ngIf="form.errors && form.errors.intetUdfyldt && antalPerioder > 1" class="col-12 text-danger">
      From date and to date must be filled out
    </div>
  </div>
</form>
