import { InjectionToken } from '@angular/core';

export const UFM_BROWSERCACHE_CONFIG = new InjectionToken<UfmBrowsercacheConfig>('UFM_BROWSERCACHE_CONFIG');

// default tekster findes i html templates. Alternative tekster kan injectes på 3 måder:
// 1) statisk/byg: brug i18n tags. Bruges i ligestilling. Se messages.en.xlf.
// 2) statisk/byg: UfmBrowsercacheModule.forRoot(ufmBrowsercacheConfig) fra app.module.ts
// 3) dynamisk: opdater ufmBrowsercacheConfig dynamisk efter opstart og kald ufmBrowsercacheConfigService.genindlaesOpdateretKonfiguration når tekster er blevet opdateret
export interface UfmBrowsercacheConfigVisTekster {
    overskrift?: string; // blank
    forklaring?: string; // blank
    dinVersion?: string; // Din version:
    kraevetVersion?: string; // Krævet version:
    linkTekst?: string; // Læs mere om tekniske problemer på su.dk her.
}
export interface UfmBrowsercacheConfigTekster {
    tjekBrowsercacheBesked?: string,
    visBrowsercache?: UfmBrowsercacheConfigVisTekster;
}
export interface UfmBrowsercacheConfig {
    environment?: {
        apiPropertyNavn?: string; // api = environment.api. Dvs. navn på api property i environment.json filen
        endpoint?: string; // profil. <api><endpoint> giver det API endpoint hvorfra backendtid hentes, fx /api/browsercache
    };
    tekster?: UfmBrowsercacheConfigTekster;
}
