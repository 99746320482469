<div class="row" id="kvittering">
  <app-upload-kvittering-sidehoved class="col-12"></app-upload-kvittering-sidehoved>

  <div class="col-12 pt-4">
    <h4>Documentation of holiday as EU worker</h4>
  </div>

  <app-ferie-kvittering-perioder class="col-12" [perioder]="kvittering.feriePerioder"></app-ferie-kvittering-perioder>

  <div class="col-12 pt-3" *ngIf="filer.length > 0">
    Documentation for holiday:
    <div *ngFor="let fil of filer" class="col-12 pt-1">
      <strong class="ufm-black"> {{fil.filnavn}}</strong>
    </div>
  </div>

  <div class="col-12 pt-3" *ngIf="kvittering.bemaerkninger != null">
    Additional information for the application: <br />
    <strong class="ufm-black">{{ kvittering.bemaerkninger }}</strong>
  </div>

  <div class="col-12 pt-5">
    <h5> Please save or print your receipt for future use</h5>
  </div>
</div>
