<div class="row notranslate" translate="no" id="kvittering">
  <div class="col-12 pt-3">
    You have accepted the following terms: <br />
    <p>
      I have been informed that the Danish Agency for Higher Education and Science will manage my personal
      information in processing my case. I also certify that the information I have provided is correct. I am
      aware that incorrect information can result in having to pay back my SU.<br />
      Read more about the Agency processing personal information on su.dk.
    </p>
  </div>
</div>
