import { Injectable, Inject, Injector } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { UFM_PROPERTIES_CONFIG, UfmPropertiesConfig } from '@ufmit/ufm-properties';
import { UfmSessionConfig, UfmSessionConfigLogafTekster, UfmSessionConfigPopupTekster, UfmSessionConfigTekster, UfmSessionConfigTimeoutTekster, UFM_SESSION_CONFIG } from './ufm-session.config';

@Injectable({
  providedIn: 'root'
})
export class UfmSessionConfigService {

  public sessionKonfigurationOpdateret$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private ufmSessionConfig: UfmSessionConfig = {};
  private ufmPropertiesConfig: UfmPropertiesConfig = {};

  constructor(
    @Inject(Injector) private injector: Injector) {
    // hent ufmSessionConfiguration
    try {
      this.ufmSessionConfig = this.injector.get(UFM_SESSION_CONFIG);
    } catch (e) { }
    if (!this.ufmSessionConfig.logAfUrl) {
      this.ufmSessionConfig.logAfUrl = '/';
    }
    // hent ufmPropertiesConfig
    try {
      this.ufmPropertiesConfig = this.injector.get(UFM_PROPERTIES_CONFIG);
    } catch (e) { }
  }

  private getApiPropertyNavn(): string {
    let apiPropertyNavn = '/api';
    if (this.ufmSessionConfig && this.ufmSessionConfig.environment) {
      apiPropertyNavn = this.ufmSessionConfig.environment.apiPropertyNavn || '/api';
    }
    return apiPropertyNavn;
  }

  public getApiLogoutUrl(): string {
    const apiPropertyNavn = this.getApiPropertyNavn();
    const environment: any = this.ufmPropertiesConfig.environment || {};
    const api: string = environment[apiPropertyNavn] ? environment[apiPropertyNavn] : '/api';
    const apiLogoutUrl: string = api + '/logout';
    return apiLogoutUrl;
  }

  public getLogAfUrl(): string {
    if (this.ufmSessionConfig.logAfUrl) {
      return this.ufmSessionConfig.logAfUrl;
    } else {
      return '/';
    }
  }

  public getFrontendClientLogRequestUrl(): string {
    return this.getApiPropertyNavn() + '/frontend-client-log';
  }

  public getDemo(): boolean {
    const demo: boolean = this.ufmPropertiesConfig.environment ? this.ufmPropertiesConfig.environment.demo : false;
    return demo;
  }

  private findesTekster(): boolean {
    return this.ufmSessionConfig.tekster ? true : false;
  }

  private findesPopupTekster(): boolean {
    return this.findesTekster() && (this.ufmSessionConfig.tekster.popup ? true : false);
  }

  public getPopupAdvarselTekst(): string {
    if (this.findesPopupTekster() && this.ufmSessionConfig.tekster.popup.popupAdvarsel) {
      return this.ufmSessionConfig.tekster.popup.popupAdvarsel;
    } else {
      return 'Advarsel';
    }
  }

  public getPopupTimeoutTekst(): string {
    if (this.findesPopupTekster() && this.ufmSessionConfig.tekster.popup.popupTimeout) {
      return this.ufmSessionConfig.tekster.popup.popupTimeout;
    } else {
      return 'Timeout';
    }
  }

  public getPopupSessionUdloeberSnartTekst(): string {
    if (this.findesPopupTekster() && this.ufmSessionConfig.tekster.popup.popupSessionUdloeberSnart) {
      return this.ufmSessionConfig.tekster.popup.popupSessionUdloeberSnart;
    } else {
      return 'Din session udløber kl.';
    }
  }

  public getPopupSessionErUdloebetTekst(): string {
    if (this.findesPopupTekster() && this.ufmSessionConfig.tekster.popup.popupSessionErUdloebet) {
      return this.ufmSessionConfig.tekster.popup.popupSessionErUdloebet;
    } else {
      return 'Din session er udløbet';
    }
  }

  private getPopupTekster(): UfmSessionConfigPopupTekster {
    return {
      popupAdvarsel: this.getPopupAdvarselTekst(),
      popupTimeout: this.getPopupTimeoutTekst(),
      popupSessionUdloeberSnart: this.getPopupSessionUdloeberSnartTekst(),
      popupSessionErUdloebet: this.getPopupSessionErUdloebetTekst()
    };
  }

  private getTimeoutTekster(): UfmSessionConfigTimeoutTekster {
    if (this.ufmSessionConfig.tekster && this.ufmSessionConfig.tekster.timeout) {
      return this.ufmSessionConfig.tekster.timeout;
    } else {
      return {};
    }
  }

  private getLogafTekster(): UfmSessionConfigLogafTekster {
    if (this.ufmSessionConfig.tekster && this.ufmSessionConfig.tekster.logaf) {
      return this.ufmSessionConfig.tekster && this.ufmSessionConfig.tekster.logaf;
    } else {
      return {};
    }
  }

  public getTekster(): UfmSessionConfigTekster {
    return {
      popup: this.getPopupTekster(),
      timeout: this.getTimeoutTekster(),
      logaf: this.getLogafTekster(),
      sideLaesFlere:
        this.ufmSessionConfig.tekster && this.ufmSessionConfig.tekster.sideLaesFlere ?
          this.ufmSessionConfig.tekster.sideLaesFlere :
          null,
      sideGodeRaad:
        this.ufmSessionConfig.tekster && this.ufmSessionConfig.tekster.sideGodeRaad ?
          this.ufmSessionConfig.tekster.sideGodeRaad :
          null,
      sideGodeRaadLink:
        this.ufmSessionConfig.tekster && this.ufmSessionConfig.tekster.sideGodeRaadLink ?
          this.ufmSessionConfig.tekster.sideGodeRaadLink :
          null
    };
  }

  public genindlaesOpdateretKonfiguration(): void {
    this.sessionKonfigurationOpdateret$.next(true);
  }

  public getSessionConfig(): UfmSessionConfig {
    return {
      logAfUrl: this.getLogAfUrl(),
      tekster: this.getTekster(),
      environment: {
        apiPropertyNavn: this.getApiLogoutUrl()

      }
    };
  }

  public getKaldLogin(): boolean {
    return (this.ufmSessionConfig.apiLoginEndpoint ? true : false);
  }

  public getApiLoginUrl(): string {
    const apiPropertyNavn = this.getApiPropertyNavn();
    const environment: any = this.ufmPropertiesConfig.environment || {};
    const api: string = environment[apiPropertyNavn] ? environment[apiPropertyNavn] : '/api';
    const apiLogoutUrl: string = api + '/' + this.ufmSessionConfig.apiLoginEndpoint;
    return apiLogoutUrl;
  }

}
