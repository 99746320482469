<div class="row">
  <div class="col-12" (click)="erTopSynlig(erElementSynligt(topElement))" #topElement></div>

  <div *ngIf="!h" class="col-12 mt-0">
    <div *ngIf="null else knap"></div>
  </div>
  <div *ngIf="h2" class="col-12 mt-0">
    <h2>
      <div *ngIf="null else knap"></div>
    </h2>
  </div>
  <div *ngIf="h3" class="col-12 mt-0">
    <h3>
      <div *ngIf="null else knap"></div>
    </h3>
  </div>
  <div *ngIf="h4" class="col-12 mt-0">
    <h4>
      <div *ngIf="null else knap"></div>
    </h4>
  </div>
  <div *ngIf="h5" class="col-12 mt-0">
    <h5>
      <div *ngIf="null else knap"></div>
    </h5>
  </div>
  <div *ngIf="h6" class="col-12 mt-0">
    <h6>
      <div *ngIf="null else knap"></div>
    </h6>
  </div>

  <ng-template #knap>
    <button
      type="submit"
      [disabled]="disabled"
      (click)="accordion(true)"
      class="btn d-block w-100 text-start"
      [ngClass]="buttonClass"
      [ngStyle]="buttonStyle"
      [attr.aria-label]="accordionAriaLabelMedStatusOgStatusTekst"
      [attr.aria-expanded]="accordionVis"
      #accordionButton
    >
      <app-accordion-prefix [vis]="accordionVis"></app-accordion-prefix>
      {{ overskrift }}
      <app-accordion-status [status]="status" [ngClass]="statusClass"></app-accordion-status>
      <app-accordion-tekst *ngIf="statusTekst && !accordionVis" [vis]="accordionVis" [status]="status" [tekst]="statusTekst"></app-accordion-tekst>
    </button>
  </ng-template>

  <div *ngIf="accordionVis" class="mb-3 col-12 pt-3 mb-0">
    <div [@foldudAmination]="accordionAmination">
      <ng-content></ng-content>
    </div>
  </div>

  <div class="col-12" (click)="erBundSynlig(erElementSynligt(bottomElement))" #bottomElement></div>
</div>
