<div class="row" id="kvittering">
  <app-upload-kvittering-sidehoved class="col-12"></app-upload-kvittering-sidehoved>

  <div class="col-12 pt-4">
    <h4>You become involuntarily unemployed</h4>
  </div>

  <div class="col-12 pt-3">
    Submit documentation showing that you have been dismissed from your job (e.g. employment termination letter).
  </div>
  <app-kvittering-filer class="col-12" [filer]="filer" [parameternavn]="'opsigelse'">
  </app-kvittering-filer>

  <div class="col-12 pt-3">
    Submit documentation showing what date you registered as a jobseeker at jobnet.dk or reported to your local job centre.
  </div>
  <app-kvittering-filer class="col-12" [filer]="filer" [parameternavn]="'jobcenter'">
  </app-kvittering-filer>

  <div class="col-12 pt-3" *ngIf="kvittering.bemaerkninger !== null">
    You can provide supplemental information here. <br />
    <strong class="ufm-black">{{ kvittering.bemaerkninger }}</strong>
  </div>

  <app-upload-kvittering-gdpr class="col-12"></app-upload-kvittering-gdpr>
</div>
