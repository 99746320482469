import { Component, OnInit } from '@angular/core';
import {environment} from '../../../../environments/environment';
import {SendAnsoegningService} from '../../utils/service/send-ansoegning.service';

@Component({
  selector: 'app-upload-ikke-tilladt',
  templateUrl: './upload-ikke-tilladt.component.html',
  styleUrls: ['./upload-ikke-tilladt.component.scss']
})
export class UploadIkkeTilladtComponent implements OnInit {

  constructor(private sendAnsoegningService: SendAnsoegningService) { }

  ngOnInit() {
    this.sendAnsoegningService.deleteCookie('modul');
  }

  tilbageTilMinsu(): void {
    window.location.href = environment.minsuUrl;
  }
}
