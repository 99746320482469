<form [formGroup]="form">
  <div class="row">
    <div class="col-12 h3" role="heading" aria-level="2">
      You get a new job
    </div>

    <div class="col-12 pt-3" role="heading" aria-level="3">
      <div class="row">
        <div class="col-12" role="heading" aria-level="3">
          If you get a new job, you must submit your new employment contract.
          We always make a specific assessment of all the information in your case about your employment when we assess
          whether you may be considered a worker under EU law.
        </div>
        <div class="col-12 pt-1">
          <a href="https://su.dk/10964" target="_blank">You can read more about the rules on su.dk.</a>
        </div>
      </div>
    </div>

    <app-loenoplysninger class="col-12" [form]="form.controls.loenoplysninger"
                         [hours]="'How many hours a week will you be working in your new job?'"
                         [wage]="'Are you paid by the hour or by the month?'"
                         [antalTimerPrUge]="'antalTimerPrUge'"
                         [timeEllerMaanedsLoen1]="'timeEllerMaanedsLoen1'"
                         [timeEllerMaanedsLoen2]="'timeEllerMaanedsLoen2'"
                         [timeEllerMaanedsLoen3]="'timeEllerMaanedsLoen3'"
                         [loenKr]="'loenKr'">
    </app-loenoplysninger>

    <div class="col-12 pt-3">
      <div class="form-check">
        <input id="ansaettelseTidsbegraenset" name="ansaettelseTidsbegraenset" type="checkbox" class="form-check-input" formControlName="ansaettelseTidsbegraenset" />
        <label for="ansaettelseTidsbegraenset" class="form-check-label">
          <span class="visually-hidden">Is the employment for a fixed term/period of time</span>
          Is the employment for a fixed term/period of time?
        </label>
      </div>
    </div>

    <div class="col-12 pt-3">
      Where will you be working? Please state the name of your employer,
      and if possible, the company registration number (CVR number).
      <br/>
      <br/>
      <label for="arbejdsgiver">
        <span class="visually-hidden">Required</span>* Name of your employer
      </label>
      <input type="text" maxlength="50" id="arbejdsgiver" class="form-control" formControlName="arbejdsgiver" />
      <div *ngIf="form.controls.arbejdsgiver.touched && form.controls.arbejdsgiver.errors" class="text-danger">
        Company name is missing
      </div>
    </div>

    <div class="col-12 pt-3">
      <label for="cvrnummer">CVR number</label>
      <input type="text" maxlength="8" id="cvrnummer" class="form-control" formControlName="cvrnummer" />
      <div *ngIf="form.controls.cvrnummer.touched && form.controls.cvrnummer.errors" class="text-danger">
        CVR number must be a number (min & max 8 long)
      </div>
    </div>

    <div class="col-12 pt-3">
      <label for="startdato">
        <span class="visually-hidden">Required</span>* What date will you start working in your new job?
        <span class="visually-hidden">
          Enter date as d d "-" m m "-"" y y y y
        </span>
      </label>
      <input
        name="startdato"
        id="startdato"
        type="text"
        required
        maxlength="10"
        placeholder="dd-mm-yyyy"
        autocomplete="off"
        class="form-control"
        formControlName="startdato"
        bsDatepicker
        placement="bottom"
        [bsConfig]="{ dateInputFormat: 'DD-MM-YYYY', adaptivePosition: true }"
        (onHidden)="datoVaeglerSkjul()"
        (onShown)="datoVaeglerVis()"
        class="col-12"
        #startdato
      />
      <div *ngIf="form.controls.startdato.touched && datovaeglerTilstand !== 1 && form.controls.startdato.errors" class="text-danger">
        Date is missing
      </div>
    </div>

    <div class="d-none" #uploadLabel>
      <span class="visually-hidden">Required</span>* Submit your new employment contract.
    </div>
    <div class="d-none" #uploadAriaLabelTilfoejFiler>
      Add more files for employment contract
    </div>
    <div class="d-none" #uploadAriaLabelFil>
      Upload file with your new employment contract
    </div>
    <div class="d-none" #uploadManglerTekst>
      Documentation is missing
    </div>
    <app-filer
      class="col-12 py-3"
      [form]="form.controls.filer"
      [accept]="'.pdf'"
      [idPrefix]="'ansaettelseskontrakt'"
      [ariaLabelTilfoejFiler]="uploadAriaLabelTilfoejFiler.innerText"
      [ariaLabelFil]="uploadAriaLabelFil.innerText"
      [filManglerTekst]="uploadManglerTekst.innerText"
      [label]="uploadLabel.innerHTML"
      (keydown.enter)="$event.preventDefault()"
      [fjern]="'Remove'"
    >
    </app-filer>

    <div class="col-12 pt-3">
      <div class="form-check">
        <input id="harStadigvaekTidligereArbejde" name="harStadigvaekTidligereArbejde" type="checkbox" class="form-check-input" formControlName="harStadigvaekTidligereArbejde" />
        <label for="harStadigvaekTidligereArbejde" class="form-check-label">
          <span class="visually-hidden">Will you still be working in your current job</span>
          Will you still be working in your current job?
        </label>
      </div>
    </div>

    <app-fold-ud class="col-12" [vis]="tidligereArbejdeJaVis">
      <div class="row">
        <div class="col-12">
          <h5>Current work</h5>
        </div>
        <app-loenoplysninger
          class="col-12"
          [form]="form.controls.loenoplysningerTidligereArbejde"
          [hours]="'How many hours a week will you be working in your current job?'"
          [wage]="'Are you paid by the hour or by the month?'"
          [currentJob]="tidligereArbejdeJaVis"
          [antalTimerPrUge]="'antalTimerPrUgeTidArb'"
          [timeEllerMaanedsLoen1]="'timeEllerMaanedsLoen1TidArb'"
          [timeEllerMaanedsLoen2]="'timeEllerMaanedsLoen2TidArb'"
          [timeEllerMaanedsLoen3]="'timeEllerMaanedsLoen3TidArb'"
          [loenKr]="'loenKrTidArb'"
        >
        </app-loenoplysninger>
      </div>
    </app-fold-ud>

    <div class="col-12 pt-5">
      <label for="bemaerkninger">
        You can provide supplemental information here.
      </label>
      <span class="float-end form-text text-muted"><small>{{ bemaerkningerTaeller$ | async }}</small></span>
      <input type="text" id="bemaerkninger" maxlength="1200" class="form-control" formControlName="bemaerkninger" />
    </div>

    <app-upload-gdpr class="col-12" [form]="form"></app-upload-gdpr>

    <div class="col-12 pt-3">
      <button (click)="startSendAnsoegning()" type="submit" [disabled]="form.invalid || senderAnsoegning" class="btn btn-primary btn-lg d-block w-100">
        Submit documentation
      </button>
    </div>
    <div *ngIf="senderAnsoegning" class="col-12 pt-3 text-center">
      <fa-icon [icon]="faSpinner" [spin]="true" [size]="'2x'" [pulse]="true"></fa-icon>
    </div>
    <div *ngIf="senderAnsoegning" class="col-12 pt-3 text-center" role="alert">
      Submitting documentation (may take several minutes)
    </div>
  </div>
</form>
