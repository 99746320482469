export class UfmFuldmagtBruger {

   readonly harPrivilegier: boolean;
   readonly erVirksomhed: boolean;
   public agerendeCpr: string;
   public agererMedFuldmagt = false;

   constructor(public egetCpr: string, public cvr: string, public readonly fuldmagtsPrivilegier: string[]) {
      this.harPrivilegier = Array.isArray(fuldmagtsPrivilegier) && (Object.keys(fuldmagtsPrivilegier).length > 0);
      if (cvr) {
         this.erVirksomhed = true;
      } else {
         this.setAgerendeCpr(egetCpr);
      }
   }

   setAgerendeCpr(agerendeCpr: string): void {
      this.agerendeCpr = agerendeCpr;
      if (this.erVirksomhed) {
         this.agererMedFuldmagt = true ;
      } else {
         this.agererMedFuldmagt = (this.egetCpr !== agerendeCpr);
      }
   }

   getAgerendeCpr(): string {
      return this.agerendeCpr;
   }

}
