<div *ngIf="fuldmagtsBruger && fuldmagtsBruger.harPrivilegier && fuldmagtsBruger.agerendeCpr" class="container pt-1">
    <div class="alert alert-warning mt-3">
        <h2 #htmlOverskrift i18n="@@ufmit fuldmagt overskrift">Fuldmagt</h2>
        <p class="alert-warning" *ngIf="fuldmagtsBruger.erVirksomhed">
            <span #htmlCvrAgererSomFuldmagtshaver
            i18n="@@fuldmagt cvr agerer som fuldmagtshaver">
            Du agerer med fuldmagtprivilegier på vegne af personen
        </span>
        {{fuldmagtsBruger.agerendeCpr}}</p>
        <p class="alert-warning" *ngIf="!fuldmagtsBruger.erVirksomhed && fuldmagtsBruger.agererMedFuldmagt">
            <span #htmlCprAgererSomFuldmagtshaver
            i18n="@@fuldmagt cpr agerer som fuldmagtshaver">
            Du agerer med fuldmagtprivilegier på vegne af personen
        </span>
            {{fuldmagtsBruger.agerendeCpr}}</p>
        <p class="alert-warning" *ngIf="!fuldmagtsBruger.erVirksomhed && !fuldmagtsBruger.agererMedFuldmagt" #htmlHarFuldmagt i18n="@@ufmit fuldmagt har fuldmagt">
            Du har fuldmagtsprivilegier til at agere på vegne af en anden person
        </p>
        <span
            *ngIf="(fuldmagtsBruger.erVirksomhed && fuldmagtsBruger.fuldmagtsPrivilegier.length > 1) || (!fuldmagtsBruger.erVirksomhed)">
            <input class="btn btn-primary" (click)="skift()" type="submit"
                value="Skift bruger" #htmlSkiftBrugerKnap i18n-value="@@ufmit fuldmagt skift bruger knap">
        </span>
    </div>
</div>