import { Injectable, Inject, Injector } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { timeout, tap } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';

import { UfmFejlhaandteringConfig, UFM_FEJLHAANDTERING_CONFIG } from './ufm-fejlhaandtering.config';
import { UfmFejlhaandteringService } from './ufm-fejlhaandtering.service';

@Injectable()
export class UfmInterceptorTimeout implements HttpInterceptor {

  private ufmFejlhaandteringConfig: UfmFejlhaandteringConfig = {};

  constructor(
    @Inject(Injector) private injector: Injector,
    private ufmFejlhaandteringService: UfmFejlhaandteringService) {
    // hent ufmFejlhaandteringConfig (popupIngenForbindelseTekst, popupNetvaerksfejlTekst)
    try {
      this.ufmFejlhaandteringConfig = this.injector.get(UFM_FEJLHAANDTERING_CONFIG);
    } catch (e) { }
  }

  // Need to get ToastrService from injector rather than constructor injection to avoid cyclic dependency error
  private get toastrService(): ToastrService {
    return this.injector.get(ToastrService);
  }
  
  // dynamisk config getter
  getPopupIngenForbindelseTekst(): string {
    if (this.ufmFejlhaandteringConfig.tekster && this.ufmFejlhaandteringConfig.tekster.popupIngenForbindelseTekst) {
      return this.ufmFejlhaandteringConfig.tekster.popupIngenForbindelseTekst;
    } else {
      return 'Der er i øjeblikket ingen forbindelse til hjemmesiden. I tilfælde af fejlsøgning kan ID {{kortSessionId}} oplyses';
    }
  }
  getPopupNetvaerksfejlTekst(): string {
    if (this.ufmFejlhaandteringConfig.tekster && this.ufmFejlhaandteringConfig.tekster.popupNetvaerksfejlTekst) {
      return this.ufmFejlhaandteringConfig.tekster.popupNetvaerksfejlTekst;
    } else {
      return 'Netværksfejl';
    }
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (request.headers.get('NoInterceptorTimeout')) {
      return next.handle(request);
    }
    return next.handle(request).pipe(
      timeout(30000),
      tap(
        {
          error: (error) => {
            if (error.name === 'TimeoutError') {
              console.error('Intet svar website - ', error, ', url=', request.url);
              const kortSessionId: string = this.ufmFejlhaandteringService.hentKortSessionid();
              // hent toast tekster
              let popupIngenForbindelseTekst = this.getPopupIngenForbindelseTekst();
              popupIngenForbindelseTekst = popupIngenForbindelseTekst.replace('{{kortSessionId}}', kortSessionId);
              const popupNetvaerksfejlTekst = this.getPopupNetvaerksfejlTekst();
              // toast
              try {
                this.toastrService.error(popupIngenForbindelseTekst,
                  popupNetvaerksfejlTekst,
                  { tapToDismiss: true, disableTimeOut: true, closeButton: true });
              } catch (e) {
                // todo: fix TypeError: Cannot read property 'create' of undefined at ToastrService._buildNotification (ngx-toastr.js:568)
                console.error(e);
              }
            }
          }
        }
      ));
  }

}
