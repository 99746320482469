<ufm-user-tracking class="no-print"></ufm-user-tracking>
<ngx-loading-bar color="#e6821e" [includeSpinner]="false"></ngx-loading-bar>
<app-menu></app-menu>
<ufm-side-backendtid class="d-flex flex-row"></ufm-side-backendtid>
<ufm-fuldmagt [priv]="priv"></ufm-fuldmagt>

<ufm-tjek-browsercache></ufm-tjek-browsercache>
<div class="wrapper d-flex flex-column" style="background-image: url('/api/miljo.svg')">
  <main class="container flex-fill" role="main">
    <!-- navigation -->
    <div class="d-none" aria-hidden="true" #naesteSektion i18n="@@app næste sektion">
      Tryk Bekræft for at fortsætte til næste sektion
    </div>
    <!-- popup meddelelser -->
    <div class="d-none" aria-hidden="true" #kunPdfFiler i18n="@@app popup kun pdf filer">
      Du må kun vedhæfte filer i pdf-format
    </div>
    <div class="d-none" aria-hidden="true" #maxStoerrelse i18n="@@app popup max størrelse">
      Du må maksimalt vedhæfte {{ maxFilUploadTekst }}
    </div>
    <div class="d-none" aria-hidden="true" #skiftSprogVent i18n="@@app popup skift sprog vent">
      Skifter sprog. Vent venligst.
    </div>
    <div class="d-none" aria-hidden="true" #skiftSprogFejl i18n="@@app popup skift sprog fejl">
      Du kan desværre ikke skifte sprog, uden at den indtastede ansøgning nulstilles.
      Du skal derfor starte forfra, hvis du skifter sprog.
    </div>

    <!-- UfmSessionModule tekster -->
    <div class="d-none" aria-hidden="true" #sessionPopupAdvarsel i18n="@@ufmit session popup advarsel">
      Advarsel
    </div>
    <div class="d-none" aria-hidden="true" #sessionPopupTimeout i18n="@@ufmit session popup timeout">
      Timeout
    </div>
    <div class="d-none" aria-hidden="true" #sessionPopupUdloeberSnart i18n="@@ufmit session popup session udløber snart">
      Din session udløber kl.
    </div>
    <div class="d-none" aria-hidden="true" #sessionPopupErUdloebet i18n="@@ufmit session popup session er udløbet">
      Din session er udløbet
    </div>

    <!-- UfmFejlHaandteringModule tekster -->
    <div class="d-none" aria-hidden="true" #fejlhaandteringPopupOverskrift i18n="@@fejlhaandtering popup overskrift">
      Fejl
    </div>
    <div class="d-none" aria-hidden="true" #fejlhaandteringPopupIngenForbindelseTekst i18n="@@fejlhaandtering popup ingen forbindelse tekst">
      Der er i øjeblikket ingen forbindelse til hjemmesiden.
      I tilfælde af fejlsøgning kan ID {{ kortSessionId }} oplyses
    </div>
    <div class="d-none" aria-hidden="true" #fejlhaandteringPopupNetvaerksfejlTekst i18n="@@fejlhaandtering popup netværksfejl tekst">
      Netværksfejl
    </div>

    <!-- ja/nej pipe -->
    <div class="d-none" aria-hidden="true" #svarJa i18n="@@app janej pipe ja">
      Ja
    </div>
    <div class="d-none" aria-hidden="true" #svarNej i18n="@@app janej pipe nej">
      Nej
    </div>
    <div class="d-none" aria-hidden="true" #ugyldigDato i18n="@@app langdato pipe ugyldig dato">
      Ugyldig dato
    </div>
    <router-outlet></router-outlet>
  </main>
  <app-side-bund></app-side-bund>
</div>
