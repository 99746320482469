import { Component, OnInit, ViewChild, ElementRef, DoCheck } from '@angular/core';

import { UfmBackendtid1Config } from './ufm-backendtid1.config';
import { UfmBackendtid1ConfigService } from './ufm-backendtid1-config.service';
import { UfmBackendtid1Service } from './ufm-backendtid1.service';

@Component({
  selector: 'ufm-side-backendtid',
  templateUrl: './ufm-backendtid1.component.html',
  styleUrls: ['./ufm-backendtid1.component.scss']
})
export class UfmBackendtid1Component implements OnInit, DoCheck {

  hentet: Date;
  @ViewChild('backendtidHentet', { static: false }) backendtidHentet: ElementRef;
  private erUfmModulTeksterOpdateret = false;

  constructor(
    private ufmBackendtid1Service: UfmBackendtid1Service,
    private ufmBackendtid1ConfigService: UfmBackendtid1ConfigService) { }

  ngOnInit() {
    this.ufmBackendtid1Service.hentBackendtidForAgerendeBruger().subscribe(
      hentet => { this.hentet = hentet; }
    );
    this.ufmBackendtid1ConfigService.backendtid1KonfigurationOpdateret$.subscribe(opdateret => {
      if (opdateret) {
        this.opdaterLabelPgaOpdateretKonfiguration();
      }
    });
  }

  ngDoCheck(): void {
    if (this.erUfmModulTeksterOpdateret || !this.backendtidHentet) {
      return;
    }
    this.erUfmModulTeksterOpdateret = true;
    this.opdaterLabelPgaOpdateretKonfiguration();
  }

  private opdaterLabelPgaOpdateretKonfiguration(): void {
    const ufmBackendtid1Config: UfmBackendtid1Config = this.ufmBackendtid1ConfigService.getBackendtid1Config();
    if (ufmBackendtid1Config && ufmBackendtid1Config.tekster && ufmBackendtid1Config.tekster.hentet) {
      this.backendtidHentet.nativeElement.innerText = ufmBackendtid1Config.tekster.hentet;
    }
  }

}
