import { Pipe, PipeTransform, Inject, LOCALE_ID } from '@angular/core';
import { DatePipe } from '@angular/common';

import { AppService } from '../../../app.service';
import { DatoService } from '../dato.service';

@Pipe({
  name: 'langdato'
})
export class LangdatoPipe implements PipeTransform {

  constructor(
    private appService: AppService,
    private datePipe: DatePipe,
    private datoService: DatoService,
    @Inject(LOCALE_ID) private locale: string) { }

  transform(value: any, optional?: boolean): any {
    if (!value) {
      return optional ? null : this.appService.hentSvarMangler();
    }
    const dato: Date = this.datoService.konverterTilUTCDato(value);
    try {
      return this.datePipe.transform(dato, 'longDate', 'UTC', this.locale);
    } catch (e) {
      console.error('Ugyldig dato', value, e.message);
      return this.appService.hentUgyldigDato();
    }
  }
}
