import { Component } from '@angular/core';

@Component({
  selector: 'app-upload-kvittering-gdpr',
  templateUrl: './upload-kvittering-gdpr.component.html',
  styleUrls: ['./upload-kvittering-gdpr.component.scss']
})
export class UploadKvitteringGdprComponent {

  constructor() { }
  
}
