import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { UfmSessionLoginService, UfmSessionTimeoutService } from '@ufmit/ufm-session';

@Injectable({
  providedIn: 'root'
})
export class LoggetPaaGuard  {

  constructor(private sessionTimeoutService: UfmSessionTimeoutService, private loginService: UfmSessionLoginService) {}

  canActivate(next: ActivatedRouteSnapshot,
              state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    return this.sessionTimeoutService
      .erLoggetPaaObservableTilBrugIGuards()
      .pipe(
        tap((erLoggetPaa) => {
          if (!erLoggetPaa) {
            this.loginService.logPaa();
          }
        })
    );
  }
}
