<form [formGroup]="form">
    <div class="row mb-3">
        <div class="col-12">
            <div class="row mb-3" role="radiogroup">
                <div class="col-10 pe-0">
                    <label [attr.id]="felt + '1'" class="form-check-label">
                        <span *ngIf="required">
                            <span aria-hidden="true">*</span>
                            <span class="visually-hidden" i18n="@@shared skal besvares">Skal besvares.</span>
                        </span>
                        <span [innerHTML]="spoergsmaalSafe"></span>
                    </label>
                </div>
                <div class="col-2 ps-0 d-flex justify-content-end">
                    <div class="form-check form-check-inline">
                        <input
                            type="radio"
                            [attr.name]="felt"
                            [attr.id]="felt + '2'"
                            value="J"
                            formControlName="{{ felt }}"
                            (keydown.enter)="$event.preventDefault()"
                            [attr.aria-checked]="ariaCheckedJa"
                            [attr.aria-controls]="ariaControlsJa"
                            class="form-check-input"
                        />
                        <label [attr.for]="felt + '2'" role="radio" class="form-check-label">
                            <span class="visually-hidden" [innerHTML]="spoergsmaalSafe"></span>
                            <span *ngIf="required" class="visually-hidden" i18n="@@shared skal besvares">
                                Skal besvares.
                            </span>
                            <span i18n="@@jaNejRadiogroup label ja">Ja</span>
                        </label>
                    </div>
                    <div class="form-check form-check-inline me-0">
                        <input
                            type="radio"
                            [attr.name]="felt"
                            [attr.id]="felt + '3'"
                            value="N"
                            formControlName="{{ felt }}"
                            (keydown.enter)="$event.preventDefault()"
                            [attr.aria-checked]="ariaCheckedNej"
                            [attr.aria-controls]="ariaControlsNej"
                            class="form-check-input"
                        />
                        <label [attr.for]="felt + '3'" role="radio" class="form-check-label">
                            <span class="visually-hidden" [innerHTML]="spoergsmaalSafe"></span>
                            <span *ngIf="required" class="visually-hidden" i18n="@@shared skal besvares">
                                Skal besvares.
                            </span>
                            <span i18n="@@jaNejRadiogroup label nej">Nej</span>
                        </label>
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="form.controls[felt].touched && (form.controls[felt].errors && form.controls[felt].errors.required || form.errors && form.errors[felt] && form.errors[felt].required)"
            [innerHTML]="svarManglerSafe" class="col-12 text-danger" role="alert" aria-atomic="true" #error>
        </div>
    </div>
</form>