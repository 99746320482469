<form [formGroup]="form">
  <div class="d-flex flex-row mb-3">
    <div class="d-none" #filErIkkeValgt i18n="@@utils filer fil ej valgt">Der er ikke valgt nogen fil</div>
    <div class="d-none" #filFjernTekst i18n="@@utils filer fil fjern skærmlæser">Fjern fil</div>
    <div class="col-lg-10 col-md-9 col">
      <input
        type="file"
        [attr.id]="id"
        accept=".pdf"
        (change)="uploadFil($event)"
        class="form-control-file form-control pt-1 pb-0"
        [ngClass]="fileclass()"
        formControlName="fil"
        (keydown.enter)="$event.target.click()"
        [attr.aria-label]="ariaLabel + '. ' + (form.controls.filnavn.value ? form.controls.filnavn.value : filErIkkeValgt.innerText)"
        #fil
      />
      <div *ngIf="fakeFileInput()" class="col-6 col-md-9 border ps-0" [ngStyle]="fakefilnavn">
        {{ form.controls.filnavn.value }}
      </div>
    </div>
    <div class="col-2 col-md-1 text-center">
      {{ stoerrelse }}
    </div>
    <button
      (click)="fjernFil()"
      [disabled]="fjernFilDisabled()"
      class="col-2 col-md-2 col-lg-1 mt-auto btn btn-dark btn-sm"
      (keydown.enter)="$event.target.click()"
      [attr.aria-label]="filFjernTekst.innerText + ' ' + form.controls.filnavn.value"
      i18n="@@utils filer fil fjern">
      {{fjern}}
    </button>
  </div>
</form>