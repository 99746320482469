<div class="row">

    <div class="col-12">
        <h1>{{ popupSessionErUdloebet }}</h1>
    </div>
    <div class="col-12" #htmlTekst1 i18n="@@ufmit session timeout tekst1">
        Du har været inaktiv i noget tid, og du skal derfor logge på igen.
    </div>
    <div class="col-12" #htmlTekst2 i18n="@@ufmit session timeout tekst2">
        Har du også været logget på andre offentlige løsninger (som boger.dk, sundhed.dk eller lignende), kan det være, du fortsat er logget på der.
    </div>
    <div class="col-12" #htmlTekst3 i18n="@@ufmit session timeout tekst3">
        Husk altid, at hvis du har været logget på fra en computer, som andre har adgang til, skal du lukke browseren, inden du forlader den.
    </div>
    <div class="col-12" #htmlTekst4 i18n="@@ufmit session timeout tekst4">
        Først når du lukker browser-vinduet, kan du være sikker på, at ingen får adgang til dine personlige oplysninger.
    </div>
    <div class="col-12 pt-3">
        <p>
            <span #htmlLaesFlere i18n="@@ufmit session side laes flere">Læs mere</span>
            <a href="https://www.nemid.nu/dk-da/pas_paa_dit_nemid/gode_raad/" #htmlGodeRaad i18n="@@ufmit session side gode raad" i18n-href="@@ufmit session side gode raad link">
                om IT-sikkerhed og NemID/MitID her.
            </a>
        </p>
    </div>

</div>