import { Component, OnInit, Input } from '@angular/core';
import { Fil } from '../../../utils/filer/fil/fil';

@Component({
  selector: 'app-kvittering-filer',
  templateUrl: './kvittering-filer.component.html',
  styleUrls: ['./kvittering-filer.component.scss']
})
export class KvitteringFilerComponent implements OnInit {

  @Input() filer: Fil[];
  @Input() parameternavn: string;
  @Input() filerEftersendes: boolean;

  filnavne: Fil[] = [];

  constructor() { }

  ngOnInit() {
    for (const fil of this.filer) {
      if (fil.parameternavn === this.parameternavn) {
        this.filnavne.push(fil);
      }
    }
  }

}
