import {Injectable, ElementRef, EventEmitter} from '@angular/core';
import {AppComponent} from './app.component';
import {Observable, timer, of} from 'rxjs';
import {map} from 'rxjs/operators';

import {environment} from '../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AppService {

  constructor() {}

  private appComponent: AppComponent;

  dannerNytArbejdeKvittering$: EventEmitter<boolean> = new EventEmitter<boolean>();
  nytArbejdeKvitteringErKlar$: EventEmitter<null> = new EventEmitter<null>();
  dannerStopArbejdeKvittering$: EventEmitter<boolean> = new EventEmitter<boolean>();
  stopArbejdeKvitteringErKlar$: EventEmitter<null> = new EventEmitter<null>();
  dannerAfskedigelseKvittering$: EventEmitter<boolean> = new EventEmitter<boolean>();
  afskedigelseKvitteringErKlar$: EventEmitter<null> = new EventEmitter<null>();
  dannerOevrigeHenvendelserKvittering$: EventEmitter<boolean> = new EventEmitter<boolean>();
  oevrigeHenvendelserKvitteringErKlar$: EventEmitter<null> = new EventEmitter<null>();
  dannerUarbejdsdygtigKvittering$: EventEmitter<boolean> = new EventEmitter<boolean>();
  uarbejdsdygtigKvitteringErKlar$: EventEmitter<null> = new EventEmitter<null>();
  dannerFerieKvittering$: EventEmitter<boolean> = new EventEmitter<boolean>();
  ferieKvitteringErKlar$: EventEmitter<null> = new EventEmitter<null>();

  injectAppComponent(appComponent: AppComponent): void {
    this.appComponent = appComponent;
  }

  hentNaesteSektionTekst(): string {
    if (this.appComponent) {
      const naesteSektion: ElementRef = this.appComponent.naesteSektion;
      if (naesteSektion) {
        return naesteSektion.nativeElement.innerText;
      }
    }
    return 'Press OK to continue with the next section';
  }

  hentFejlTekst(): string {
    if (this.appComponent) {
      const fejlhaandteringPopupOverskrift: ElementRef = this.appComponent.fejlhaandteringPopupOverskrift;
      if (fejlhaandteringPopupOverskrift) {
        return fejlhaandteringPopupOverskrift.nativeElement.innerText;
      }
    }
    return 'Error';
  }

  hentKunPdfFilerTekst(): string {
    if (this.appComponent) {
      const kunPdfFiler: ElementRef = this.appComponent.kunPdfFiler;
      if (kunPdfFiler) {
        return kunPdfFiler.nativeElement.innerText;
      }
    }
    return 'You may only upload PDF documents';
  }

  hentMaxStoerrelse(): string {
    if (this.appComponent) {
      const maxStoerrelse: ElementRef = this.appComponent.maxStoerrelse;
      if (maxStoerrelse) {
        return maxStoerrelse.nativeElement.innerText;
      }
    }
    return 'You must upload a maximum of ' + environment.maxFilUploadTekst;
  }

  hentSkiftSprogFejlTekst(): string {
    if (this.appComponent) {
      const skiftSprogFejl: ElementRef = this.appComponent.skiftSprogFejl;
      if (skiftSprogFejl) {
        return skiftSprogFejl.nativeElement.innerText;
      }
    }
    return 'Unfortunately, you cannot change language without losing the entered application';
  }

  hentSkiftSprogVentTekst(): string {
    if (this.appComponent) {
      const skiftSprogVent: ElementRef = this.appComponent.skiftSprogVent;
      if (skiftSprogVent) {
        return skiftSprogVent.nativeElement.innerText;
      }
    }
    return 'Changing language. Please wait';
  }

  hentSvarJa(): string {
    if (this.appComponent) {
      const svarJa: ElementRef = this.appComponent.svarJa;
      if (svarJa) {
        return svarJa.nativeElement.innerText;
      }
    }
    return 'Yes';
  }

  hentSvarNej(): string {
    if (this.appComponent) {
      const svarNej: ElementRef = this.appComponent.svarNej;
      if (svarNej) {
        return svarNej.nativeElement.innerText;
      }
    }
    return 'No';
  }

  hentSvarMangler(): string {
    if (this.appComponent) {
      const svarMangler: ElementRef = this.appComponent.svarMangler;
      if (svarMangler) {
        return svarMangler.nativeElement.innerText;
      }
    }
    return 'Response is missing';
  }

  hentUgyldigDato(): string {
    if (this.appComponent) {
      const ugyldigDato: ElementRef = this.appComponent.ugyldigDato;
      if (ugyldigDato) {
        return ugyldigDato.nativeElement.innerText;
      }
    }
    return 'Invalid date';
  }

  // event emittere for upload nyt arbejde
  danNytArbejdeKvittering() {
    this.dannerNytArbejdeKvittering$.emit(true);
  }

  nytArbejdeKvitteringErKlar() {
    this.dannerNytArbejdeKvittering$.emit(false);
    this.nytArbejdeKvitteringErKlar$.emit();
  }

  // event emittere for upload stop arbejde
  danStopArbejdeKvittering() {
    this.dannerStopArbejdeKvittering$.emit(true);
  }

  stopArbejdeKvitteringErKlar() {
    this.dannerStopArbejdeKvittering$.emit(false);
    this.stopArbejdeKvitteringErKlar$.emit();
  }

  // event emittere for upload afskedigelse

  danAfskedigelseKvittering() {
    this.dannerAfskedigelseKvittering$.emit(true);
  }

  afskedigelseKvitteringErKlar() {
    this.dannerAfskedigelseKvittering$.emit(false);
    this.afskedigelseKvitteringErKlar$.emit();
  }

  // event emittere for upload øvrige henvendelser

  danOevrigeHenvendelserKvittering() {
    this.dannerOevrigeHenvendelserKvittering$.emit(true);
  }

  oevrigeHenvendelserKvitteringErKlar() {
    this.dannerOevrigeHenvendelserKvittering$.emit(false);
    this.oevrigeHenvendelserKvitteringErKlar$.emit();
  }

  // event emittere for upload uarbejdsdygtig

  danUarbejdsdygtigKvittering() {
    this.dannerUarbejdsdygtigKvittering$.emit(true);
  }

  uarbejdsdygtigKvitteringErKlar() {
    this.dannerUarbejdsdygtigKvittering$.emit(false);
    this.uarbejdsdygtigKvitteringErKlar$.emit();
  }

  // event emittere for upload ferie dok.
  danFerieKvittering() {
    this.dannerFerieKvittering$.emit(true);
  }

  ferieKvitteringErKlar() {
    this.dannerFerieKvittering$.emit(false);
    this.ferieKvitteringErKlar$.emit();
  }
}
