import { Injectable } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators, ValidatorFn, UntypedFormArray } from '@angular/forms';

import {Ansoegningstype} from '../../../utils/ansoegning/ansoegningstype.enum';
import { SendAnsoegningService, ValiderMindstEnFil } from '../../../utils/service/send-ansoegning.service';

const ValiderAndet: ValidatorFn = (fg: UntypedFormGroup) => {
  if (!fg) {
    return null;
  }
  const filer: UntypedFormArray = fg.controls.filer as UntypedFormArray;
  // tslint:disable-next-line:ban-types
  const bemaerkninger: String = fg.controls.bemaerkninger.value;
  if (bemaerkninger) {
    return;
  }
  if (ValiderMindstEnFil(filer)) {
    return { filerEllerbemaerkninger: { required: true } };
  }
};

@Injectable({
  providedIn: 'root'
})
export class OevrigeHenvendelserService {

  constructor(private formBuilder: UntypedFormBuilder, private sendAnsoegningService: SendAnsoegningService) {}

  initialiserAnsoegningFormGroup(): UntypedFormGroup {
    return this.formBuilder.group({
      ansoegning: 'upload',
      ansoegningstype: Ansoegningstype.UploadOevrigeHenvendelser,
      oevrigeHenvendelser: this.formBuilder.group({
        filer: this.formBuilder.array([this.sendAnsoegningService.nyFil('oevrigehenvendelser')]),
        bemaerkninger: null,
        godkendt: [null, Validators.requiredTrue]
      }, { validators: [ValiderAndet] })
    });
  }
}
