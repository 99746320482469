import {Component, OnInit} from '@angular/core';
import {UploadService} from '../upload.service';
import {UploadProfil} from '../upload-profil';
import {DatePipe} from '@angular/common';
import {ApiResponse} from '../../utils/service/api-response';

@Component({
  selector: 'app-upload-kvittering-sidehoved',
  templateUrl: './upload-kvittering-sidehoved.component.html',
  styleUrls: ['./upload-kvittering-sidehoved.component.scss']
})
export class UploadKvitteringSidehovedComponent implements OnInit {

  uploadProfil: UploadProfil;
  apiResponse: ApiResponse;
  modtagetDato: string;
  today: Date;
  ufmLogo: string;

  constructor(private datePipe: DatePipe, private uploadService: UploadService) {
    this.ufmLogo = 'assets/img/Logo_UFS_UK_Sort_Web_RGB_72dpi.png';
  }

  ngOnInit() {
    this.today = new Date();
    this.uploadProfil = this.uploadService.hentUploadProfil;
    this.modtagetDato = this.datePipe.transform(this.today, 'HH:mm, dd-MM-yyyy');
    this.apiResponse = this.uploadService.hentApiResponse;
  }

}
