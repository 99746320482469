import { Component, EventEmitter, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';

import { fadeInOut } from './animationer';
import { UfmGoogleAnalyticsService } from './ufm-google-analytics.service';

@Component({
  selector: 'ufm-ustr-google-analytics',
  templateUrl: './ufm-google-analytics.component.html',
  styleUrls: ['./ufm-google-analytics.component.scss'],
  animations: [fadeInOut]
})
export class GoogleAnalyticsComponent implements OnInit {

  jaTaktilSporing$: EventEmitter<null>;

  constructor(private ufmGoogleAnalyticsService: UfmGoogleAnalyticsService) {
    this.jaTaktilSporing$ = ufmGoogleAnalyticsService.jaTaktilSporing$;
  }

  ngOnInit() {
    const s: Subscription = this.ufmGoogleAnalyticsService.jaTaktilSporing$.subscribe(() => {
      s.unsubscribe();
      this.jaTaktilSporing$.emit();
    });
  }

  visCookieMeddelelse(): boolean {
    return this.ufmGoogleAnalyticsService.visCookieMeddelelse();
  }

  jaTaktilSporing(): boolean {
    return this.ufmGoogleAnalyticsService.jaTaktilSporing();
  }

  nejTaktilSporing(): boolean {
    return this.ufmGoogleAnalyticsService.nejTaktilSporing();
  }

}

