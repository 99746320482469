import { Component, OnInit, Input, Output, EventEmitter, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { UntypedFormGroup, AbstractControl } from '@angular/forms';
import { Subscription, timer, interval } from 'rxjs';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Component({
  selector: 'app-ja-nej-radiogroup',
  templateUrl: './ja-nej-radiogroup.component.html',
  styleUrls: ['./ja-nej-radiogroup.component.scss']
})
export class JaNejRadiogroupComponent implements OnInit, OnDestroy {

  @Input() disabled: boolean;
  @Input() felt: string;
  @Input() form: UntypedFormGroup;
  @Input() required: boolean;
  @Input() spoergsmaal: string;
  @Input() svarMangler: string;
  @Input() ariaControlsJa: string;
  @Input() ariaControlsNej: string;
  @Output() svarMangler$: EventEmitter<string> = new EventEmitter();

  @ViewChild('error', { static: false }) private error: ElementRef;
  spoergsmaalSafe: SafeHtml;
  svarManglerSafe: SafeHtml;
  ariaCheckedJa: string;
  ariaCheckedNej: string;

  private subscriptions: Subscription[] = [];

  constructor(private sanitizer: DomSanitizer) { }

  ngOnInit() {
    if (!this.svarMangler) {
      this.svarMangler = 'Spørgsmål skal besvares';
    }
    if (!this.ariaControlsJa) {
      this.ariaControlsJa = Math.random().toString().substr(2, 10);
    }
    if (!this.ariaControlsNej) {
      this.ariaControlsNej = Math.random().toString().substr(2, 10);
    }
    this.spoergsmaalSafe = this.sanitizer.bypassSecurityTrustHtml(this.spoergsmaal);
    this.svarManglerSafe = this.sanitizer.bypassSecurityTrustHtml(this.svarMangler);

    const controller: AbstractControl = this.form.controls[this.felt];
    this.subscriptions.push(
      controller.valueChanges.subscribe((svar) => {
        if (svar === 'J') {
          this.ariaCheckedJa = 'true';
          this.ariaCheckedNej = null;
        }
        if (svar === 'N') {
          this.ariaCheckedJa = null;
          this.ariaCheckedNej = 'true';
        }
        this.emitSvarMangler();
      })
    );
    const s: Subscription = interval(50).subscribe(() => {
      if (controller.touched) {
        this.emitSvarMangler();
        s.unsubscribe();
      }
    });
    this.subscriptions.push(s);
  }

  ngOnDestroy() {
    let s: Subscription;
    while (this.subscriptions.length) {
      s = this.subscriptions.shift();
      if (!s.closed) {
        s.unsubscribe();
      }
    }
  }

  private emitSvarMangler(): void {
    timer(0).subscribe(() => {
      if (this.error) {
        this.svarMangler$.emit(this.error.nativeElement.innerText);
      } else {
        this.svarMangler$.emit();
      }
    });
  }

}
