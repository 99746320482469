import { Component, ElementRef, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { timer } from 'rxjs';

import { UfmSessionTimeoutService } from '../ufm-session-timeout/ufm-session-timeout.service';

@Component({
  templateUrl: './ufm-session-demo-auth.component.html',
  styleUrls: ['./ufm-session-demo-auth.component.scss']
})
export class UfmSessionDemoAuthComponent {

  actionUrl: string;
  cpr = '1234567890';
  timestamp = '1234';
  signature = 'xyz';
  meddelelse = 'Vent venligst. Login er i gang';

  @ViewChild('loginForm') loginForm: ElementRef;

  constructor(private route: ActivatedRoute, private ufmSessionTimeoutService: UfmSessionTimeoutService) {
    // hent action url
    this.route.queryParams
      .subscribe(params => {
        this.actionUrl = params.action_url;
        if (!this.actionUrl) {
          this.meddelelse = 'Login afbrudt. actionUrl mangler';
          return;
        }
        if (!ufmSessionTimeoutService.findesUs2000RequestId()) {
          this.meddelelse = 'Login afbrudt. us2000RequestId mangler';
          return;
        }
        // hent us2000/minsu post request fra proxy
        this.ufmSessionTimeoutService.getDemoPostRequest().subscribe(postRequestData => {
          this.cpr = postRequestData.cpr;
          this.timestamp = postRequestData.temp;
          this.signature = postRequestData.signature;
          if (!this.cpr || !this.timestamp || !this.signature) {
            this.meddelelse = 'Login afbrudt. post data request mangler';
            return;
          }
          timer(0).subscribe(() => this.loginForm.nativeElement.submit());
        });
      });
  }

}
