import { ModuleWithProviders, NgModule, Optional, SkipSelf } from '@angular/core';
import { CommonModule } from '@angular/common';

import { UfmBrowsercacheConfig, UFM_BROWSERCACHE_CONFIG } from './ufm-browsercache.config';
import { UfmTjekBrowsercacheComponent } from './ufm-tjek-browsercache.component';
import { UfmVisBrowsercacheComponent } from './ufm-vis-browsercache.component';

@NgModule({
  declarations: [
    UfmTjekBrowsercacheComponent,
    UfmVisBrowsercacheComponent
  ],
  imports: [CommonModule],
  exports: [
    UfmTjekBrowsercacheComponent,
    UfmVisBrowsercacheComponent
  ]
})
export class UfmBrowsercacheModule {
  constructor(@Optional() @SkipSelf() parentModule?: UfmBrowsercacheModule) {
    if (parentModule) {
      throw new Error(
        'UfmBrowsercacheModule is already loaded. Import it in the AppModule only');
    }
  }

  static forRoot(ufmBrowsercache?: UfmBrowsercacheConfig): ModuleWithProviders<UfmBrowsercacheModule> {
    return {
      ngModule: UfmBrowsercacheModule,
      providers: [
        { provide: UFM_BROWSERCACHE_CONFIG, useValue: ufmBrowsercache || {}}
      ]
    };
  }
}
