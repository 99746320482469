// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import { KeycloakConfig } from 'keycloak-js';

// Keycloak configuration
const keycloakConfig: KeycloakConfig = {
  url: '',
  realm: '',
  clientId: ''
};

export const environment = {
  production: false,
  demo: false,
  keycloak: keycloakConfig,
  fuldmagtsprivilege: 'urn:dk:umit:minsu:privilege_grants',
  upApi: '/api',
  userTracking: {
    brug: 'ga',
    ga:  {
      ejendom: 'UA-11016073-15'
    }
  },
  minsuUrl: 'https://susb-test.su.dk/team1/AjfController?ACTION=minSU',
  maxFilUploadBytes: 52428950,
  maxFilUploadTekst: '50Mb',
  test: true,
  upload: true,
  version: 'v1.2.2'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
