import { NgModule, Optional, SkipSelf, ModuleWithProviders } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';

import { UfmSessionLogafComponent } from './ufm-session-logaf/ufm-session-logaf.component';
import { UfmSessionTimeoutComponent } from './ufm-session-timeout/ufm-session-timeout.component';
import { UfmSessionDemoAuthComponent } from './ufm-session-demo-auth/ufm-session-demo-auth.component';

import { UFM_SESSION_CONFIG, UfmSessionConfig } from './ufm-session.config';

@NgModule({
  declarations: [UfmSessionLogafComponent, UfmSessionTimeoutComponent, UfmSessionDemoAuthComponent],
  imports: [HttpClientModule],
  exports: [UfmSessionLogafComponent, UfmSessionTimeoutComponent, UfmSessionDemoAuthComponent]
})
export class UfmSessionModule {
  constructor(@Optional() @SkipSelf() parentModule?: UfmSessionModule) {
    if (parentModule) {
      throw new Error(
        'UfmSessionModule is already loaded. Import it in the AppModule only');
    }
  }

  static forRoot(config?: UfmSessionConfig): ModuleWithProviders<UfmSessionModule> {
    return {
      ngModule: UfmSessionModule,
      providers: [
        { provide: UFM_SESSION_CONFIG, useValue: config || {}}
      ]
    };
  }
}
