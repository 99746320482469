import {Component, ElementRef, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {UntypedFormGroup} from '@angular/forms';
import {Subscription} from 'rxjs';
import {foldudAmination} from '../../../../../shared/animationer/animationer';

@Component({
  selector: 'app-loenoplysninger',
  templateUrl: './loenoplysninger.component.html',
  styleUrls: ['./loenoplysninger.component.scss'],
  animations: [foldudAmination]
})
export class LoenoplysningerComponent implements OnInit, OnDestroy {

  @Input() form: UntypedFormGroup;
  @Input() hours: string;
  @Input() wage: string;
  @Input() currentJob: boolean;
  @Input() antalTimerPrUge: string;
  @Input() timeEllerMaanedsLoen1: string;
  @Input() timeEllerMaanedsLoen2: string;
  @Input() timeEllerMaanedsLoen3: string;
  @Input() loenKr: string;
  loenKrPlaceholder: string;
  loenKrJaVis = false;
  private subscriptions: Subscription[] = [];
  @ViewChild('timeloenKrPlaceholderText', {static: false}) private timeloenKrPlaceholderText: ElementRef;
  @ViewChild('maanedsloenKrPlaceholderText', {static: false}) private maanedsloenKrPlaceholderText: ElementRef;

  constructor() {}

  ngOnInit() {
    this.subscriptions.push(
      this.form.controls.timeEllerMaanedsLoen.valueChanges.subscribe((value) => {
        if (value === 't') {
          this.loenKrPlaceholder = this.timeloenKrPlaceholderText.nativeElement.innerText;
          this.loenKrJaVis = true;
        }
        if (value === 'm') {
          this.loenKrPlaceholder = this.maanedsloenKrPlaceholderText.nativeElement.innerText;
          this.loenKrJaVis = true;
        }
        if (value === 'v') {
          this.loenKrJaVis = false;
          this.form.controls.loenKr.reset();
        }
        this.form.controls.loenKr.updateValueAndValidity();
      })
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach((item) => {
      item.unsubscribe();
    });
  }
}
