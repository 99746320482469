import { Inject, Injectable, Injector } from '@angular/core';
import { Observable, Subscription, timer, of } from 'rxjs';
import { sampleTime, tap, catchError, switchMap, map } from 'rxjs/operators';
import { ToastrService, ActiveToast } from 'ngx-toastr';
import { Router } from '@angular/router';
import * as moment from 'moment/moment';
import { KeycloakService } from 'keycloak-angular';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

import { DemoPostRequest } from './demo-post-request';
import { JwtService } from '../jwt.service';
import { UfmSessionAktivBrugerService } from '../ufm-session-aktiv-bruger.service';
import { UfmSessionLoginService } from '../ufm-session-login.service';
import { UfmSessionInfo } from '../ufm-session-info';
import { UfmSessionConfigService } from '../ufm-session-config.service';
import { UfmSessionLogPaaStatus } from '../ufm-session-log-paa-status.enum';

const apiSessionInfokaldInterval = 60000;

@Injectable({
  providedIn: 'root'
})
export class UfmSessionTimeoutService {

  private apiSessionMaxGyldigMillisekunder: number;
  private refreshExpiresIn: number;
  private apiSessionAdvarselBroek: number = 5 / 6;
  private sessionErUdloebetSubscription: Subscription;
  private sessionUdloeberSnartSubscription: Subscription;
  private aktivSessionGetSessionInfoSubscription: Subscription;
  private sidsteAdvarsel: ActiveToast<any>;
  private ufmSessionInfo: UfmSessionInfo;
  private detectedSingleSignout = false;

  constructor(
    private brugerAktivService: UfmSessionAktivBrugerService,
    private http: HttpClient,
    @Inject(Injector) private injector: Injector,
    private jwtService: JwtService,
    private keycloakService: KeycloakService,
    private router: Router,
    private ufmSessionConfigService: UfmSessionConfigService,
    private ufmSessionLoginService: UfmSessionLoginService) {
  }

    // Need to get ToastrService from injector rather than constructor injection to avoid cyclic dependency error
    private get toastrService(): ToastrService {
      return this.injector.get(ToastrService);
    }
  
  // til brug i LoggetPaaGuard i projekterne.
  // 1) tjekker login og returnerer true/false
  // 2) efter login startes en timer der tjekker JWT token en gang hver minut så længe at bruger er aktiv (mus eller keyboard)
  // 3) der gives advarsel når session er ved at udløbe (5/6 af 30 minutter), dvs lig med 25 minutters inaktivet
  // 4) der redirectes til session timeout siden efter 30 minutters inaktivtet
  public erLoggetPaaObservableTilBrugIGuards(): Observable<boolean> {
    let ufmSessionInfoSubscription: Subscription;
    return this.ufmSessionLoginService.erLoggetPaa().pipe(
      tap(loggetPaa => {
        if (loggetPaa && !this.refreshExpiresIn) {
          // vi er logget på, men JWT og keycloakInstance er endnu ikke blevet tjekket for session information
          ufmSessionInfoSubscription = this.getSessionInfoFraKeycloak().subscribe(ufmSessionInfo => {
            if (!ufmSessionInfo) {
              console.error('UfmSessionTimeoutService.erLoggetPaaObservableTilBrugIGuards: getSessionInfoFraKeycloak kald fejlede. Ingen JWT modtaget');
              return;
            }
            this.refreshExpiresIn = ufmSessionInfo.refreshExpiresIn;
            ufmSessionInfoSubscription.unsubscribe();
          });
        } else if (!loggetPaa && ufmSessionInfoSubscription) {
          // todo: dette ser mærkeligt ud. Der sker jo unsubscribe ovenfor efter login
          ufmSessionInfoSubscription.unsubscribe();
          ufmSessionInfoSubscription = null;
        }
      })
    );
  }

  // cache seneste ufmSessionInfo objektet er tilgængelig for beregning af advarsel om session udløb og session ophør
  private cacheUfmSessionInfo(ufmSessionInfo: UfmSessionInfo): void {
    this.ufmSessionInfo = ufmSessionInfo;
  }

  private getSessionInfoFraKeycloak(): Observable<UfmSessionInfo> {
    // inject nødvendige services ind i hentJsonWebToken så services og logning fungerer der
    const keycloakService2 = this.keycloakService;
    const jwtService2 = this.jwtService;
    const ufmSessionLoginService2 = this.ufmSessionLoginService;
    const this2 = this;
    const console2 = console;
    const hentJsonWebToken = (obs) => {
      keycloakService2.getToken().then(encryptedJsonWebToken => {
        if (!encryptedJsonWebToken) {
          console2.error('UfmSessionTimeoutService.getSessionInfoFraKeycloak token er tom');
          return obs.next(null);
        }
        let jsonWebToken: any;
        try {
          jsonWebToken = jwtService2.decodeToken(encryptedJsonWebToken);
        }
        catch (e) {
          console2.error('UfmSessionTimeoutService.getSessionInfoFraKeycloak: encryptedJwt er invalid: ', e);
          return obs.next(null);
        }
        const keycloakInstance: Keycloak.KeycloakInstance = keycloakService2.getKeycloakInstance();
        let ufmSessionInfo: UfmSessionInfo;
        try {
          ufmSessionInfo = new UfmSessionInfo(encryptedJsonWebToken, jsonWebToken, keycloakInstance);
          this2.cacheUfmSessionInfo(ufmSessionInfo);
        } catch (e) {
          console2.error('UfmSessionTimeoutService.getSessionInfoFraKeycloak: kunne ikke danne UfmSessionInfo objekt', e);
          console2.debug('jsonWebToken=', jsonWebToken);
          console2.debug('keycloakInstance=', keycloakInstance);
        }
        if (ufmSessionInfo && ufmSessionInfo.erGyldig) {
          ufmSessionLoginService2.logPaaSignal();
          this2.opsaetSessionUdloebAdvarsel(ufmSessionInfo); // 1800000 = 30 minutter
        } else {
          ufmSessionLoginService2.logAfSignal();
        }
        obs.next(ufmSessionInfo);
      }).catch(error => {
        console2.error('UfmSessionTimeoutService.getSessionInfoFraKeycloak: keycloakService.getToken fejlede med', error);
        obs.next(false);
      });
    };
    const observable: Observable<UfmSessionInfo> = new Observable(hentJsonWebToken);
    return observable;
  }

  public lukSessionTimers() {
    if (this.aktivSessionGetSessionInfoSubscription) {
      this.aktivSessionGetSessionInfoSubscription.unsubscribe();
      this.aktivSessionGetSessionInfoSubscription = null;
    }
    if (this.sessionErUdloebetSubscription) {
      this.sessionErUdloebetSubscription.unsubscribe();
      this.sessionErUdloebetSubscription = null;
    }
    if (this.sessionUdloeberSnartSubscription) {
      this.sessionUdloeberSnartSubscription.unsubscribe();
      this.sessionUdloeberSnartSubscription = null;
    }
  }

  private checkSingleSignOut(): void {
    if (this.detectedSingleSignout) {
      return;
    }
    this.keycloakService.isLoggedIn().then((authenticated) => {
      if (!authenticated) {
        if (this.ufmSessionLoginService.loggetPaa$.value === UfmSessionLogPaaStatus.LoggetPaa) {
          this.detectedSingleSignout = true;
          if (this.ufmSessionInfo && this.ufmSessionInfo.encryptedJsonWebToken) {
            // vi "snyder", og tilføjer seneste kendte JTW til /api/logout request.
            this.ufmSessionLoginService.apiLogout(this.ufmSessionInfo.encryptedJsonWebToken).subscribe(
              () => this.sendLogAfSignalOgRedirectTilLogAfSide()
            );
          } else {
            // vi kan ikke kalde /api/logout uden en gyldig JWT. sender logaf signal til FE componenter');
            this.sendLogAfSignalOgRedirectTilLogAfSide();
          }
        }
      }
    });
  }

  private sendLogAfSignalOgRedirectTilLogAfSide(): void {
    // sender logaf signal til FE componenter');
    this.ufmSessionLoginService.logAfSignal();
    // navigation til logaf siden
    const logAfUrl: string = this.ufmSessionConfigService.getLogAfUrl();
    if (logAfUrl.substr(0, 4) === 'http') {
      window.location.href = logAfUrl;
    } else {
      this.lukSessionTimers();
      this.router.navigate([logAfUrl]);
    }
  }

  // gem refresh_expires_in fra keycloak session og opsæt timer for log af advarsel
  private opsaetSessionUdloebAdvarsel(ufmSessionInfo: UfmSessionInfo): void {
    const maxInactivityTime: number = ufmSessionInfo.refreshExpiresIn * 1000;
    if (this.apiSessionMaxGyldigMillisekunder) {
      return;
    }
    this.apiSessionMaxGyldigMillisekunder = maxInactivityTime;
    this.kaldKeycloakHvisBrugerErAktiv();
    if (!this.sessionErUdloebetSubscription) {
      this.opsaetTimerForAdvarsel();
    }
  }

  private lukAdvarsel(): void {
    if (!this.sidsteAdvarsel) {
      return;
    }
    this.sidsteAdvarsel.toastRef.close();
    this.sidsteAdvarsel = null;
    if (this.logAfOmMillisekunder() <= apiSessionInfokaldInterval) {
      this.getSessionInfoFraKeycloak().subscribe();
    }
  }

  private kaldKeycloakHvisBrugerErAktiv(): void {
    if (this.aktivSessionGetSessionInfoSubscription) {
      return;
    }
    this.aktivSessionGetSessionInfoSubscription = this.brugerAktivService.sidstAktivTidspunkt()
      .pipe(
        tap(() => this.lukAdvarsel()),
        tap(() => this.checkSingleSignOut()),
        sampleTime(apiSessionInfokaldInterval),
        switchMap(() => {
          const disposableStream$ = this.getSessionInfoFraKeycloak();
          return disposableStream$.pipe(
            catchError(error => {
              console.error(error);
              return new Observable(observer => {
                observer.next(error);
              });
            }));
        })
      )
      .subscribe();
    this.ufmSessionLoginService.loggetPaa$.subscribe(loggetPaa => {
      if ((loggetPaa === UfmSessionLogPaaStatus.IkkeLoggetPaa) && this.aktivSessionGetSessionInfoSubscription) {
        this.aktivSessionGetSessionInfoSubscription.unsubscribe();
        this.aktivSessionGetSessionInfoSubscription = null;
      }
    });
  }

  private advarselOmMillisekunder(): number {
    const ufmSessionInfo: UfmSessionInfo = this.ufmSessionInfo;
    const iat: number = ufmSessionInfo.iat;
    const expiresIn: number = ufmSessionInfo.refreshExpiresIn;
    const expiresAt: number = iat + expiresIn;
    const warningIn: number = expiresIn * this.apiSessionAdvarselBroek;
    const warningAt: number = expiresAt - (expiresIn - warningIn);
    const nu: number = Math.floor((new Date()).getTime() / 1000);
    const advarselOmSekunder: number = warningAt - nu;
    const advarselOmMillisekunder: number = advarselOmSekunder > 0 ? advarselOmSekunder * 1000 : 0;
    return advarselOmMillisekunder;
  }

  private opsaetTimerForAdvarsel(): void {
    if (this.sessionUdloeberSnartSubscription) {
      this.sessionUdloeberSnartSubscription.unsubscribe();
      this.sessionUdloeberSnartSubscription = null;
    }
    const advarselOmMillisekunder = this.advarselOmMillisekunder();
    if (advarselOmMillisekunder > 0) {
      this.sessionUdloeberSnartSubscription = timer(advarselOmMillisekunder).subscribe(() => {
        if (this.advarselOmMillisekunder() > 0) {
          this.opsaetTimerForAdvarsel();
        } else {
          this.opsaetTimerForLogAfUdloeb();
        }
      });
    } else {
      this.opsaetTimerForLogAfUdloeb();
    }
  }

  private logAfOmMillisekunder(): number {
    const ufmSessionInfo: UfmSessionInfo = this.ufmSessionInfo;
    const iat: number = ufmSessionInfo.iat;
    const expiresIn: number = ufmSessionInfo.refreshExpiresIn;
    const expiresAt: number = iat + expiresIn;
    const nu: number = Math.floor((new Date()).getTime() / 1000);
    const logAfOmSekunder = expiresAt - nu;
    const logAfOmMillisekunder = logAfOmSekunder * 1000;
    return logAfOmMillisekunder;
  }

  private opsaetTimerForLogAfUdloeb(): void {
    const tidspunkt: string = moment().add(this.millisekunderEfterAdvarsel()).format('HH:mm:ss');
    const sessionUdloeberSnart: string = this.ufmSessionConfigService.getPopupSessionUdloeberSnartTekst() + ' ' + tidspunkt;
    const toastOverskrift: string = this.ufmSessionConfigService.getPopupAdvarselTekst();
    this.sidsteAdvarsel = this.toastrService.warning(sessionUdloeberSnart, toastOverskrift, { timeOut: 0 });
    if (this.sessionErUdloebetSubscription) {
      this.sessionErUdloebetSubscription.unsubscribe();
    }
    const logAfOmMillisekunder = this.logAfOmMillisekunder();
    if (logAfOmMillisekunder > 0) {
      this.sessionErUdloebetSubscription = timer(logAfOmMillisekunder).subscribe(() => {
        const logAfOmMillisekunderNy: number = this.logAfOmMillisekunder();
        if (logAfOmMillisekunderNy > 0) {
          this.opsaetTimerForAdvarsel();
        } else {
          // tslint:disable-next-line: no-shadowed-variable
          const toastOverskrift: string = this.ufmSessionConfigService.getPopupTimeoutTekst();
          const sessionErUdloebet: string = this.ufmSessionConfigService.getPopupSessionErUdloebetTekst();
          this.toastrService.error(sessionErUdloebet, toastOverskrift, { timeOut: 0 });
          this.ufmSessionLoginService.logAfSignal();
          this.router.navigate(['/timeout']);
        }
      });
    }
  }

  private millisekunderFoerAdvarsel(): number {
    return this.apiSessionMaxGyldigMillisekunder * this.apiSessionAdvarselBroek;
  }

  private millisekunderEfterAdvarsel(): number {
    return this.apiSessionMaxGyldigMillisekunder - this.millisekunderFoerAdvarsel();
  }

  public findesUs2000RequestId(): boolean {
    return this.ufmSessionLoginService.findesUs2000RequestId();
  }

  public getDemoPostRequest(): Observable<DemoPostRequest> {
    const us2000RequestId: string = this.ufmSessionLoginService.getUs2000RequestId();
    if (!us2000RequestId) {
      return of(new DemoPostRequest());
    }
    const headers: { [key: string]: string } = { NoInterceptorAgerendeCpr: 'x' };
    return this.http.get<DemoPostRequest>('/demo/login?requestId=' + us2000RequestId, { headers });
  }

  private sendUS2000UseridentTilAPI(userident: string) {
    const body: HttpParams = new HttpParams().set('fejlbesked', 'USERIDENT: ' + userident);
    this.http.post(
      this.ufmSessionConfigService.getFrontendClientLogRequestUrl(),
      body.toString(),
      { headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded') }
    ).subscribe();
  }

  public erLoginOk(jwtCpr: string): Observable<boolean> {
    const demo: boolean = this.ufmSessionConfigService.getDemo();
    if (!demo) {
      return of(true);
    } else if (!jwtCpr) {
      return of(false);
    } else {
      return this.getDemoPostRequest()
        .pipe(
          tap(demoPostRequest => {
            if (demoPostRequest => demoPostRequest && demoPostRequest.cpr && (demoPostRequest.cpr === jwtCpr)) {
              // US2000 demo ok. Send US2000 USERIDENT til API så vi kan se hvad US2000 sagsbehandler laver i demo.
              this.sendUS2000UseridentTilAPI(demoPostRequest.userident);
            }
          }),
          map(demoPostRequest => demoPostRequest && demoPostRequest.cpr && (demoPostRequest.cpr === jwtCpr))
        );
    }
  }

  public setErVirksomhed (erVirksomhed: boolean): void {
    this.ufmSessionLoginService.setErVirksomhed(erVirksomhed);
  }

  public setAgerendeCpr (agerendeCpr: string): void {
    this.ufmSessionLoginService.setAgerendeCpr(agerendeCpr);
  }

}
