<div *ngIf="ok" class="float-end" [ngClass]="ngClass">
    <span class="pe-2" i18n="@@utils accordion status ok">OK</span>
    <fa-icon [icon]="faCheckCircle"></fa-icon>
</div>
<div *ngIf="skalGodkendes" class="float-end" [ngClass]="ngClass">
    <span class="pe-2" i18n="@@utils accordion status godkend">OK?</span>
    <fa-icon [icon]="faQuestionCircle"></fa-icon>
</div>
<div *ngIf="skalUdfyldes" class="float-end" [ngClass]="ngClass">
    <span class="pe-2" i18n="@@utils accordion status skal udfyldes">Skal udfyldes</span>
    <fa-icon [icon]="faExclamation"></fa-icon>
</div>
<div *ngIf="fejl" class="float-end" [ngClass]="ngClass">
    <span class="pe-2" i18n="@@utils accordion status fejl">Fejl</span>
    <fa-icon [icon]="faTimesCircle"></fa-icon>
</div>
<div *ngIf="skalIkkeUdfyldes" class="float-end" [ngClass]="ngClass">
    <span class="pe-2" i18n="@@utils accordion status skal ikke udfyldes">Skal ikke udfyldes</span>
    <fa-icon [icon]="faCheckCircle"></fa-icon>
</div>