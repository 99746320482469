import { InjectionToken } from '@angular/core';

export const UFM_SESSION_CONFIG = new InjectionToken<UfmSessionConfig>('UFM_SESSION_CONFIG');

// default tekster findes i html templates. Alternative tekster kan injectes på 3 måder:
// 1) statisk/byg: brug i18n tags. Bruges i ligestilling. Se messages.en.xlf. Denne metode kan ikke anvendes for UfmSessionConfigPopupTekster hvor der ikke findes html templates
// 2) statisk/byg: UfmSessionModule.forRoot(ufmSessionConfig) fra app.module.ts
// 3) dynamisk: opdater ufmSessionConfig dynamisk efter opstart og kald ufmSessionConfigService.genindlaesOpdateretKonfiguration når tekster er blevet opdateret
export interface UfmSessionConfigPopupTekster {
    popupAdvarsel?: string; // Advarsel
    popupTimeout?: string; // Timeout
    popupSessionUdloeberSnart?: string; // Din session udløber kl.
    popupSessionErUdloebet?: string; // Din session er udløbet
}
export interface UfmSessionConfigTimeoutTekster {
    tekst1?: string; // i18n=@@ufmit session timeout tekst1 = Du har været inaktiv i noget tid, og du skal derfor logge på igen.
    tekst2?: string; // i18n=@@ufmit session timeout tekst2 = Har du også været logget på andre offentlige løsninger (som boger.dk, sundhed.dk eller lignende), kan det være, du fortsat er logget på der.
    tekst3?: string; // i18n=@@ufmit session timeout tekst3 = Husk altid, at hvis du har været logget på fra en computer, som andre har adgang til, skal du lukke browseren, inden du forlader den.
    tekst4?: string; // i18n=@@ufmit session timeout tekst4 = Først når du lukker browser-vinduet, kan du være sikker på, at ingen får adgang til dine personlige oplysninger.
}
export interface UfmSessionConfigLogafTekster {
    overskrift?: string; // i18n=@@ufmit session logaf side overskrift = Husk at lukke din browser
    tekst1?: string; // i18n=@@ufmit session logaf tekst1 = Du er nu lukket af selvbetjeningen.
    tekst2?: string; // i18n=@@ufmit session logaf tekst2 = Har du været logget på fra en computer, som andre har adgang til, skal du lukke browseren, inden du forlader den.
    tekst3?: string; // i18n=@@ufmit session logaf tekst3 = Først når du lukker browser-vinduet, kan du være sikker på, at ingen får adgang til dine personlige oplysninger.
}
export interface UfmSessionConfigTekster {
    popup?: UfmSessionConfigPopupTekster;
    timeout?: UfmSessionConfigTimeoutTekster;
    logaf?: UfmSessionConfigLogafTekster;
    sideLaesFlere?: string; // i18n=@@ufmit session side laes flere = Læs mere
    sideGodeRaad?: string; // i18n=@@ufmit session side gode raad = om IT-sikkerhed og NemID/MitID her.
    sideGodeRaadLink?: string; // i18n=@@ufmit session side gode raad link = https://www.nemid.nu/dk-da/pas_paa_dit_nemid/gode_raad/
}
export interface UfmSessionConfig {
    logAfUrl?: string; // brug / for retur til forside  after log af(default), /logaf eller https://su.dk)
    tekster?: UfmSessionConfigTekster;
    environment?: {
        apiPropertyNavn?: string; // api = environment.api. Dvs. navn på api property i environment.json filen. Bruges i /api/logout kald
    };
    apiLoginEndpoint?: 'login' | 'loginsb'; // sættes til null, login eller sblogin 
}
