<div class="row">
    <div class="col-12">
        <h3 #htmlOverskrift i18n="@@ufmit browsercache vis overskrift"></h3>
    </div>
    <div class="col-12" #htmlForklaring i18n="@@ufmit browsercache vis forklaring"></div>
    <div class="col-12 pt-3">
        <span #htmlDinVersion i18n="@@ufmit browsercache vis din version">Din version:</span> 
        {{ version }} 
    </div>
    <div class="col-12" >
        <span #htmlKraevetVersion i18n="@@ufmit browsercache vis kraevet version">Krævet version:</span> 
        {{ feVersions }} 
    </div>
    <div class="col-12 pt-3">
        <a href="https://www.su.dk/kontakt/tekniske-problemer/" target="_blank" #htmlLinkTekst i18n="@@ufmit browsercache vis link tekst">
            Læs mere om tekniske problemer på su.dk her.
        </a>
    </div>
</div>