<div class="row"  id="kvittering">
  <app-upload-kvittering-sidehoved class="col-12"></app-upload-kvittering-sidehoved>

  <div class="col-12 pt-4">
    <h4>You get a new job</h4>
  </div>

  <div class="col-12 pt-3">
    <h6 role="heading" aria-level="5">Information about your new work:</h6>
  </div>
  <app-loenoplysnings-kvittering class="col-12"
                                 [loenoplysninger]="kvittering.loenoplysninger"
                                 [hours]="'How many hours a week will you be working in your new job? '"
                                 [wage]="'Are you paid by the hour or by the month? '"
                                 [variable]="'Wage is not fixed'">
  </app-loenoplysnings-kvittering>

  <div class="col-12" *ngIf="kvittering.ansaettelseTidsbegraenset">
    Your new job is a fixed-term employment.
  </div>

  <div class="col-12">
    Name of your new employer:
    <strong class="ufm-black">{{ kvittering.arbejdsgiver }}</strong>
  </div>

  <div class="col-12" *ngIf="kvittering.cvrnummer !== null">
    CVR number:
    <strong class="ufm-black">{{ kvittering.cvrnummer }}</strong>
  </div>

  <div class="col-12">
    Start date:
    <strong class="ufm-black">{{ kvittering.startdato | langdato }}</strong>
  </div>

  <div class="col-12 pt-4">
    Upload file with your new employment contract:
  </div>

  <app-kvittering-filer class="col-12" [filer]="filer" [parameternavn]="'ansaettelseskontrakt'">
  </app-kvittering-filer>

  <div class="col-12 pt-2" *ngIf="kvittering.harStadigvaekTidligereArbejde">
    <h5>Information about your current work:</h5>
  </div>
  <app-loenoplysnings-kvittering class="col-12" 
                                 *ngIf="kvittering.harStadigvaekTidligereArbejde"
                                 [loenoplysninger]="kvittering.loenoplysningerTidligereArbejde"
                                 [hours]="'How many hours a week will you be working in your current job?: '"
                                 [wage]="'Are you paid by the hour or by the month?: '"
                                 [variable]="'Wage is not fixed'">
  </app-loenoplysnings-kvittering>

  <div class="col-12 pt-3" *ngIf="kvittering.bemaerkninger !== null">
    You can provide supplemental information here. <br />
    <strong class="ufm-black">{{ kvittering.bemaerkninger }}</strong>
  </div>

  <app-upload-kvittering-gdpr class="col-12 pt-3"></app-upload-kvittering-gdpr>
</div>
