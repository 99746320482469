import { NgModule, Optional, SkipSelf, ModuleWithProviders, ErrorHandler } from '@angular/core';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { CommonModule } from '@angular/common';

import { UFM_FEJLHAANDTERING_CONFIG, UfmFejlhaandteringConfig } from './ufm-fejlhaandtering.config';
import { UfmErrorHandlerMedLogning } from './ufm-error-handler-med-logning';
import { UfmInterceptorTimeout } from './ufm-interceptor-timeout';
import { UfmInterceptorError } from './ufm-interceptor-error';

@NgModule({
  imports: [CommonModule],
  providers: [
    { provide: ErrorHandler, useClass: UfmErrorHandlerMedLogning },
    { provide: HTTP_INTERCEPTORS, useClass: UfmInterceptorTimeout, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: UfmInterceptorError, multi: true }
  ]
})
export class UfmFejlhaandteringModule {
  constructor(@Optional() @SkipSelf() parentModule?: UfmFejlhaandteringModule) {
    if (parentModule) {
      throw new Error(
        'UfmFejlhaandteringModule is already loaded. Import it in the AppModule only');
    }
  }

  static forRoot(config?: UfmFejlhaandteringConfig): ModuleWithProviders<UfmFejlhaandteringModule> {
    return {
      ngModule: UfmFejlhaandteringModule,
      providers: [
        { provide: UFM_FEJLHAANDTERING_CONFIG, useValue: config || {}}
      ]
    };
  }
}
