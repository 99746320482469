import { Injectable } from '@angular/core';
import { Observable, merge, fromEvent } from 'rxjs';
import { map, sampleTime } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class UfmSessionAktivBrugerService {

  constructor() { }

  public sidstAktivTidspunkt(): Observable<Date> {
    return merge(fromEvent(document, 'mousemove'), fromEvent(document, 'keyup'))
      .pipe(
        map(() => new Date()),
        sampleTime(1000));
  }
}
