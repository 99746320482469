import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { tap, map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { ApiResponse } from '../utils/service/api-response';
import { environment } from '../../../environments/environment';
import { UfmFuldmagtService } from '@ufmit/ufm-fuldmagt';
import { UploadProfil } from './upload-profil';

@Injectable({
  providedIn: 'root'
})
export class UploadService {

  private uploadProfil: UploadProfil;
  private apiResponse: ApiResponse;

  constructor(
    private http: HttpClient,
    private router: Router,
    private ufmFuldmagtService: UfmFuldmagtService
  ) {
    this.ufmFuldmagtService.skiftetFuldmagtsBruger$.subscribe((fuldmagtSkiftet) => {
      if (!fuldmagtSkiftet) {
        return;
      }
        // reset profil cache og retur til upload forside
        this.uploadProfil = null;
        this.router.navigate(['/upload']);
    });
  }

  hentProfil(): Observable<UploadProfil> {
    if (this.uploadProfil == null) {
      return this.http
        .get<UploadProfil>(environment.upApi + '/upload?feVersion=' + environment.version)
        .pipe(tap((uploadProfil) => (this.uploadProfil = uploadProfil)));
    } else {
      return of(this.hentUploadProfil);
    }
  }

  get hentUploadProfil(): UploadProfil {
    return this.uploadProfil;
  }

  get hentApiResponse(): ApiResponse {
    return this.apiResponse;
  }
}
