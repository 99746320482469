import { Component, Input } from '@angular/core';

import { Periode } from '../../../utils/perioder/periode/periode';

@Component({
  selector: 'app-kvittering-perioder',
  templateUrl: './kvittering-perioder.component.html',
  styleUrls: ['./kvittering-perioder.component.scss']
})
export class KvitteringPerioderComponent {

  @Input() perioder: Periode[];

  constructor() { }
}
