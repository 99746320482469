import { InjectionToken } from '@angular/core';

export const UFM_BACKENDTID1_CONFIG = new InjectionToken<UfmBackendtid1Config>('UFM_BACKENDTID1_CONFIG');

// default tekster findes i html templates. Alternative tekster kan injectes på 3 måder:
// 1) statisk/byg: brug i18n tags. Bruges i ligestilling. Se messages.en.xlf.
// 2) statisk/byg: UfmBackendtid1Module.forRoot(ufmBackendtid1Config) fra app.module.ts
// 3) dynamisk: opdater ufmBackendtid1Config dynamisk efter opstart og kald ufmBackendtid1ConfigService.genindlaesOpdateretKonfiguration når tekster er blevet opdateret
export interface UfmBackendtid1ConfigTekster {
    hentet?: string; // Hentet: i18n=@@ufmit backendtid1 hentet
}
export interface UfmBackendtid1Config {
    environment?: {
        apiPropertyNavn?: string; // api = environment.api. Dvs. navn på api property i environment.json filen
        endpoint?: string; // profil. <api><endpoint> giver det API endpoint hvorfra backendtid hentes, fx /api/profil
    };
    tekster?: UfmBackendtid1ConfigTekster;
}
