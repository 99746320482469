<div class="row">
    <div *ngFor="let fil of filnavne" class="pt-1 col-12 ufm-black">
        {{ fil.filnavn }}
    </div>
    <div *ngIf="filerEftersendes" class="col-12 ufm-black">
        <span i18n="@@filer eftersendes">
            Jeg har på nuværende tidspunktet ikke al den nødvendige dokumentation. Du skal sende den manglende
            dokumentation inden for 7 dage.
        </span>
        <span i18n="@@filer eftersendes 7 dages frist">
            Hvis vi ikke har modtaget dokumentationen inden 7 dage, vil vi behandle din ansøgning på det nuværende grundlag. Du skal sende dokumentationen via Digital Post.
        </span>
        <a href="https://su.dk/11408" i18n-href="@@filer eftersendes link" target="_blank" i18n="@@filer eftersendes link tekst">
            Du kan læse mere om Digital Post på su.dk
        </a>
    </div>
</div>
