import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';

import { UfmFuldmagtBruger } from './ufm-fuldmagt-bruger';
import { UfmFuldmagtService } from './ufm-fuldmagt.service';

@Injectable()
export class InterceptorAgerendeCpr implements HttpInterceptor {

  constructor(private ufmFuldmagtService: UfmFuldmagtService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (request.headers.get('NoInterceptorAgerendeCpr')) {
      return next.handle(request);
    }
    const ufmFuldmagtBruger: UfmFuldmagtBruger = this.ufmFuldmagtService.fuldmagtsBruger$.value;
    const agerendeCpr: string | null = ufmFuldmagtBruger ? ufmFuldmagtBruger.agerendeCpr : null;
    if (!agerendeCpr) {
      return next.handle(request);
    }
    return next.handle(request.clone({
      headers: request.headers.set('AgerendeCpr', agerendeCpr)
    }));
  }
}
