import {Component, Input} from '@angular/core';

@Component({
  selector: 'app-loenoplysnings-kvittering',
  templateUrl: './loenoplysnings-kvittering.component.html',
  styleUrls: ['./loenoplysnings-kvittering.component.scss']
})
export class LoenoplysningsKvitteringComponent {

  @Input() hours: string;
  @Input() wage: string;
  @Input() variable: string;
  @Input() loenoplysninger: any;

  constructor() { }

}
