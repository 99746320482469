<div class="row">

    <div class="col-12" #htmlOverskrift i18n="@@ufmit session logaf side overskrift">
        <h3>Husk at lukke din browser</h3>
    </div>
    <div class="col-12" #htmlTekst1 i18n="@@ufmit session logaf tekst1">
        Du er nu logget ud af selvbetjeningsløsning.
    </div>
    <div class="col-12" #htmlTekst2 i18n="@@ufmit session logaf tekst2">
        Har du været logget på fra en computer, som andre har adgang til, skal du lukke browseren, inden du forlader den.
    </div>
    <div class="col-12" #htmlTekst3 i18n="@@ufmit session logaf tekst3">
        Først når du lukker browser-vinduet, kan du være sikker på, at ingen får adgang til dine personlige oplysninger.
    </div>
    <div class="col-12 pt-3">
        <p>
            <span #htmlLaesFlere i18n="@@ufmit session side laes flere">Læs mere</span>
            <a href="https://www.nemid.nu/dk-da/pas_paa_dit_nemid/gode_raad/" #htmlGodeRaad i18n="@@ufmit session side gode raad" i18n-href="@@ufmit session side gode raad link">
                om IT-sikkerhed og NemID/MitID her.
            </a>
        </p>
    </div>

</div>