<div [@fadeInOut] *ngIf="visCookieMeddelelse()"
  class="fixed-bottom text-white text-center fw-light p-3 bg-dark">
  <span i18n="@@cookies overskrift">
    Vi samler statistik ved hjælp af cookies. Vi bruger cookies til at forbedre hjemmesiden til
    glæde for vores brugere. Du kan altid slette cookies fra os igen.
  </span>
  <p>
    <button type="button" class="btn btn-sm btn-secondary m-1 shadow" (click)="jaTaktilSporing()"
      i18n="@@cookies ja tak">Accepter cookies</button>
    <button type="button" class="btn btn-sm btn-danger m-1 shadow" aria-label="Nej tak til cookies"
      i18n-aria-label="@@cookies hvad er cookies link text" (click)=nejTaktilSporing() i18n="@@cookies nej tak">Nej
      tak</button>
  </p>
  <p>
    <a class="text-white text-underline" href="http://www.ungdomskort.dk/cookies"
      title="Hvad er cookies? (eksternt link - side åbnes i nyt faneblad)"
      i18n-title="@@cookies hvad er cookies link title" target="_blank" i18n="@@cookies hvad er cookies link tekst">Hvad
      er cookies?</a>
  </p>
</div>
