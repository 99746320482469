<form [formGroup]="form">
  <div class="row">
    <div class="col-12 h3" role="heading" aria-level="2">
      You become involuntarily unemployed
    </div>

    <div class="col-12 pt-3" role="heading" aria-level="3">
      <div class="row">
        <div class="col-12">
          If you become involuntarily unemployed, you can in some cases maintain your status as an EU worker for a period of
          time. It is a requirement that you, immediately after you have become involuntarily unemployed, register as a
          jobseeker at jobnet.dk or report to your local job centre with the purpose of finding new employment.<br />
        </div>
      </div>
    </div>

    <div class="col-12 pt-1">
      We always make a specific assessment of all the information in your case when we assess whether you can maintain
      your status as an EU worker during involuntary unemployment.
    </div>

    <div class="col-12 pt-1">
      <a href="https://su.dk/10964" target="_blank">You can read more about the rules on su.dk.</a>
    </div>

    <div class="d-none" #uploadOpsigelseLabel>
      <span class="visually-hidden">Required</span>
      * Submit documentation showing that you have been dismissed from your job (e.g. employment termination letter).
    </div>
    <div class="d-none" #uploadOpsigelseAriaLabelTilfoejFiler>
      Add more files with documentation
    </div>
    <div class="d-none" #uploadOpsigelseAriaLabelFil>
      Upload file with documentation
    </div>
    <div class="d-none" #uploadOpsigelseManglerTekst>
      Documentation is missing
    </div>
    <app-filer
      class="col-12 py-3"
      [form]="form.controls.filerOpsigelse"
      [accept]="'.pdf'"
      [idPrefix]="'opsigelse'"
      [ariaLabelTilfoejFiler]="uploadOpsigelseAriaLabelTilfoejFiler.innerText"
      [ariaLabelFil]="uploadOpsigelseAriaLabelFil.innerText"
      [filManglerTekst]="uploadOpsigelseManglerTekst.innerText"
      [label]="uploadOpsigelseLabel.innerHTML"
      (keydown.enter)="$event.preventDefault()"
      [fjern]="'Remove'"
    >
    </app-filer>

    <div class="d-none" #uploadJobcenterLabel>
      <span class="visually-hidden">Required</span>
      * Submit documentation showing what date you registered as a jobseeker at <a href="https://job.jobnet.dk/" target="_blank">jobnet.dk</a> or reported to your local job centre.
    </div>
    <div class="d-none" #uploadJobcenterAriaLabelTilfoejFiler>
      Add more files with documentation
    </div>
    <div class="d-none" #uploadJobcenterAriaLabelFil>
      Upload file with documentation
    </div>
    <div class="d-none" #uploadJobcenterManglerTekst>
      Documentation is missing
    </div>
    <app-filer
      class="col-12 py-3"
      [form]="form.controls.filerJobcenter"
      [accept]="'.pdf'"
      [idPrefix]="'jobcenter'"
      [ariaLabelTilfoejFiler]="uploadJobcenterAriaLabelTilfoejFiler.innerText"
      [ariaLabelFil]="uploadJobcenterAriaLabelFil.innerText"
      [filManglerTekst]="uploadJobcenterManglerTekst.innerText"
      [label]="uploadJobcenterLabel.innerHTML"
      (keydown.enter)="$event.preventDefault()"
      [fjern]="'Remove'"
    >
    </app-filer>

    <div class="col-12">
      <label for="bemaerkninger">
        You can provide supplemental information here.
      </label>
      <span class="float-end form-text text-muted"><small>{{ bemaerkningerTaeller$ | async }}</small></span>
      <input type="text" id="bemaerkninger" maxlength="1200" class="form-control" formControlName="bemaerkninger" />
    </div>

    <app-upload-gdpr class="col-12" [form]="form"></app-upload-gdpr>

    <div class="col-12 pt-3">
      <button (click)="sendAnsoegning()" type="submit" [disabled]="form.invalid || senderAnsoegning" class="btn btn-primary btn-lg d-block w-100">
        Submit documentation
      </button>
    </div>
    <div *ngIf="senderAnsoegning" class="col-12 pt-3 text-center">
      <fa-icon [icon]="faSpinner" [spin]="true" [size]="'2x'" [pulse]="true"></fa-icon>
    </div>
    <div *ngIf="senderAnsoegning" class="col-12 pt-3 text-center" role="alert">
      Submitting documentation (may take several minutes)
    </div>
  </div>
</form>
