import { NgModule, Optional, SkipSelf } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CookieService } from 'ngx-cookie-service';

import { GoogleAnalyticsComponent } from './ufm-google-analytics/ufm-google-analytics.component';
import { UfmPropertiesModule } from '@ufmit/ufm-properties';
import { UfmUserTrackingComponent } from './ufm-user-tracking.component';


@NgModule({
  declarations: [UfmUserTrackingComponent, GoogleAnalyticsComponent],
  imports: [
    CommonModule,
    UfmPropertiesModule
  ],
  providers: [CookieService],
  exports: [UfmUserTrackingComponent]
})
export class UfmUserTrackingModule {
  constructor(@Optional() @SkipSelf() parentModule?: UfmUserTrackingModule) {
    if (parentModule) {
      throw new Error(
        'UfmUserTrackingModule is already loaded. Import it in the AppModule only');
    }
  }
}
