<div class="row" id="kvittering">
  <app-upload-kvittering-sidehoved class="col-12"></app-upload-kvittering-sidehoved>

  <div class="col-12 pt-4">
    <h4>Other changes to your circumstances as an EU worker</h4>
  </div>

  <div class="col-12 pt-3" *ngIf="filer.length > 0">
    Submit any documentation you may have:
    <app-kvittering-filer class="col-12" [filer]="filer" [parameternavn]="'oevrigehenvendelser'">
    </app-kvittering-filer>
  </div>

  <div class="col-12 pt-3" *ngIf="kvittering.bemaerkninger !== null">
    You can provide supplemental information here: <br />
    <strong class="ufm-black">{{ kvittering.bemaerkninger }}</strong>
  </div>

  <app-upload-kvittering-gdpr class="col-12 pt-3"></app-upload-kvittering-gdpr>
</div>
