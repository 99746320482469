<form [formGroup]="form">
  <div class="row">
    <div class="col-12 h3" role="heading" aria-level="2">
      You stop working
    </div>

    <div class="col-12 pt-3" role="heading" aria-level="3">
      <div class="row">
        <div class="col-12">
          If you stop working, you must immediately inform us of your last working day.
        </div>
        <div class="col-12 pt-1">
          <a href="https://su.dk/10964" target="_blank">You can read more about the rules on su.dk.</a>
        </div>
      </div>
    </div>

    <div class="col-12 pt-2 mb-3">
      <label for="sidsteArbejdsdag" class="form-check-label">
        <span class="visually-hidden">Required</span>*
        When was/is your last working day?
        <span class="visually-hidden">
          Enter date as d d "-" m m "-"" y y y y
        </span>
      </label>
      <input
        id="sidsteArbejdsdag"
        name="sidsteArbejdsdag"
        type="text"
        maxlength="10"
        placeholder="dd-mm-yyyy"
        autocomplete="off"
        class="form-control"
        formControlName="sidsteArbejdsdag"
        bsDatepicker
        placement="bottom"
        [bsConfig]="{ dateInputFormat: 'DD-MM-YYYY', adaptivePosition: true }"
        (onHidden)="datoVaeglerSkjul()"
        (onShown)="datoVaeglerVis()"
      />
      <div
        *ngIf="form.controls.sidsteArbejdsdag.touched && datovaeglerTilstand !== 1 && form.controls.sidsteArbejdsdag.errors"
        role="alert" aria-atomic="true">
        <div *ngIf="form.controls.sidsteArbejdsdag.errors.required" class="text-danger">
          Date is missing
        </div>
      </div>
    </div>

    <div class="col-12">
      <label for="bemaerkninger">
        You can provide supplemental information here.
      </label>
      <span class="float-end form-text text-muted"><small>{{ bemaerkningerTaeller$ | async }}</small></span>
      <input type="text" id="bemaerkninger" maxlength="1200" class="form-control" formControlName="bemaerkninger" />
    </div>

    <app-upload-gdpr class="col-12" [form]="form"></app-upload-gdpr>

    <div class="col-12 pt-3">
      <button (click)="startSendAnsoegning()" type="submit" [disabled]="form.invalid || senderAnsoegning" class="btn btn-primary btn-lg d-block w-100">
        Submit documentation
      </button>
    </div>
    <div *ngIf="senderAnsoegning" class="col-12 pt-3 text-center">
      <fa-icon [icon]="faSpinner" [spin]="true" [size]="'2x'" [pulse]="true"></fa-icon>
    </div>
    <div *ngIf="senderAnsoegning" class="col-12 pt-3 text-center" role="alert">
      Submitting documentation (may take several minutes)
    </div>
  </div>
</form>
