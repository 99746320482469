import {Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {UntypedFormArray} from '@angular/forms';
import {interval, Subscription} from 'rxjs';
import {faPlusCircle} from '@fortawesome/free-solid-svg-icons';
import {FerieService} from '../../upload/ferie/ferie/ferie.service';
import {FeriePeriodeComponent} from './ferie-periode/ferie-periode.component';

@Component({
  selector: 'app-ferie-perioder',
  templateUrl: './ferie-perioder.component.html',
  styleUrls: ['./ferie-perioder.component.scss']
})
export class FeriePerioderComponent implements OnInit, OnDestroy {

  @Input() form: UntypedFormArray;
  @Input() id: string;
  @Input() label: string;
  @Input() labelTildato: string;
  @Input() labelFradato: string;
  @Output() perioderFejl$: EventEmitter<string> = new EventEmitter();

  faPlusCircle = faPlusCircle;
  @ViewChild('perioderFejl', {static: false}) private perioderFejl: ElementRef;
  private subscriptions: Subscription[] = [];

  constructor(private ferieService: FerieService) {}

  ngOnInit() {
    const touched: Subscription = interval(50).subscribe(() => {
      if (this.form.touched) {
        touched.unsubscribe();
        this.emitperioderFejl$();
      }
    });
    this.subscriptions.push(touched);
    this.form.valueChanges.subscribe(() => {
      this.emitperioderFejl$();
    });
  }

  ngOnDestroy() {
    let s: Subscription;
    while (this.subscriptions.length) {
      s = this.subscriptions.shift();
      if (!s.closed) {
        s.unsubscribe();
      }
    }
  }

   antalPerioder(): number {
    return this.form.length;
  }
  tilfoejFlerePerioder(): boolean {
    this.form.push(this.ferieService.nyPeriode());
    return false;
  }

  private emitperioderFejl$(): void {
    if (this.form.valid || this.form.status === 'DISABLED') {
      this.perioderFejl$.emit();
    } else if (this.perioderFejl) {
      this.perioderFejl$.emit(this.perioderFejl.nativeElement.innerText);
    } else {
      this.perioderFejl$.emit('Other errors in periods');
    }
  }

}
