import {Injectable} from '@angular/core';
import {UntypedFormGroup, UntypedFormBuilder, Validators} from '@angular/forms';

import {Ansoegningstype} from '../../../utils/ansoegning/ansoegningstype.enum';
import {SendAnsoegningService, ValiderMindstEnFil} from '../../../utils/service/send-ansoegning.service';

@Injectable({
  providedIn: 'root'
})
export class AfskedigelseService {

  constructor(private formBuilder: UntypedFormBuilder, private sendAnsoegningService: SendAnsoegningService) {}

  initialiserAnsoegningFormGroup(): UntypedFormGroup {
    return this.formBuilder.group({
      ansoegning: 'upload',
      ansoegningstype: Ansoegningstype.UploadAfskedigelse,
      afskedigelse: this.formBuilder.group({
        filerOpsigelse: this.formBuilder.array([this.sendAnsoegningService.nyFil('opsigelse')], [ValiderMindstEnFil]),
        filerJobcenter: this.formBuilder.array([this.sendAnsoegningService.nyFil('jobcenter')], [ValiderMindstEnFil]),
        bemaerkninger: null,
        godkendt: [null, Validators.requiredTrue]
      })
    });
  }
}
