import { Injectable, Injector, Inject } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable, timer } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import * as moment from 'moment/moment';
import { Router } from '@angular/router';

import { UfmFejlhaandteringService } from './ufm-fejlhaandtering.service';
import { UfmFejlhaandteringConfig, UFM_FEJLHAANDTERING_CONFIG } from './ufm-fejlhaandtering.config';
import { UfmSessionLoginService } from '@ufmit/ufm-session';

@Injectable()
export class UfmInterceptorError implements HttpInterceptor {

  private ufmFejlhaandteringConfig: UfmFejlhaandteringConfig = {};

  constructor(
    @Inject(Injector) private injector: Injector,
    private router: Router,
    private ufmFejlhaandteringService: UfmFejlhaandteringService,
    private ufmSessionLoginService:  UfmSessionLoginService) {
    // hent ufmFejlgaandteringConfig (popupOverskrift)
    try {
      this.ufmFejlhaandteringConfig = this.injector.get(UFM_FEJLHAANDTERING_CONFIG);
    } catch (e) { }
  }

  // dynamisk config getter
  getpopupOverskrift(): string {
    if (this.ufmFejlhaandteringConfig.tekster && this.ufmFejlhaandteringConfig.tekster.popupOverskrift) {
      return this.ufmFejlhaandteringConfig.tekster.popupOverskrift;
    } else {
      return 'Fejl';
    }
  }

  // Need to get ToastrService from injector rather than constructor injection to avoid cyclic dependency error
  private get toastrService(): ToastrService {
    return this.injector.get(ToastrService);
  }  

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (request.headers.get('NoInterceptorError')) {
      return next.handle(request);
    }
    return next.handle(request)
      .pipe(
        catchError((error) => {
          if (!this.ufmFejlhaandteringService) {
            console.error(error);
            throw 'ufmFejlhaandteringService er ikke initialiseret';
          }
          const fejlBesked = this.ufmFejlhaandteringService.foldUdFejlBesked(error);
          const datoOgTid = moment().format('DD-MM-YYYY HH:mm');
          const kortSessionId = this.ufmFejlhaandteringService.hentKortSessionid();
          const trackingInfo = ' (' + datoOgTid + ' / ' + kortSessionId + ')';
          let nyFejl = true;
          if (!this.toastrService) {
            throw 'toastrService er ikke initialiseret. fejlBesked=' + fejlBesked;
          }
          for (const activeToast of this.toastrService.toasts) {
            let message: string = activeToast.message.toString();
            message = message.substr(0, message.length - datoOgTid.length);
            if (message.indexOf(fejlBesked) > -1) {
              nyFejl = false;
            }
          }

          // Fang fejl fra API-security vedr. blandet PROD og DEMO login
          if ( (error.status === 403) && (fejlBesked.match(/DEMO/))) {
            console.log('Sammenblanding af PROD og DEMO login. Logger af og redirect til login');
            timer(0).subscribe(() => {
              this.ufmSessionLoginService.logAf();
            });
            nyFejl = false; 
          }
          if (nyFejl) {
            const popupOverskrift: string = this.getpopupOverskrift();
            console.error('InterseptorError: ', popupOverskrift, fejlBesked);
            console.error('InterseptorError: popupOverskrift, fejlBesked=', popupOverskrift, fejlBesked);
            try {
              this.toastrService.error(
                fejlBesked + trackingInfo, popupOverskrift,
                { tapToDismiss: true, disableTimeOut: true, closeButton: true }
              );
            } catch (error2) {
              console.error(error, fejlBesked, error2);
            }
            if (error.status === 426) {
              // 426 Upgrade Required returneret fra API. versionsnummer mangler i FE
              timer(0).subscribe(() => {
                console.error('426 Upgrade Required: redirect to /clear-cache');
                this.router.navigate(['/clear-cache'])
              });
            }
 
          }
          throw fejlBesked;
        }) as any
      );
  }
}
