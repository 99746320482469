<form [formGroup]="form">
  <div class="row">
    <div class="col-12 pt-3">
      <label [attr.for]="antalTimerPrUge">
        <span class="visually-hidden">Required</span>* {{hours}}
        <span class="visually-hidden">Enter an integer between 1 and 99 hours</span>
      </label>
      <input type="text" pattern="[0-9]{1,2}" [attr.id]="antalTimerPrUge" class="form-control" formControlName="antalTimerPrUge" />
      <div *ngIf="form.controls.antalTimerPrUge.errors && form.controls.antalTimerPrUge.errors.pattern"
           class="text-danger">
        Hours must be between 1 and 99 and may not contain digits
      </div>
      <div *ngIf="form.controls.antalTimerPrUge.touched && form.errors && form.errors.antalTimerPrUge" role="alert"
           aria-atomic="true" #antalTimerPrUgeError>
        <div *ngIf="form.errors.antalTimerPrUge.required" class="text-danger">
          Hours are missing
        </div>
        <div *ngIf="form.errors.antalTimerPrUge.interval" class="text-danger">
          Hours must be between 1 and 99 and may not contain digits
        </div>
      </div>
    </div>

    <div class="col-12 pt-3">
      <div class="row">
        <div class="form-check" role="radiogroup" class="col-12">
          <label class="form-check-label col-12">
            <span class="visually-hidden">Required</span>* {{wage}}
          </label>
          <div class="form-check pt-1 col-12">
            <input
              class="form-check-input"
              formControlName="timeEllerMaanedsLoen"
              type="radio"
              name="timeEllerMaanedsLoen"
              [attr.id]="timeEllerMaanedsLoen1"
              value="t"
              (keydown.enter)="$event.preventDefault()"
            />
            <label class="form-check-label" [attr.for]="timeEllerMaanedsLoen1">
              <span class="visually-hidden">I will have a fixed hourly wage</span>
              I will have a fixed hourly wage
            </label>
          </div>
          <div class="form-check col-12">
            <input
              class="form-check-input"
              formControlName="timeEllerMaanedsLoen"
              type="radio"
              name="timeEllerMaanedsLoen"
              [attr.id]="timeEllerMaanedsLoen2"
              value="m"
              (keydown.enter)="$event.preventDefault()"
            />
            <label class="form-check-label" [attr.for]="timeEllerMaanedsLoen2">
              <span class="visually-hidden">I will have a fixed monthly wage</span>
              I will have a fixed monthly wage
            </label>
          </div>
          <div class="form-check col-12">
            <input
              class="form-check-input"
              formControlName="timeEllerMaanedsLoen"
              type="radio"
              name="timeEllerMaanedsLoen"
              [attr.id]="timeEllerMaanedsLoen3"
              value="v"
              (keydown.enter)="$event.preventDefault()"
            />
            <label class="form-check-label" [attr.for]="timeEllerMaanedsLoen3">
              <span class="visually-hidden">I will not have a fixed hourly or monthly wage</span>
              I will not have a fixed hourly or monthly wage
            </label>
          </div>
        </div>
        <div *ngIf="form.controls.timeEllerMaanedsLoen.touched && form.errors && form.errors.timeEllerMaanedsLoen"
             class="text-danger col-12" role="alert" aria-atomic="true" #timeEllerMaanedsLoenError>
          Type of wage must be chosen
        </div>
      </div>
    </div>

    <div class="d-none" #timeloenKrPlaceholderText *ngIf="currentJob">
      What is your hourly wage?
    </div>

    <div class="d-none" #maanedsloenKrPlaceholderText *ngIf="currentJob">
      What is your monthly wage?
    </div>

    <div class="d-none" #timeloenKrPlaceholderText *ngIf="!currentJob">
      What will your hourly wage be?
    </div>

    <div class="d-none" #maanedsloenKrPlaceholderText *ngIf="!currentJob">
      What will your monthly wage be?
    </div>

    <div class="col-12">
      <div class="row">
        <app-fold-ud class="col-12" [vis]="loenKrJaVis">
          <div class="row">
            <div class="col-12">
              <input
                type="text"
                pattern="[0-9]{1,5}"
                [attr.id]="loenKr"
                class="form-control"
                formControlName="loenKr"
                [attr.placeholder]="loenKrPlaceholder"
              />
              <div *ngIf="form.controls.loenKr.errors && form.controls.loenKr.errors.pattern" class="text-danger">
                Hourly or monthly wage must be between 1 and 99999 and may not contain digits
              </div>

              <div *ngIf="form.controls.loenKr.touched && form.errors && form.errors.loenKr" role="alert" aria-atomic="true" #loenKrError>
                <div *ngIf="form.errors.loenKr.required" class="text-danger">
                  Wage is missing
                </div>
                <div *ngIf="form.errors.loenKr.interval" class="text-danger">
                  Wage must be between 1 and 99999 and may not contain digits
                </div>
              </div>
            </div>
          </div>
        </app-fold-ud>
      </div>
    </div>
  </div>
</form>
