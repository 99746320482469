import { Component, OnInit, Input } from '@angular/core';
import { faPlus, faMinus } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-accordion-prefix',
  templateUrl: './accordion-prefix.component.html',
  styleUrls: ['./accordion-prefix.component.scss']
})
export class AccordionPrefixComponent implements OnInit {

  @Input() vis: boolean;
  @Input() opacity: number;

  style: {[key: string]: number | string};
  faPlus = faPlus;
  faMinus = faMinus;

  constructor() { }

  ngOnInit() {
    if (this.opacity === undefined) {
      this.opacity = 1 ;
    }
    if (this.opacity < 0 || this.opacity > 1) {
      this.opacity = 1 ;
    }
    this.style = { opacity: this.opacity } ;
  }

}
