import { Injectable, EventEmitter, Injector, Inject } from '@angular/core';
import * as moment from 'moment/moment';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';

import { UFM_PROPERTIES_CONFIG, UfmPropertiesConfig } from '@ufmit/ufm-properties';

@Injectable({
  providedIn: 'root'
})
export class UfmGoogleAnalyticsService {

  private ufmPropertiesConfig: UfmPropertiesConfig = {};
  private googleAnalyticsStartet = false;
  jaTaktilSporing$: EventEmitter<null> = new EventEmitter<null>();

  constructor(private cookieService: CookieService, @Inject(Injector) private injector: Injector, private router: Router) {
    // læs ufmPropertiesConfig (environment.userTracking.ga.ejendom)
    try {
      this.ufmPropertiesConfig = this.injector.get(UFM_PROPERTIES_CONFIG);
    } catch (e) { }
  }

  // dynamisk config getter
  private getEjendom(): string {
    const environment: any = this.ufmPropertiesConfig.environment || {};
    const ejendom: string = environment.userTracking ? (environment.userTracking.ga ? environment.userTracking.ga.ejendom : null) : null;
    return ejendom;
  }

  public accepteret(): boolean {
    const accepteret = this.getCookie('cc_cookie_accept') === 'cc_cookie_accept';
    if (accepteret) {
      this.startGoogleAnalytics();
      if (this.getCookie('gdpr') === 'ja') {
        // ret gdpr accept til permanent gdpr accept
        this.setCookie('gdpr', 'ja', moment().add(10, 'years').toDate());
      }
    }
    return accepteret;
  }

  public jaTaktilSporing(): boolean {
    this.setCookie('cc_cookie_accept', 'cc_cookie_accept', moment().add(10, 'years').toDate());
    this.startGoogleAnalytics();
    this.sendUrl('/ga/accept' + this.router.url);
    this.jaTaktilSporing$.emit();
    return false;
  }

  public afvist(): boolean {
    const afvist = this.getCookie('cc_cookie_decline') === 'cc_cookie_decline';
    return afvist;
  }
  public nejTaktilSporing(): boolean {
    this.setCookie('cc_cookie_decline', 'cc_cookie_decline', moment().add(10, 'years').toDate());
    return false;
  }
  public visCookieMeddelelse(): boolean {
    return !this.accepteret() && !this.afvist();
  }

  private startGoogleAnalytics(): void {
    if (this.googleAnalyticsStartet) {
      return;
    }
    this.googleAnalyticsStartet = true;

    const ejendom: string = this.getEjendom();
    if (!ejendom) {
      console.error('fejl. Kan ikke starte google analytics tracking. ejendom er ikke konfigureret');
      return;
    }

    const script1 = document.createElement('script');
    script1.src = `https://www.googletagmanager.com/gtag/js?id=#{this.ejendom}`;
    document.body.appendChild(script1);
    const script2 = document.createElement('script');
    script2.innerHTML =
      `window.dataLayer = window.dataLayer || [];
       function gtag() { dataLayer.push(arguments); } ;
       gtag("js", new Date());
       gtag("config", "#{ejendom}", {"anonymize_ip": true});` ;
    document.body.appendChild(script2);
  }

  public sendUrl(url: string): void {
    const ejendom: string = this.getEjendom();
    if (this.googleAnalyticsStartet && ejendom) {
      (window as any).gtag('config', ejendom, { page_title: '', page_path: url });
    }
  }

  private setCookie(name: string, value: string, dato?: Date | number) {
    if (!dato) {
      dato = 0;
    }
    this.cookieService.set(name, value, dato, '/');
  }

  private getCookie(name: string): string {
    return this.cookieService.get(name);
  }

}
