import { Injectable, Injector } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { UfmFuldmagtConfig, UFM_FULDMAGT_CONFIG } from './ufm-fuldmagt.config';
import { UFM_PROPERTIES_CONFIG, UfmPropertiesConfig } from '@ufmit/ufm-properties';

@Injectable({
  providedIn: 'root'
})
export class UfmFuldmagtConfigService {

  public fuldmagtKonfigurationOpdateret$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private ufmFuldmagtConfig: UfmFuldmagtConfig = {};
  private ufmPropertiesConfig: UfmPropertiesConfig = {};

  constructor(
    private injector: Injector) {
    // læs ufmFuldmagtConfig (tekster)
    try {
      this.ufmFuldmagtConfig = this.injector.get(UFM_FULDMAGT_CONFIG);
    } catch (e) { }
    // hent ufmPropertiesConfig
    try {
      this.ufmPropertiesConfig = this.injector.get(UFM_PROPERTIES_CONFIG);
    } catch (e) { }    
  }

  public genindlaesOpdateretKonfiguration(): void {
    this.fuldmagtKonfigurationOpdateret$.next(true);
  }

  getFuldmagtConfig(): UfmFuldmagtConfig {
    if (this.ufmFuldmagtConfig.tekster) {
      return {
        tekster: {
          fuldmagt: this.ufmFuldmagtConfig.tekster.fuldmagt,
          fuldmagtsValg: this.ufmFuldmagtConfig.tekster.fuldmagtsValg,
          fuldmagtMangler: this.ufmFuldmagtConfig.tekster.fuldmagtMangler
        },
      };
    } else {
      return {
        tekster: {
          fuldmagt: {},
          fuldmagtsValg: {},
          fuldmagtMangler: {}
        },
      };
    }
    return {
      tekster: {
        fuldmagt: null,
        fuldmagtsValg: null,
        fuldmagtMangler: null
      },
    };
    const config: UfmFuldmagtConfig = JSON.parse(JSON.stringify(this.ufmFuldmagtConfig));
    if (!config.tekster) {
      config.tekster = {};
    }
    // default tekster til fuldmagt component
    if (!config.tekster.fuldmagt) {
      config.tekster.fuldmagt = {};
    }
    if (!config.tekster.fuldmagt.overskrift) {
      config.tekster.fuldmagt.overskrift = 'Fuldmagt';
    }
    if (!config.tekster.fuldmagt.cvrAgererSomFuldmagtshaver) {
      config.tekster.fuldmagt.cvrAgererSomFuldmagtshaver = 'Du agerer med fuldmagtprivilegier på vegne af personen';
    }
    if (!config.tekster.fuldmagt.cprAgererSomFuldmagtshaver) {
      config.tekster.fuldmagt.cprAgererSomFuldmagtshaver = 'Du agerer med fuldmagtprivilegier på vegne af personen';
    }
    if (!config.tekster.fuldmagt.harFuldmagt) {
      config.tekster.fuldmagt.harFuldmagt = 'Du har fuldmagtsprivilegier til at agere på vegne af en anden person';
    }
    if (!config.tekster.fuldmagt.skiftBrugerKnap) {
      config.tekster.fuldmagt.skiftBrugerKnap = 'Skift bruger';
    }
    // default tekster til fuldmagtsvalg component
    if (!config.tekster.fuldmagtsValg) {
      config.tekster.fuldmagtsValg = {};
    }
    if (!config.tekster.fuldmagtsValg.overskrift) {
      config.tekster.fuldmagtsValg.overskrift = 'Fuldmagtsvalg';
    }
    if (!config.tekster.fuldmagtsValg.cvrAgererSomFuldmagtshaver) {
      config.tekster.fuldmagtsValg.cvrAgererSomFuldmagtshaver = 'Du er logget på med et medarbejdercertifikat med tildelt fuldmagts privilegier til at agere som en anden bruger.';
    }
    if (!config.tekster.fuldmagtsValg.vaelgPerson) {
      config.tekster.fuldmagtsValg.vaelgPerson = 'Vælg den person du vil repræsentere:';
    }
    if (!config.tekster.fuldmagtsValg.duAgererSomDigSelv) {
      config.tekster.fuldmagtsValg.duAgererSomDigSelv = 'Dig selv';
    }
    if (!config.tekster.fuldmagtsValg.fuldmagtstype) {
      config.tekster.fuldmagtsValg.fuldmagtstype = 'Fuldmagt til SU-stipendie';
    }
    if (!config.tekster.fuldmagtsValg.annullerKnap) {
      config.tekster.fuldmagtsValg.annullerKnap = 'Annuller';
    }
    if (!config.tekster.fuldmagtsValg.logAfKnap) {
      config.tekster.fuldmagtsValg.logAfKnap = 'Log af';
    }
    if (!config.tekster.fuldmagtsValg.vaelgKnap) {
      config.tekster.fuldmagtsValg.vaelgKnap = 'Vælg';
    }
    if (!config.tekster.fuldmagtsValg.ingenFuldmagt) {
      config.tekster.fuldmagtsValg.ingenFuldmagt = 'ingen fuldmagt';
    }
    // default tekster til fuldmagt mangler component
    if (!config.tekster.fuldmagtMangler) {
      config.tekster.fuldmagtMangler = {};
    }
    if (!config.tekster.fuldmagtMangler.tekst1) {
      config.tekster.fuldmagtMangler.tekst1 = 'Du er logget på med et medarbejdercertifikat uden at have fået tildelt fuldmagts privilegier og kan derfor ikke foretage ændringer; hverken for dig selv eller på vegne af andre.';
    }
    if (!config.tekster.fuldmagtMangler.tekst2) {
      config.tekster.fuldmagtMangler.tekst2 = 'Hvis du forventede at kunne handle under fuldmagt på vegne af en anden bruger, skal du kontakte denne bruger og få vedkommende til at sikre fuldmagtens gyldighed ved hjælp af NemLog-in.';
    }
    if (!config.tekster.fuldmagtMangler.logAfKnap) {
      config.tekster.fuldmagtMangler.logAfKnap = 'Log af';
    }
    return config;
  }

  private getApiPropertyNavn(): string {
    let apiPropertyNavn = 'api';
    if (this.ufmFuldmagtConfig && this.ufmFuldmagtConfig.environment) {
      apiPropertyNavn = this.ufmFuldmagtConfig.environment.apiPropertyNavn || 'api';
    }
    return apiPropertyNavn;
  }

  public getKaldLogin(): boolean {
    return (this.ufmFuldmagtConfig.apiLoginEndpoint ? true : false);
  }

  public getApiLoginUrl(): string {
    const apiPropertyNavn = this.getApiPropertyNavn();
    const environment: any = this.ufmPropertiesConfig.environment || {};
    const api: string = environment[apiPropertyNavn] ? environment[apiPropertyNavn] : '/api';
    const apiLogoutUrl: string = api + '/' + this.ufmFuldmagtConfig.apiLoginEndpoint;
    return apiLogoutUrl;
  }

}
