import { BrowserModule } from '@angular/platform-browser';
import { NgModule, LOCALE_ID, APP_INITIALIZER } from '@angular/core';
import { LoadingBarHttpClientModule } from '@ngx-loading-bar/http-client';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CookieService } from 'ngx-cookie-service';
import { ToastrModule, ToastrService } from 'ngx-toastr';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TypeaheadModule, TypeaheadConfig } from 'ngx-bootstrap/typeahead';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { CommonModule, DatePipe } from '@angular/common';
import { BsDatepickerConfig, BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { KeycloakService, KeycloakAngularModule } from 'keycloak-angular';

// ufm moduler
import { UfmBackendtid1Module } from '@ufmit/ufm-backendtid1';
import { UfmBrowsercacheModule } from '@ufmit/ufm-browsercache';
import { UfmFejlhaandteringModule } from '@ufmit/ufm-fejlhaandtering';
import { UfmFuldmagtModule } from '@ufmit/ufm-fuldmagt';
import { UfmPropertiesModule } from '@ufmit/ufm-properties';
import { UfmSessionModule } from '@ufmit/ufm-session';
import { UfmUserTrackingModule } from '@ufmit/ufm-user-tracking';

// services
import { AppService } from './app.service';
//import { ProfilService } from './core/profil.service';

// pipes
//import { JaNejPipe } from './forretnings-sider/ligestilling/kvittering/ja-nej.pipe';
import { LangdatoPipe } from './shared/dato/langdato-pipe/langdato.pipe';

// components
import { AccordionComponent } from './shared/ui/accordion/accordion.component';
import { AccordionPrefixComponent } from './shared/ui/accordion/accordion-prefix/accordion-prefix.component';
import { AccordionStatusComponent } from './shared/ui/accordion/accordion-status/accordion-status.component';
import { AccordionTekstComponent } from './shared/ui/accordion/accordion-tekst/accordion-tekst.component';
import { AfskedigelseComponent } from './forretnings-sider/upload/afskedigelse/afskedigelse/afskedigelse.component';
import { AfskedigelseKvitteringComponent } from './forretnings-sider/upload/afskedigelse/kvittering/afskedigelse-kvittering.component';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { FilComponent } from './forretnings-sider/utils/filer/fil/fil.component';
import { FilerComponent } from './forretnings-sider/utils/filer/filer.component';
import { FoldUdComponent } from './shared/ui/fold-ud/fold-ud.component';
import { JaNejRadiogroupComponent } from './shared/ui/ja-nej-radiogroup/ja-nej-radiogroup.component';
import { LoenoplysningerComponent } from './forretnings-sider/upload/nyt-arbejde/nyt-arbejde/loenoplysninger/loenoplysninger.component';
import { LoenoplysningsKvitteringComponent } from './forretnings-sider/upload/nyt-arbejde/kvittering/loenoplysnings-kvittering/loenoplysnings-kvittering.component';
import { MenuComponent } from './side-struktur/menu/menu.component';
import { NytArbejdeComponent } from './forretnings-sider/upload/nyt-arbejde/nyt-arbejde/nyt-arbejde.component';
import { NytArbejdeKvitteringComponent } from './forretnings-sider/upload/nyt-arbejde/kvittering/nyt-arbejde-kvittering.component';
import { OevrigeHenvendelserComponent } from './forretnings-sider/upload/oevrige-henvendelser/oevrige-henvendelser/oevrige-henvendelser.component';
import { OevrigeHenvendelserKvitteringComponent } from './forretnings-sider/upload/oevrige-henvendelser/kvittering/oevrige-henvendelser-kvittering.component';
import { SideBundComponent } from './side-struktur/side-bund/side-bund.component';
import { StopArbejdeComponent } from './forretnings-sider/upload/stop-arbejde/stop-arbejde/stop-arbejde.component';
import { StopArbejdeKvitteringComponent } from './forretnings-sider/upload/stop-arbejde/kvittering/stop-arbejde-kvittering.component';
import { TidPipe } from './shared/dato/tid-pipe/tid.pipe';
import { UarbejdsdygtigComponent } from './forretnings-sider/upload/uarbejdsdygtig/uarbejdsdygtig/uarbejdsdygtig.component';
import { KvitteringFilerComponent} from './forretnings-sider/utils/kvittering/kvittering-filer/kvittering-filer.component';
import { KvitteringPerioderComponent} from './forretnings-sider/utils/kvittering/kvittering-perioder/kvittering-perioder.component';
import { UarbejdsdygtigKvitteringComponent } from './forretnings-sider/upload/uarbejdsdygtig/kvittering/uarbejdsdygtig-kvittering.component';
import { UploadForsideComponent } from './forretnings-sider/upload/upload-forside/upload-forside.component';
import { UploadGdprComponent } from './forretnings-sider/upload/upload-gdpr/upload-gdpr.component';
import { UploadIkkeTilladtComponent } from './forretnings-sider/upload/upload-ikke-tilladt/upload-ikke-tilladt.component';
import { UploadKvitteringGdprComponent } from './forretnings-sider/upload/upload-kvittering-gdpr/upload-kvittering-gdpr.component';
import { UploadKvitteringSidehovedComponent } from './forretnings-sider/upload/upload-kvittering-sidehoved/upload-kvittering-sidehoved.component';

import { registerLocaleData } from '@angular/common';
import { defineLocale } from 'ngx-bootstrap/chronos';
import { daLocale } from 'ngx-bootstrap/locale';
import da from '@angular/common/locales/da';
registerLocaleData(da);
defineLocale('da', daLocale);

import { environment } from '../environments/environment';
import {FerieKvitteringPerioderComponent} from './forretnings-sider/upload/ferie/kvittering/ferie-kvittering-perioder/ferie-kvittering-perioder.component';
import {FerieKvitteringComponent} from './forretnings-sider/upload/ferie/kvittering/ferie-kvittering.component';
import {FerieComponent} from './forretnings-sider/upload/ferie/ferie/ferie.component';
import {FeriePeriodeComponent} from './forretnings-sider/utils/ferie-perioder/ferie-periode/ferie-periode.component';
import {FeriePerioderComponent} from './forretnings-sider/utils/ferie-perioder/ferie-perioder.component';
import {PdfDanFilerComponent} from './side-struktur/side-bund/dan-pdf-filer.component';
import {JaNejPipe} from './shared/pipe/ja-nej.pipe';

export function getBsDatepickerConfig(): BsDatepickerConfig {
  return Object.assign(new BsDatepickerConfig(), {
    containerClass: 'theme-default'
  });
}

function loadEnvironmentJson(http: HttpClient): Promise<boolean | void> {
  const headers: { [key: string]: string } = { NoInterceptorAgerendeCpr: 'x' };
  return new Promise<boolean>(
    (resolve) => {
      http
        .get('assets/environment.json', { headers })
        .toPromise()
        .then((environmentValues) => {
          for (const key in environmentValues) {
            if (environmentValues.hasOwnProperty(key)) {
              environment[key] = environmentValues[key];
            }
          }
          resolve(true);
        })
        .catch((reason) => {
          console.error('Failed to load environment properties. reason=', reason);
        });
  });
}

function initializeKeycloak(keycloakService: KeycloakService): Promise<boolean | void> {
  const script = document.createElement('script');
  script.src = environment.keycloak.url + '/js/keycloak.js';
  document.body.appendChild(script);
  if (environment.demo) {
    const re: RegExp = new RegExp('\\?requestid=(\\d+)');
    const m: RegExpMatchArray = window.location.href.match(re);
    if (m) {
      const us2000RequestId: string = m[1];
      sessionStorage.setItem('us2000RequestId', us2000RequestId);
    }
  }
  return keycloakService.init({
    config: environment.keycloak,
    initOptions: {
      onLoad: 'check-sso',
      silentCheckSsoRedirectUri: window.location.origin + '/assets/silent-check-sso.html'
    }
  })
  .catch((reason) => {
    console.error('Failed to initialize Keycloak', reason);
  });
}

function initializeApplication(httpClient: HttpClient, keycloakService: KeycloakService) {
  return () => 
    loadEnvironmentJson(httpClient).then(() => 
    initializeKeycloak(keycloakService)
    );
}

@NgModule({
  declarations: [
    AccordionComponent,
    AccordionPrefixComponent,
    AccordionStatusComponent,
    AccordionTekstComponent,
    AfskedigelseComponent,
    AfskedigelseKvitteringComponent,
    OevrigeHenvendelserComponent,
    OevrigeHenvendelserKvitteringComponent,
    KvitteringFilerComponent,
    KvitteringPerioderComponent,
    AppComponent,
    FilComponent,
    FilerComponent,
    FoldUdComponent,
    FerieKvitteringPerioderComponent,
    FerieKvitteringComponent,
    FerieComponent,
    FeriePeriodeComponent,
    FeriePerioderComponent,
    JaNejPipe,
    JaNejRadiogroupComponent,
    LangdatoPipe,
    LoenoplysningerComponent,
    LoenoplysningsKvitteringComponent,
    MenuComponent,
    NytArbejdeComponent,
    NytArbejdeKvitteringComponent,
    PdfDanFilerComponent,
    SideBundComponent,
    StopArbejdeComponent,
    StopArbejdeKvitteringComponent,
    TidPipe,
    UarbejdsdygtigComponent,
    UarbejdsdygtigKvitteringComponent,
    UploadForsideComponent,
    UploadIkkeTilladtComponent,
    UploadGdprComponent,
    UploadKvitteringGdprComponent,
    UploadKvitteringSidehovedComponent
  ],
  imports: [
    AppRoutingModule,
    BrowserAnimationsModule,
    BrowserModule,
    BsDropdownModule.forRoot(),
    BsDatepickerModule.forRoot(),
    CollapseModule.forRoot(),
    CommonModule,
    FontAwesomeModule,
    FormsModule,
    HttpClientModule,
    KeycloakAngularModule,
    LoadingBarHttpClientModule,
    ReactiveFormsModule,
    ToastrModule.forRoot({
      positionClass: 'toast-top-center',
      preventDuplicates: true,
    }),
    TypeaheadModule,
    UfmBackendtid1Module.forRoot({
      environment: { apiPropertyNavn: 'upApi', endpoint: '/backendtid' }
    }),
    UfmBrowsercacheModule.forRoot(),
  
    UfmFejlhaandteringModule.forRoot(),
    UfmFuldmagtModule.forRoot({ apiLoginEndpoint: 'loginsb' }),
    UfmPropertiesModule.forRoot({ environment: environment }),
    UfmSessionModule.forRoot({ logAfUrl: '/logaf', apiLoginEndpoint: 'loginsb' }),
    UfmUserTrackingModule
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: initializeApplication,
      multi: true,
      deps: [HttpClient, KeycloakService]
    },
    { provide: LOCALE_ID, useValue: 'da' },
    { provide: BsDatepickerConfig, useFactory: getBsDatepickerConfig },
    AppService,
    CookieService,
    DatePipe,
   // JaNejPipe,
    LangdatoPipe,
  //  ProfilService,
    ToastrService,
    TypeaheadConfig
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
