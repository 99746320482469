import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { Subscription } from 'rxjs';

import { UfmFuldmagtBruger } from '../ufm-fuldmagt-bruger';
import { UfmFuldmagtService } from '../ufm-fuldmagt.service';
import { UfmSessionLoginService, UfmSessionLogPaaStatus } from '@ufmit/ufm-session';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'ufm-fuldmagt',
  templateUrl: './ufm-fuldmagt.component.html',
  styleUrls: ['./ufm-fuldmagt.component.scss']
})
export class UfmFuldmagtComponent implements OnInit, OnDestroy {

  @Input() priv!: string;

  fuldmagtsBruger!: UfmFuldmagtBruger;
  fuldmagtMangler = false;
  visFuldmagtValg = false;

  private subscriptions: Subscription[] = [];
  private fuldmagtsBrugerSubscription!: Subscription;

  constructor(
    private ufmFuldmagtService: UfmFuldmagtService,
    private ufmSessionLoginService: UfmSessionLoginService) { }

  ngOnInit() {
    if (!this.priv) {
      console.error('Input [priv] mangler til ufm-fuldmagt component. Anvender urn:dk:umit:minsu:privilege_grants');
      this.priv = 'urn:dk:umit:minsu:privilege_grants';
    }
    let opstart = true;
    this.subscriptions.push(
      this.ufmFuldmagtService.getCachedFuldmagtsBruger(this.priv).subscribe(
        fuldmagtsBruger => {
          if (fuldmagtsBruger) {
            this.visFuldmagtValg = true;
            this.fuldmagtsBruger = fuldmagtsBruger;
            this.fuldmagtMangler = (this.fuldmagtsBruger.erVirksomhed && !this.fuldmagtsBruger.harPrivilegier);
            if (opstart && this.fuldmagtsBruger.agerendeCpr) {
              this.visFuldmagtValg = false;
            }
            opstart = false;
          }
        }
      )
    );
    this.subscriptions.push(
      this.ufmSessionLoginService.loggetPaa$.subscribe(erLoggetPaa => {
        if (erLoggetPaa === UfmSessionLogPaaStatus.EndnuIkkeTestet) {
          return;
        }
        if (this.fuldmagtsBrugerSubscription) {
          this.fuldmagtsBrugerSubscription.unsubscribe();
          this.fuldmagtsBrugerSubscription = null;
        }
        if (erLoggetPaa === UfmSessionLogPaaStatus.LoggetPaa) {
          this.fuldmagtsBrugerSubscription = this.ufmFuldmagtService.getCachedFuldmagtsBruger(this.priv)
            .subscribe(fuldmagtsBruger => this.fuldmagtsBruger = fuldmagtsBruger);
          this.subscriptions.push(this.fuldmagtsBrugerSubscription);
        } else {
          this.fuldmagtsBruger = null;
          this.fuldmagtMangler = false;
          this.visFuldmagtValg = false;
        }
      })
    );
  }

  ngOnDestroy() {
    let s: Subscription;
    while (this.subscriptions.length) {
      s = this.subscriptions.shift();
      if (!s.closed) {
        s.unsubscribe();
      }
    }
  }

}
