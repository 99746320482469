import { Component, OnInit, OnDestroy } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Subscription, Observable, timer } from 'rxjs';
import { map } from 'rxjs/operators';
import { Router } from '@angular/router';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { ToastrService } from 'ngx-toastr';

import { AppService } from '../../../../app.service';
import { DatoService } from '../../../../shared/dato/dato.service';
import { SendAnsoegningService } from '../../../utils/service/send-ansoegning.service';
import { StopArbejdeService } from './stop-arbejde.service';
import { UfmFejlhaandteringService } from '@ufmit/ufm-fejlhaandtering';
import { UfmUserTrackingService } from '@ufmit/ufm-user-tracking';

@Component({
  selector: 'app-stop-arbejde',
  templateUrl: './stop-arbejde.component.html',
  styleUrls: ['./stop-arbejde.component.scss']
})
export class StopArbejdeComponent implements OnInit, OnDestroy {
  
  private formWithEnvelope: UntypedFormGroup;
  form: UntypedFormGroup;
  bemaerkningerTaeller$: Observable<string>;
  private formData: FormData;
  senderAnsoegning = false;
  faSpinner = faSpinner;
  datovaeglerTilstand = 0;
  private subscriptions: Subscription[] = [];

  constructor(
    private appService: AppService,
    private datoService: DatoService,
    private sendAnsoegningService: SendAnsoegningService,
    private stopArbejdeService: StopArbejdeService,
    private toastrService: ToastrService,
    private router: Router,
    private ufmFejlhaandteringService: UfmFejlhaandteringService,
    private ufmUserTrackingService: UfmUserTrackingService) { }

  ngOnInit() {
    this.formWithEnvelope = this.stopArbejdeService.initialiserAnsoegningFormGroup();
    this.form = this.formWithEnvelope.controls.stopArbejde as UntypedFormGroup;
    this.bemaerkningerTaeller$ = this.form.controls.bemaerkninger.valueChanges
      .pipe(
        map((bemaerkninger) => {
          if (!bemaerkninger) {
            bemaerkninger = '';
          }
          return JSON.stringify(bemaerkninger.length) + '/1200';
        })
      );

    this.subscriptions.push(
      this.form.controls.sidsteArbejdsdag.valueChanges.subscribe((tidspunkt) => {
        const dato: Date = this.datoService.konverterTilUTCDato(tidspunkt);
        if (!(tidspunkt instanceof Date) || tidspunkt.getTime() !== dato.getTime()) {
          this.form.controls.sidsteArbejdsdag.setValue(dato);
        }
      })
    );
  }

  ngOnDestroy() {
    let s: Subscription;
    while (this.subscriptions.length) {
      s = this.subscriptions.shift();
      if (!s.closed) {
        s.unsubscribe();
      }
    }
  }

  startSendAnsoegning(): void {
    this.senderAnsoegning = true;
    this.sendAnsoegningService.erDioKlar().subscribe((erDioKlar) => {
      if (erDioKlar) {
        console.log('DIO er klar. Sender ansøgning');
        this.dioErKlar();
      } else {
        this.senderAnsoegning = false;
        this.toastrService.error('We are currently unable to accept applications. Please try again in a few hours. Sorry for the inconvenience.', 'Error', {
          tapToDismiss: true,
          disableTimeOut: true,
          closeButton: true
        });
      }
    });
  }

  private dioErKlar(): void {
    this.formData = this.sendAnsoegningService.initialiserFormData(this.formWithEnvelope, true);
    if (!this.formData) {
      this.senderAnsoegning = false;
      return;
    }
    this.subscriptions.push(
      this.appService.stopArbejdeKvitteringErKlar$.subscribe(() => {
        this.stopArbejdeKvitteringPdfErklar();
      })
    );
    this.appService.danStopArbejdeKvittering();
  }

  datoVaeglerSkjul(): void {
    this.datovaeglerTilstand++;
  }

  datoVaeglerVis(): void {
    this.datovaeglerTilstand++;
  }

  private stopArbejdeKvitteringPdfErklar(): void {
    timer(0).subscribe(() => {
      this.sendAnsoegningService.sendAnsoegning(this.formData)
        .subscribe(
          (ok) => {
            this.senderAnsoegning = false;
            if (ok && ok.receiptId) {
              // alt OK. Ansøgning findes i US2000 og 360
              this.ufmUserTrackingService.sendUrl('upload/stop-arbejde/ok');
              this.router.navigate(['/upload/kvittering/stop-arbejde']);
            } else {
              // fejl. OK svar fra POST, men ansøgning findes ikke i US2000 og 360
              this.sendAnsoegningService.enableFormGroup(this.form);
              this.ufmUserTrackingService.sendUrl('upload/stop-arbejde/fejl/receipt-id-mangler');
              const msg: string =
                'Unfortunately, a technical error occurred and we could not receive the application. user agent = ' +
                window.navigator.userAgent;
              console.error(msg);
              this.ufmFejlhaandteringService.sendFejlTilAPI(msg);
              this.toastrService.error(msg, 'Error', { tapToDismiss: true, disableTimeOut: true, closeButton: true });
            }
          },
          (error) => {
            console.error(error);
            this.senderAnsoegning = false;
            this.sendAnsoegningService.enableFormGroup(this.form);
            this.ufmUserTrackingService.sendUrl('upload/send/fejl');
          },
          () => {
            if (this.senderAnsoegning) {
              this.senderAnsoegning = false;
              this.sendAnsoegningService.enableFormGroup(this.form);
              this.ufmUserTrackingService.sendUrl('upload/send/fejl');
            }
            this.senderAnsoegning = false;
          }
        );
    });
  }
}
