import { Component, OnInit, DoCheck, ViewChild, ElementRef } from '@angular/core';

import { UfmFuldmagtConfigService } from '../ufm-fuldmagt-config.service';
import { UfmFuldmagtConfig } from '../ufm-fuldmagt.config';

@Component({
  selector: 'ufm-fuld-type',
  templateUrl: './ufm-fuldmagts-type.component.html',
  styleUrls: ['./ufm-fuldmagts-type.component.scss']
})
export class UfmFuldmagtstypeComponent implements OnInit, DoCheck {

  @ViewChild('htmlFuldmagtstype', { static: false }) htmlFuldmagtstype: ElementRef;
  private erUfmModulTeksterOpdateret = false;

  constructor(private ufmFuldmagtConfigService: UfmFuldmagtConfigService) { }

  ngOnInit() {
    this.ufmFuldmagtConfigService.fuldmagtKonfigurationOpdateret$.subscribe(opdateret => {
      if (opdateret) {
        this.opdaterTeksterPgaOpdateretKonfiguration();
      }
    });
  }

  ngDoCheck(): void {
    if (this.erUfmModulTeksterOpdateret || !this.htmlFuldmagtstype) {
      return;
    }
    this.erUfmModulTeksterOpdateret = true;
    this.opdaterTeksterPgaOpdateretKonfiguration();
  }

  private opdaterTeksterPgaOpdateretKonfiguration(): void {
    const ufmFuldmagtConfig: UfmFuldmagtConfig = this.ufmFuldmagtConfigService.getFuldmagtConfig();
    if (ufmFuldmagtConfig.tekster.fuldmagtsValg.fuldmagtstype) {
      this.htmlFuldmagtstype.nativeElement.innerText = ufmFuldmagtConfig.tekster.fuldmagtsValg.fuldmagtstype;
    }
  }

}
