<div class="row" id="kvittering">
  <app-upload-kvittering-sidehoved class="col-12"></app-upload-kvittering-sidehoved>

  <div class="col-12 pt-4">
    <h4>You become ill</h4>
  </div>

  <div class="col-12 pt-3">
    First day: <strong class="ufm-black"> {{ kvittering.fraDato | langdato }}</strong>
  </div>

  <div class="col-12 pt-3">
    Last day: <strong class="ufm-black"> {{ kvittering.tilDato | langdato }}</strong>
  </div>

  <div class="col-12 pt-3" *ngIf="filer.length > 0">
    Submit documentation of illness (e.g. medical records or statement from your doctor).
    <app-kvittering-filer class="col-12" [filer]="filer" [parameternavn]="'sygdom'">
    </app-kvittering-filer>
  </div>

  <div class="col-12 pt-3" *ngIf="kvittering.bemaerkninger !== null">
    Describe how your illness is affecting your ability to work: <br />
    <strong class="ufm-black">{{ kvittering.bemaerkninger }}</strong>
  </div>

 <app-upload-kvittering-gdpr class="col-12 pt-3"></app-upload-kvittering-gdpr>
</div>
