<div class="row">
  <div class="col-12">
    <h1>Unauthorized access</h1>
  </div>

  <div class="col-12 pt-2">
    <span>You have not the right credentials to access the upload documentation page.</span>
  </div>

  <div class="col-12 py-3">
    <button (click)="tilbageTilMinsu()" type="submit" id="tilbageTilMinsu" class="btn btn-primary hBack">
      back to su.dk
    </button>
  </div>
</div>
