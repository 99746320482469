import { Injectable } from '@angular/core';
import * as moment from 'moment/moment';

@Injectable({
  providedIn: 'root'
})
export class DatoService {

  constructor() { }

    public konverterTilUTCDato(input: string | Date): Date {
      if (!input) {
        return null;
      }
      let dato: Date;
      if (typeof input === 'string') {
        if (input.match(/^[0-9]{2}-[0-9]{2}-[0-9]{4}$/)) {
          dato = moment.utc(input, 'DD-MM-YYYY').toDate();
        } else if (input.match(/^[0-9]{8}$/)) {
          dato = moment.utc(input, 'DDMMYYYY').toDate();
        } else {
          console.error('ukendt dato format', input);
          return null;
        }
      } else {
        dato = input;
      }
      return moment.utc([dato.getFullYear(), dato.getMonth(), dato.getDate()]).toDate();
    }

    public konverterTilUTCMaaned(input: string | Date): Date {
      if (!input) {
        return null;
      }
      let dato: Date;
      if (typeof input === 'string') {
        if (input.match(/^[0-9]{2}-[0-9]{4}$/)) {
          dato = moment.utc(input, 'MM-YYYY').toDate();
        } else if (input.match(/^[0-9]{6}$/)) {
          dato = moment.utc(input, 'MMYYYY').toDate();
        } else {
          console.error('ukendt dato format', input);
          return null;
        }
      } else {
        dato = input;
      }
      return moment.utc([dato.getFullYear(), dato.getMonth(), 1]).toDate();
    }

}
