import { Injectable, EventEmitter, Injector, Inject } from '@angular/core';
import { Subscription } from 'rxjs';

import { UfmGoogleAnalyticsService } from './ufm-google-analytics/ufm-google-analytics.service';
import { UFM_PROPERTIES_CONFIG, UfmPropertiesConfig } from '@ufmit/ufm-properties';

@Injectable({
  providedIn: 'root'
})
export class UfmUserTrackingService {

  private ufmPropertiesConfig: UfmPropertiesConfig = {};

  jaTaktilSporing$: EventEmitter<null> = new EventEmitter<null>();

  constructor(private googleAnalyticsService: UfmGoogleAnalyticsService, @Inject(Injector) private injector: Injector) {
    // læs ufmPropertiesConfig (environment.userTracking.brug)
    try {
      this.ufmPropertiesConfig = this.injector.get(UFM_PROPERTIES_CONFIG);
    } catch (e) { }
    this.googleAnalyticsService.jaTaktilSporing$.subscribe(() => this.jaTaktilSporing$.emit());
  }

  // dynamisk config getter
  public brug(): string {
    const environment: any = this.ufmPropertiesConfig.environment || {};
    const brug: string = environment.userTracking ? environment.userTracking.brug : null;
    return brug;
  }

  public sendUrl(url: string): void {
    if (this.brug() === 'ga') {
      this.googleAnalyticsService.sendUrl(url);
    }
  }

  public accepteret(): boolean {
    if (this.brug() === 'ga') {
      return this.googleAnalyticsService.accepteret();
    }
  }

}
