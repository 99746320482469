<form [formGroup]="form">
  <div class="row">
    <div class="col-12 h3" role="heading" aria-level="2">
      You become ill
    </div>

    <div class="col-12 pt-3" role="heading" aria-level="3">
      <div class="row">
        <div class="col-12">
          If your working hours are reduced for a period of time because you are ill, you must provide documentation showing
          that you are temporarily unable to work as a result of illness or accident in order to maintain your status as a
          worker during your illness.<br />We always make a specific assessment of all the information in your case when we
          assess whether you can maintain your status as an EU worker when you are unable to work because of illness.
        </div>
        <div class="col-12 pt-1">
          <a href="https://su.dk/10964" target="_blank">You can read more about the rules on su.dk.</a>
        </div>
      </div>
    </div>

    <div class="col-12 pt-3">
      In what period of time have you been ill and unable to work?
    </div>

    <div class="col-12 pt-3 mb-3">
      <label for="fraDato" class="form-check-label">
        <span class="visually-hidden">Required</span>* First day
        <span class="visually-hidden">
          Enter date as d d "-" m m "-"" y y y y
        </span>
      </label>
      <input
        id="fraDato"
        name="fraDato"
        type="text"
        maxlength="10"
        placeholder="dd-mm-yyyy"
        autocomplete="off"
        class="form-control"
        formControlName="fraDato"
        bsDatepicker
        [maxDate]="maxFraDato"
        placement="bottom"
        [bsConfig]="{ dateInputFormat: 'DD-MM-YYYY', adaptivePosition: true }"
        (onHidden)="fraDatoVaeglerSkjul()"
        (onShown)="fraDatoVaeglerVis()"
      />
      <div *ngIf="form.controls.fraDato.touched && form.controls.fraDato.errors" role="alert" aria-atomic="true">
        <div *ngIf="form.controls.fraDato.errors.required" class="text-danger">
          Date is missing
        </div>
        <div *ngIf="form.controls.fraDato.errors.bsDate && form.controls.fraDato.errors.bsDate.maxDate" class="text-danger">
          First sick day must be equal to or less than last sick day
        </div>
      </div>
    </div>

    <div class="col-12 mb-3">
      <label for="tilDato" class="form-check-label">
        <span class="visually-hidden">Required</span>* Last day
        <span class="visually-hidden">
          Enter date as d d "-" m m "-"" y y y y
        </span>
      </label>
      <input
        id="tilDato"
        name="tilDato"
        type="text"
        maxlength="10"
        placeholder="dd-mm-yyyy"
        autocomplete="off"
        class="form-control"
        formControlName="tilDato"
        bsDatepicker
        [minDate]="minTilDato"
        placement="bottom"
        [bsConfig]="{ dateInputFormat: 'DD-MM-YYYY', adaptivePosition: true }"
        (onHidden)="tilDatoVaeglerSkjul()"
        (onShown)="tilDatoVaeglerVis()"
      />
      <div *ngIf="form.controls.tilDato.touched && form.controls.tilDato.errors" role="alert" aria-atomic="true">
        <div *ngIf="form.controls.tilDato.errors.required" class="text-danger">
          Date is missing
        </div>
        <div *ngIf="form.controls.tilDato.errors.bsDate && form.controls.tilDato.errors.bsDate.minDate" class="text-danger">
          Last sick day must be be equal to or greater than last sick day
        </div>
      </div>
    </div>

    <div class="d-none" #uploadLabel>
      Submit documentation of illness (e.g. medical records or statement from your doctor).
    </div>

    <div class="d-none" #uploadAriaLabelTilfoejFiler>
      Add more files with documentation
    </div>
    <div class="d-none" #uploadAriaLabelFil>
      Upload file with documentation
    </div>
    <div class="d-none" #uploadManglerTekst>
      Documentation for illness is missing
    </div>
    <app-filer
      class="col-12 py-3"
      [form]="form.controls.filer"
      [accept]="'.pdf'"
      [idPrefix]="'sygdom'"
      [ariaLabelTilfoejFiler]="uploadAriaLabelTilfoejFiler.innerText"
      [ariaLabelFil]="uploadAriaLabelFil.innerText"
      [filManglerTekst]="uploadManglerTekst.innerText"
      [label]="uploadLabel.innerText"
      (keydown.enter)="$event.preventDefault()"
      [fjern]="'Remove'"
    >
    </app-filer>

    <div class="col-12">
      <label for="bemaerkninger">
        <span class="visually-hidden">Required</span>* Describe how your illness is affecting your ability to work.
      </label>
      <span class="float-end form-text text-muted"><small>{{ bemaerkningerTaeller$ | async }}</small></span>
      <input type="text" id="bemaerkninger" maxlength="1200" class="form-control" formControlName="bemaerkninger" />
    </div>
    <div *ngIf="form.controls.bemaerkninger.touched && form.controls.bemaerkninger.errors" role="alert" aria-atomic="true">
      <div *ngIf="form.controls.bemaerkninger.errors.required" class="col-12 text-danger">
        Description of your illness is missing
      </div>
    </div>

    <app-upload-gdpr class="col-12" [form]="form"></app-upload-gdpr>

    <div class="col-12 pt-3">
      <button (click)="sendAnsoegning()" type="submit" [disabled]="form.invalid || senderAnsoegning" class="btn btn-primary btn-lg d-block w-100">
        Submit documentation
      </button>
    </div>
    <div *ngIf="senderAnsoegning" class="col-12 pt-3 text-center">
      <fa-icon [icon]="faSpinner" [spin]="true" [size]="'2x'" [pulse]="true"></fa-icon>
    </div>
    <div *ngIf="senderAnsoegning" class="col-12 pt-3 text-center" role="alert">
      Submitting documentation (may take several minutes)
    </div>
  </div>
</form>
