<div class="row">

  <div>{{ meddelelse }}</div>

  <div class="col-12 d-none">
    <form method="POST" id="loginForm" #loginForm action="{{actionUrl}}">
      <input type="text" name="cpr" value="{{cpr}}">
      <input type="text" name="timestamp" value="{{timestamp}}">
      <input type="text" name="signature" value="{{signature}}">
    </form>
  </div>
  
</div>
