import { Component } from '@angular/core';

import { UfmUserTrackingService } from './ufm-user-tracking.service';

@Component({
  selector: 'ufm-user-tracking',
  templateUrl: './ufm-user-tracking.component.html',
  styleUrls: ['./ufm-user-tracking.component.scss']
})
export class UfmUserTrackingComponent {

  constructor(private ufmUserTrackingService: UfmUserTrackingService) { }

  brug(): string {
    return this.ufmUserTrackingService.brug();
  }

}
