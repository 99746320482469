<div class="py-5 mt-5 border-top bg-light">
  <footer class="container">
    <div class="text-muted">
      <img
        src="assets/img/ufm_brand_35.svg"
        width="19"
        height="19"
        class="d-inline-block align-top"
        alt="Min.su.dk - selvbetjening og information"
        title="Min.su.dk - selvbetjening og information"
        i18n-alt="@@bund logo title"
        i18n-title="@@bund logo title"
      />
      <span i18n="@@bund styrelse">Uddannelses- og Forskningsstyrelsen.</span>
    </div>
    <app-dan-pdf-filer></app-dan-pdf-filer>
  </footer>
</div>