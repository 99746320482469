<div class="row"  >
  <div class="col-12 pt-2">
    <strong class="ufm-black">You have stated the following holiday period/periods</strong>
  </div>
  <div *ngFor="let periode of perioder" class="pt-2 col-12 ufm-black">
        <span *ngIf="periode.fradato || periode.tildato">
          <span>From: </span> {{ periode.fradato | langdato }}
          <span *ngIf="periode.tildato">to: </span> {{ periode.tildato | langdato:true }}
        </span>
  </div>
</div>
