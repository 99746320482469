import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { map, mergeMap, tap } from 'rxjs/operators';

import { environment } from '../../../environments/environment';
import { UploadService } from './upload.service';
import { UfmFuldmagtService } from '@ufmit/ufm-fuldmagt';

@Injectable({
  providedIn: 'root'
})
export class UploadGuard  {

  constructor(private router: Router, private uploadservice: UploadService, private ufmFuldmagtService: UfmFuldmagtService) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.ufmFuldmagtService.getCachedFuldmagtsBruger(environment.fuldmagtsprivilege)
      .pipe(
        map((fuldmagtsBruger) => {
          if (fuldmagtsBruger && fuldmagtsBruger.agerendeCpr) {
            return true;
          } else {
            return false;
          }
        }),
        mergeMap(() => this.uploadservice.hentProfil()),
        map((uploadprofil) => {
          if (uploadprofil && uploadprofil.ligestillingOK === 'J') {
            return true;
          } else {
            this.router.navigate(['/upload/upload-ikke-tilladt']);
            return false;
          }
        })
      );
  }
}
