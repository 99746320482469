<div *ngIf="dannerNytArbejdeKvittering$ | async" class="row">
    <div class="col-12 pt-5"></div>
    <div class="col-12 pt-5"></div>
    <div class="col-12 pt-5"></div>
    <div class="col-12 pt-5"></div>
    <div class="col-12 pt-5"></div>
    <div class="col-12 pt-5"></div>
    <div class="col-12 pt-5"></div>
    <div class="col-12 pt-5"></div>
    <div class="col-12 pt-5"></div>
    <app-nyt-arbejde-kvittering [pdf]="true" (pdfErKlar$)="nytArbejdeKvitteringErklar($event)" class="col-12"></app-nyt-arbejde-kvittering>
</div>

<div *ngIf="dannerStopArbejdeKvittering$ | async" class="row">
    <div class="col-12 pt-5"></div>
    <div class="col-12 pt-5"></div>
    <div class="col-12 pt-5"></div>
    <div class="col-12 pt-5"></div>
    <div class="col-12 pt-5"></div>
    <div class="col-12 pt-5"></div>
    <div class="col-12 pt-5"></div>
    <div class="col-12 pt-5"></div>
    <div class="col-12 pt-5"></div>
    <app-stop-arbejde-kvittering [pdf]="true" (pdfErKlar$)="stopArbejdeKvitteringErklar($event)" class="col-12"></app-stop-arbejde-kvittering>
</div>

<div *ngIf="dannerAfskedigelseKvittering$ | async" class="row">
  <div class="col-12 pt-5"></div>
  <div class="col-12 pt-5"></div>
  <div class="col-12 pt-5"></div>
  <div class="col-12 pt-5"></div>
  <div class="col-12 pt-5"></div>
  <div class="col-12 pt-5"></div>
  <div class="col-12 pt-5"></div>
  <div class="col-12 pt-5"></div>
  <div class="col-12 pt-5"></div>
  <app-afskedigelse-kvittering [pdf]="true" (pdfErKlar$)="afskedigelseKvitteringErklar($event)" class="col-12"></app-afskedigelse-kvittering>
</div>

<div *ngIf="dannerUarbejdsdygtigKvittering$ | async" class="row">
  <div class="col-12 pt-5"></div>
  <div class="col-12 pt-5"></div>
  <div class="col-12 pt-5"></div>
  <div class="col-12 pt-5"></div>
  <div class="col-12 pt-5"></div>
  <div class="col-12 pt-5"></div>
  <div class="col-12 pt-5"></div>
  <div class="col-12 pt-5"></div>
  <div class="col-12 pt-5"></div>
  <app-uarbejdsdygtig-kvittering [pdf]="true" (pdfErKlar$)="uarbejdsdygtigKvitteringErklar($event)" class="col-12"></app-uarbejdsdygtig-kvittering>
</div>

<div *ngIf="dannerOevrigeHenvendelserKvittering$ | async" class="row">
  <div class="col-12 pt-5"></div>
  <div class="col-12 pt-5"></div>
  <div class="col-12 pt-5"></div>
  <div class="col-12 pt-5"></div>
  <div class="col-12 pt-5"></div>
  <div class="col-12 pt-5"></div>
  <div class="col-12 pt-5"></div>
  <div class="col-12 pt-5"></div>
  <div class="col-12 pt-5"></div>
  <app-oevrige-henvendelser-kvittering [pdf]="true" (pdfErKlar$)="oevrigeHenvendelserKvitteringErklar($event)" class="col-12"></app-oevrige-henvendelser-kvittering>
</div>

<div *ngIf="dannerFerieKvittering$ | async" class="row">
  <div class="col-12 pt-5"></div>
  <div class="col-12 pt-5"></div>
  <div class="col-12 pt-5"></div>
  <div class="col-12 pt-5"></div>
  <div class="col-12 pt-5"></div>
  <div class="col-12 pt-5"></div>
  <div class="col-12 pt-5"></div>
  <div class="col-12 pt-5"></div>
  <div class="col-12 pt-5"></div>
  <app-ferie-kvittering [pdf]="true" (pdfErKlar$)="ferieKvitteringErklar($event)" class="col-12"></app-ferie-kvittering>
</div>
