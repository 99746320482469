import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { UfmBrowsercacheConfigService } from './ufm-browsercache-config.service';
import { UfmBrowsercache } from './ufm-browsercache';

@Injectable({
  providedIn: 'root'
})
export class UfmBrowsercacheService {

  constructor(private http: HttpClient, private ufmBrowsercacheConfigService: UfmBrowsercacheConfigService) { }

  public hentBrowsercache(): Observable<UfmBrowsercache> {
    const url: string = this.ufmBrowsercacheConfigService.getBrowsercacheRequestUrl();
    const headers: { [key: string]: string } = { 'NoInterceptorAgerendeCpr': 'x' };
    return this.http.get<UfmBrowsercache>(url, { headers: headers });
  }
}
