import {Component, Input, OnInit} from '@angular/core';
import {Periode} from '../../../../utils/perioder/periode/periode';

@Component({
  selector: 'app-ferie-kvittering-perioder',
  templateUrl: './ferie-kvittering-perioder.component.html',
  styleUrls: ['./ferie-kvittering-perioder.component.scss']
})
export class FerieKvitteringPerioderComponent implements OnInit {

  @Input() perioder: Periode[];

  constructor() { }

  ngOnInit() {}

}
