<form [formGroup]="form">
  <div class="row">
    <div class="col-12 h3" role="heading" aria-level="2">
      Other changes to your circumstances as an EU worker
    </div>

    <div class="col-12 pt-3" role="heading" aria-level="3">
      <div class="row">
        <div class="col-12">
          If there are other changes to your circumstances as an EU worker (e.g. you go on maternity leave or start an
          internship), you can state it here and/or submit any documentation.
        </div>
        <div class="col-12 pt-1">
          <a href="https://su.dk/10964" target="_blank">You can read more about the rules on su.dk.</a>
        </div>
      </div>
    </div>

    <div class="d-none" #uploadLabel>
      Submit any documentation you may have
    </div>
    <div class="d-none" #uploadAriaLabelTilfoejFiler>
      Add more files with documentation
    </div>
    <div class="d-none" #uploadAriaLabelFil>
      Upload file with documentation
    </div>
    <app-filer
      class="col-12 py-3"
      [form]="form.controls.filer"
      [accept]="'.pdf'"
      [idPrefix]="'oevrigehenvendelser'"
      [ariaLabelTilfoejFiler]="uploadAriaLabelTilfoejFiler.innerText"
      [ariaLabelFil]="uploadAriaLabelFil.innerText"
      [label]="uploadLabel.innerText"
      (keydown.enter)="$event.preventDefault()"
      [fjern]="'Remove'"
    >
    </app-filer>

    <div class="col-12">
      <label for="bemaerkninger">
        You can provide supplemental information here.
      </label>
      <span class="float-end form-text text-muted"><small>{{ bemaerkningerTaeller$ | async }}</small></span>
      <input type="text" id="bemaerkninger" maxlength="1200" class="form-control" formControlName="bemaerkninger" />
    </div>
    <div
      *ngIf="form.errors && form.errors.filerEllerbemaerkninger && form.controls.filer.touched && form.controls.bemaerkninger.touched"
      class="col-12 text-danger">
      Documentation is missing
    </div>

    <app-upload-gdpr class="col-12" [form]="form"></app-upload-gdpr>

    <div class="col-12 pt-3">
      <button (click)="sendAnsoegning()" type="submit" [disabled]="form.invalid || senderAnsoegning" class="btn btn-primary btn-lg d-block w-100">
        Submit documentation
      </button>
    </div>
    <div *ngIf="senderAnsoegning" class="col-12 pt-3 text-center">
      <fa-icon [icon]="faSpinner" [spin]="true" [size]="'2x'" [pulse]="true"></fa-icon>
    </div>
    <div *ngIf="senderAnsoegning" class="col-12 pt-3 text-center" role="alert">
      Submitting documentation (may take several minutes)
    </div>
  </div>
</form>
