import {Injectable} from '@angular/core';
import {UntypedFormGroup, ValidatorFn, UntypedFormControl, UntypedFormBuilder, UntypedFormArray, AbstractControl, Validators} from '@angular/forms';
import {HttpClient} from '@angular/common/http';
import {Observable, of} from 'rxjs';
import {CookieService} from 'ngx-cookie-service';
import {ToastrService} from 'ngx-toastr';
import {map, tap} from 'rxjs/operators';

import {ApiResponse} from './api-response';
import {AppService} from '../../../app.service';
import {DioConnectAlive} from './dio-connect-alive';
import {environment} from '../../../../environments/environment';
import {Fil} from '../filer/fil/fil';
import {UploadProfil} from '../../upload/upload-profil';
import {UploadService} from '../../upload/upload.service';

export const ValiderMindstEnFil: ValidatorFn = (fa: UntypedFormArray) => {
  if (!fa) {
    return null;
  }
  for (let i = 0; i < fa.length; i++) {
    const fil: UntypedFormGroup = fa.at(i) as UntypedFormGroup;
    if (fil.controls.filnavn.value) {
      return null;
    }
  }
  return {required: true};
};

@Injectable({
  providedIn: 'root'
})
export class SendAnsoegningService {

  private ansoegningJson: any;
  private ansoegningFiler: Fil[];
  private pdfKvittering: Blob;
  private pdfAnsoegning360: Blob;
  private apiResponse: ApiResponse;
  private uploadProfil: UploadProfil;

  constructor(
    private appService: AppService,
    private cookieService: CookieService,
    private formBuilder: UntypedFormBuilder,
    private http: HttpClient,
    private toastrService: ToastrService,
    private uploadService: UploadService
  ) {}

  nyFil(gruppe: string): UntypedFormGroup {
    return this.formBuilder.group({
      gruppe: [gruppe, Validators.required],
      fil: [null],
      filnavn: [null],
      data: [null]
    });
  }

  nyRequiredFil(gruppe: string): UntypedFormGroup {
    return this.formBuilder.group({
      gruppe: [gruppe, Validators.required],
      fil: [null, Validators.required],
      filnavn: [null, Validators.required],
      data: [null, Validators.required]
    });
  }

  initialiserFormData(form: UntypedFormGroup, upload: boolean): FormData {
    this.disableFormGroup(form);
    this.ansoegningJson = this.findAnsoegningJsonUdenFiler(this.formGroupValue(form));
    const vedhaeftedeFiler: string[] = [];
    const uploadprofil: Observable<UploadProfil> = this.hentProfil();
    this.ansoegningJson.client_info = {
      _ga: this.cookieService.get('_ga'),
      user_agent: window.navigator.userAgent,
      profil: uploadprofil,
      vedhaeftedeFiler
    };

    this.ansoegningFiler = [];
    let formDataSize = 0;
    this.findAnsoegningFiler(this.formGroupValue(form), this.ansoegningFiler);
    for (const fil of this.ansoegningFiler) {
      vedhaeftedeFiler.push(fil.filnavn);
    }
    const formData: FormData = new FormData();
    const ansoegningBlob: Blob = new Blob([JSON.stringify(this.ansoegningJson)], {type: 'application/json'});
    if (environment.test) {
      const saveData = () => {
        const a: HTMLAnchorElement = document.createElement('a');
        document.body.appendChild(a);
        return () => {
          const url = window.URL.createObjectURL(ansoegningBlob);
          a.href = url;
          a.download = 'ansoegning.blob';
          a.click();
          window.URL.revokeObjectURL(url);
        };
      };
      saveData();
    }
    formData.append('ansoegning', ansoegningBlob);
    formDataSize += ansoegningBlob.size + 'ansoegning'.length + 20;
    for (const fil of this.ansoegningFiler) {
      formData.append(fil.parameternavn, fil.data, fil.filnavn);
      formDataSize += fil.parameternavn.length + fil.data.size + fil.filnavn.length + 20;
    }
    if (formDataSize > environment.maxFilUploadBytes) {
      const fejlTekst: string = this.appService.hentMaxStoerrelse();
      const fejl: string = this.appService.hentFejlTekst();
      this.toastrService.error(fejlTekst, fejl, {tapToDismiss: true, disableTimeOut: true, closeButton: true});
      this.enableFormGroup(form);
      this.ansoegningJson = null;
      this.ansoegningFiler = [];
      return null;
    } else {
      return formData;
    }
  }

  erDioKlar(): Observable<boolean> {
    return this.http
      .get<DioConnectAlive>(environment.upApi + '/dio-connect-alive')
      .pipe(map((json) => json && json.dioConectAlive));
  }

  sendAnsoegning(formData: FormData): Observable<ApiResponse> {
    formData.append('feKvittering', this.pdfKvittering, 'Kvittering.pdf');
    return this.http
      .post<ApiResponse>(environment.upApi + '/upload', formData, {headers: {NoInterceptorTimeout: 'x'}})
      .pipe(tap((apiResponse) => (this.apiResponse = apiResponse)));
  }

  public formGroupValue(form: UntypedFormGroup): any {
    const json: any = {};
    for (const key in form.controls) {
      if (form.controls.hasOwnProperty(key)) {
        const control: AbstractControl = form.controls[key];
        json[key] = this.abstractControlvalue(control);
      }
    }
    return json;
  }

  private formArrayValue(form: UntypedFormArray): any {
    const json: any = [];
    for (let i = 0; i < form.length; i++) {
      const control: AbstractControl = form.at(i);
      json.push(this.abstractControlvalue(control));
    }
    return json;
  }

  private abstractControlvalue(control: AbstractControl): any {
    if (control instanceof UntypedFormGroup) {
      return this.formGroupValue(control);
    } else if (control instanceof UntypedFormArray) {
      return this.formArrayValue(control);
    } else {
      return control.value;
    }
  }

  private disableFormGroup(form: UntypedFormGroup): void {
    for (const key in form.controls) {
      if (form.controls.hasOwnProperty(key)) {
        const control: AbstractControl = form.controls[key];
        if (control instanceof UntypedFormArray) {
          this.disableFormArray(control);
        } else if (control instanceof UntypedFormGroup) {
          this.disableFormGroup(control);
        } else if (control instanceof UntypedFormControl) {
          control.disable();
        }
      }
    }
  }

  private disableFormArray(formarray: UntypedFormArray): void {
    for (let i = 0; i < formarray.length; i++) {
      const control: UntypedFormGroup = formarray.at(i) as UntypedFormGroup;
      this.disableFormGroup(control);
    }
  }

  public enableFormGroup(form: UntypedFormGroup): void {
    for (const key in form.controls) {
      if (form.controls.hasOwnProperty(key)) {
        const control: AbstractControl = form.controls[key];
        if (control instanceof UntypedFormArray) {
          this.enableFormArray(control);
        } else if (control instanceof UntypedFormGroup) {
          this.enableFormGroup(control);
        } else if (control instanceof UntypedFormControl) {
          control.enable();
        }
      }
    }
  }

  private enableFormArray(formarray: UntypedFormArray): void {
    for (let i = 0; i < formarray.length; i++) {
      const control: UntypedFormGroup = formarray.at(i) as UntypedFormGroup;
      this.enableFormGroup(control);
    }
  }

  private findAnsoegningJsonUdenFiler(ansoegningJson: any): any {
    if (!ansoegningJson) {
      return;
    } else if (ansoegningJson instanceof Array) {
      for (let i = ansoegningJson.length - 1; i >= 0; i--) {
        const ansoegningJsonRow: any = ansoegningJson[i];
        if (ansoegningJsonRow.hasOwnProperty('gruppe') && ansoegningJsonRow.hasOwnProperty('filnavn') && ansoegningJsonRow.hasOwnProperty('data')) {
          // FormGroup Fil
          ansoegningJson.splice(i, 1);
        } else {
          // Anden FormGroup
          this.findAnsoegningJsonUdenFiler(ansoegningJsonRow);
        }
      }
      const svar: any = ansoegningJson.length ? ansoegningJson : null;
      return svar;
    } else if (ansoegningJson instanceof Date) {
      return ansoegningJson;
    } else if (ansoegningJson instanceof Object) {
      // andre FormGroups
      const nyAnsoegningJson: any = {};
      for (const key in ansoegningJson) {
        if (ansoegningJson.hasOwnProperty(key)) {
          const nyValue: any = this.findAnsoegningJsonUdenFiler(ansoegningJson[key]);
          nyAnsoegningJson[key] = nyValue;
        }
      }
      const svar: any = Object.keys(nyAnsoegningJson).length ? nyAnsoegningJson : null;
      return svar;
    } else {
      return ansoegningJson;
    }
  }

  public findAnsoegningFiler(ansoegningJson: any, filer: Fil[]): void {
    if (!ansoegningJson) {
      // tomme property
      return;
    } else if (ansoegningJson instanceof Array) {
      // FormArray
      for (let i = ansoegningJson.length - 1; i >= 0; i--) {
        this.findAnsoegningFiler(ansoegningJson[i], filer);
      }
      return;
    } else if (ansoegningJson.hasOwnProperty('gruppe') && ansoegningJson.hasOwnProperty('filnavn') && ansoegningJson.hasOwnProperty('data')) {
      // FormGroup Fil
      if (ansoegningJson.gruppe && ansoegningJson.filnavn && ansoegningJson.data) {
        filer.push({parameternavn: ansoegningJson.gruppe, filnavn: ansoegningJson.filnavn, data: ansoegningJson.data});
      }
      return;
    } else if (ansoegningJson instanceof Object) {
      // andre FormGroups
      for (const key in ansoegningJson) {
        if (ansoegningJson.hasOwnProperty(key)) {
          this.findAnsoegningFiler(ansoegningJson[key], filer);
        }
      }
    }
  }

  hentSendtAnsoegning(): any {
    if (this.ansoegningJson && this.ansoegningFiler) {
      return [this.ansoegningJson, this.ansoegningFiler];
    }
  }

  gemPdfKvittering(pdf: Blob) {
    this.pdfKvittering = pdf;
  }

  setCookie(name: string, value: string, dato?: Date | number) {
    if (!dato) {
      dato = 0;
    }
    this.cookieService.set(name, value, dato, '/');
  }

  getCookie(name: string): string {
    return this.cookieService.get(name);
  }

  deleteCookie(name: string): void {
    this.cookieService.delete(name, '/');
  }

  ValiderPDF(file: File) {
    const formData = new FormData();
    formData.append('dokument', file);
    const headers = {'Accept-Language': 'en'};
    return this.http.post(environment.upApi + '/validatepdf', formData, {headers});
  }

  hentProfil(): Observable<UploadProfil> {
    if (this.uploadProfil == null) {
      return this.http
        .get<UploadProfil>(environment.upApi + '/upload?feVersion=' + environment.version)
        .pipe(tap((uploadProfil) => (this.uploadProfil = uploadProfil)));
    } else {
      return of(this.hentUploadProfil);
    }
  }

  get hentUploadProfil(): UploadProfil {
    return this.uploadProfil;
  }

}
