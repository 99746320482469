import {NgModule} from '@angular/core';
import {RouterModule, Routes, Router, NavigationEnd} from '@angular/router';
import {AfskedigelseComponent} from './forretnings-sider/upload/afskedigelse/afskedigelse/afskedigelse.component';
import {AfskedigelseKvitteringComponent} from './forretnings-sider/upload/afskedigelse/kvittering/afskedigelse-kvittering.component';
import {OevrigeHenvendelserComponent} from './forretnings-sider/upload/oevrige-henvendelser/oevrige-henvendelser/oevrige-henvendelser.component';
import {OevrigeHenvendelserKvitteringComponent} from './forretnings-sider/upload/oevrige-henvendelser/kvittering/oevrige-henvendelser-kvittering.component';
import {FuldmagtGuard} from './forretnings-sider/fuldmagt/fuldmagt.guard';
import {LoggetPaaGuard} from './shared/api-session/logget-paa.guard';
import {NytArbejdeComponent} from './forretnings-sider/upload/nyt-arbejde/nyt-arbejde/nyt-arbejde.component';
import {NytArbejdeKvitteringComponent} from './forretnings-sider/upload/nyt-arbejde/kvittering/nyt-arbejde-kvittering.component';
import {StopArbejdeKvitteringComponent} from './forretnings-sider/upload/stop-arbejde/kvittering/stop-arbejde-kvittering.component';
import {StopArbejdeComponent} from './forretnings-sider/upload/stop-arbejde/stop-arbejde/stop-arbejde.component';
import {UarbejdsdygtigComponent} from './forretnings-sider/upload/uarbejdsdygtig/uarbejdsdygtig/uarbejdsdygtig.component';
// tslint:disable-next-line: max-line-length
import {UarbejdsdygtigKvitteringComponent} from './forretnings-sider/upload/uarbejdsdygtig/kvittering/uarbejdsdygtig-kvittering.component';
import {UploadForsideComponent} from './forretnings-sider/upload/upload-forside/upload-forside.component';
import {UploadGuard} from './forretnings-sider/upload/upload.guard';
import {UploadIkkeTilladtComponent} from './forretnings-sider/upload/upload-ikke-tilladt/upload-ikke-tilladt.component';
import {UfmSessionLogafComponent, UfmSessionTimeoutComponent, UfmSessionDemoAuthComponent} from '@ufmit/ufm-session';
import {UfmUserTrackingService} from '@ufmit/ufm-user-tracking';
import {UfmVisBrowsercacheComponent} from '@ufmit/ufm-browsercache';
import {FerieKvitteringComponent} from './forretnings-sider/upload/ferie/kvittering/ferie-kvittering.component';
import {FerieComponent} from './forretnings-sider/upload/ferie/ferie/ferie.component';
import {KvitteringGuard} from './forretnings-sider/utils/kvittering/kvittering.guard';

const routes: Routes = [
  {path: 'clear-cache', component: UfmVisBrowsercacheComponent},
  {path: 'demo-auth', component: UfmSessionDemoAuthComponent},
  {path: 'logaf', component: UfmSessionLogafComponent},
  {path: 'timeout', component: UfmSessionTimeoutComponent},
  {path: '', component: UploadForsideComponent, canActivate: [LoggetPaaGuard, FuldmagtGuard, UploadGuard]},
  {path: 'upload/nyt-arbejde', component: NytArbejdeComponent, canActivate: [LoggetPaaGuard, FuldmagtGuard, UploadGuard]},
  {path: 'upload/stop-arbejde', component: StopArbejdeComponent, canActivate: [LoggetPaaGuard, FuldmagtGuard, UploadGuard]},
  {path: 'upload/afskedigelse', component: AfskedigelseComponent, canActivate: [LoggetPaaGuard, FuldmagtGuard, UploadGuard]},
  {path: 'upload/uarbejdsdygtig', component: UarbejdsdygtigComponent, canActivate: [LoggetPaaGuard, FuldmagtGuard, UploadGuard]},
  {
    path: 'upload/oevrige-henvendelser',
    component: OevrigeHenvendelserComponent,
    canActivate: [LoggetPaaGuard, UploadGuard]
  },
  {
    path: 'upload/ferie',
    component: FerieComponent,
    canActivate: [LoggetPaaGuard, UploadGuard]
  },
  {path: 'upload/upload-ikke-tilladt', component: UploadIkkeTilladtComponent, canActivate: [LoggetPaaGuard]},
  {
    path: 'upload/kvittering/nyt-arbejde',
    component: NytArbejdeKvitteringComponent,
    canActivate: [LoggetPaaGuard, FuldmagtGuard, UploadGuard, KvitteringGuard]
  },
  {
    path: 'upload/kvittering/stop-arbejde',
    component: StopArbejdeKvitteringComponent,
    canActivate: [LoggetPaaGuard, FuldmagtGuard, UploadGuard, KvitteringGuard]
  },
  {
    path: 'upload/kvittering/afskedigelse',
    component: AfskedigelseKvitteringComponent,
    canActivate: [LoggetPaaGuard, FuldmagtGuard, UploadGuard, KvitteringGuard]
  },
  {
    path: 'upload/kvittering/uarbejdsdygtig',
    component: UarbejdsdygtigKvitteringComponent,
    canActivate: [LoggetPaaGuard, FuldmagtGuard, UploadGuard, KvitteringGuard]
  },
  {
    path: 'upload/kvittering/oevrige-henvendelser',
    component: OevrigeHenvendelserKvitteringComponent,
    canActivate: [LoggetPaaGuard, UploadGuard, KvitteringGuard]
  },
  {
    path: 'upload/kvittering/ferie',
    component: FerieKvitteringComponent,
    canActivate: [LoggetPaaGuard, UploadGuard, KvitteringGuard]
  },
  {path: '**', redirectTo: '/'}
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {})
  ],
  exports: [
    RouterModule
  ]
})
export class AppRoutingModule {
  constructor(private router: Router, private ufmUserTrackingService: UfmUserTrackingService) {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        const browserRefresh: boolean = event.id === 1 && event.url === event.urlAfterRedirects;
        if (['/clear-cache', '/timeout'].indexOf(event.url) > -1 && browserRefresh) {
          // retur til forside ved reload
          router.navigate(['/']);
          return;
        }
        this.ufmUserTrackingService.sendUrl(event.urlAfterRedirects);
      }
    });
  }
}
