import { Injectable, Injector, Inject } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { UfmBrowsercacheConfig, UfmBrowsercacheConfigTekster, UfmBrowsercacheConfigVisTekster, UFM_BROWSERCACHE_CONFIG } from './ufm-browsercache.config';
import { UFM_PROPERTIES_CONFIG, UfmPropertiesConfig } from '@ufmit/ufm-properties';

@Injectable({
  providedIn: 'root'
})
export class UfmBrowsercacheConfigService {

  public browsercacheKonfigurationOpdateret$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private ufmBrowsercacheConfig: UfmBrowsercacheConfig = {};
  private ufmPropertiesConfig: UfmPropertiesConfig = {};

  constructor(
    @Inject(Injector) private injector: Injector) {
    // hent ufmBrowsercacheConfig (apiPropertyNavn)
    try {
      this.ufmBrowsercacheConfig = this.injector.get(UFM_BROWSERCACHE_CONFIG);
    } catch (e) { 
      console.error('UfmBrowsercacheConfigService. Kan ikke loade UFM_BROWSERCACHE_CONFIG. error=', e);
    }
    // hent ufmPropertiesConfig (browsercacheRequestUrl, sessionRequestUrl, frontendClientLogRequestUrl)
    try {
      this.ufmPropertiesConfig = this.injector.get(UFM_PROPERTIES_CONFIG);
    } catch (e) { 
      console.error('UfmBrowsercacheConfigService. Kan ikke loade UFM_PROPERTIES_CONFIG. error=', e);
    }
  }

  // dynamisk config getters
  public getApiPropertyNavn(): string {
    if (this.ufmBrowsercacheConfig.environment && this.ufmBrowsercacheConfig.environment.apiPropertyNavn) {
      return this.ufmBrowsercacheConfig.environment.apiPropertyNavn;
    } else {
      return 'api';
    }
  }
  public getApi(): string {
    const apiPropertyNavn = this.getApiPropertyNavn();
    const environment: any = this.ufmPropertiesConfig.environment || {};
    const api: string = environment[apiPropertyNavn] ? environment[apiPropertyNavn] : '/api';
    return api;
  }
  public getEndpoint(): string {
    if (this.ufmBrowsercacheConfig.environment && this.ufmBrowsercacheConfig.environment.endpoint) {
      return this.ufmBrowsercacheConfig.environment.endpoint;
    } else {
      return '/browsercache';
    }
  }
  public getRequestParams(): string {
    const environment: any = this.ufmPropertiesConfig.environment || {};
    if (environment.version) {
      return '?feVersion=' + environment.version;
    } else {
      return '';
    }
  }
  public getVersion(): string {
    const environment: any = this.ufmPropertiesConfig.environment || {};
    return environment.version;
  }
  public getBrowsercacheRequestUrl(): string {
    const endpoint = this.getEndpoint();
    const profilRequestUrl: string = this.getApi() + endpoint + this.getRequestParams();
    return profilRequestUrl;
  }
  public getFrontendClientLogRequestUrl(): string {
    const frontendClientLogRequestUrl: string = this.getApi() + '/frontend-client-log';
    return frontendClientLogRequestUrl;
  }

  public genindlaesOpdateretKonfiguration(): void {
    this.browsercacheKonfigurationOpdateret$.next(true);
  }

  private getBrowsercacheConfigVisTekster(): UfmBrowsercacheConfigVisTekster {
    if (this.ufmBrowsercacheConfig.tekster && this.ufmBrowsercacheConfig.tekster.visBrowsercache) {
      return this.ufmBrowsercacheConfig.tekster.visBrowsercache;
    } else {
      return {};
    }
  }

  private getTjekBrowsercacheBesked(): string {
    if (this.ufmBrowsercacheConfig.tekster && this.ufmBrowsercacheConfig.tekster.tjekBrowsercacheBesked) {
      return this.ufmBrowsercacheConfig.tekster.tjekBrowsercacheBesked;
    } else {
      return '';
    }
  }

  private getBrowsercacheConfigTekster(): UfmBrowsercacheConfigTekster {
    return {
      tjekBrowsercacheBesked: this.getTjekBrowsercacheBesked(),
      visBrowsercache: this.getBrowsercacheConfigVisTekster()
    };
  }

  public getBrowsercacheConfig(): UfmBrowsercacheConfig {
    return {
      environment: {
        apiPropertyNavn: this.getApiPropertyNavn(),
        endpoint: this.getEndpoint()
      },
      tekster: this.getBrowsercacheConfigTekster()
    };
  }

}
