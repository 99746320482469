<div class="container alert alert-warning mt-3">
    <p #htmlTekst1 i18n="@@ufmit fuldmagt mangler tekst 1">
        Du er logget på med et medarbejdercertifikat uden at have fået tildelt fuldmagts privilegier og kan derfor ikke
        foretage ændringer; hverken for dig selv eller på vegne af andre.
      </p>
    <p #htmlTekst2 i18n="@@ufmit fuldmagt mangler tekst 2">
        Hvis du forventede at kunne handle under fuldmagt på vegne af en anden bruger, skal du kontakte denne bruger og få
        vedkommende til at sikre fuldmagtens gyldighed ved hjælp af NemLog-in.
      </p>
    <button (click)="logAf()" type="submit" class="btn btn-sm btn-primary" #htmlLogAfKnap i18n="@@ufmit fuldmagt mangler log af knap">
        Log af
    </button>
</div>