import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription, timer, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { UntypedFormGroup } from '@angular/forms';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

import { AppService } from '../../../../app.service';
import { DatoService } from '../../../../shared/dato/dato.service';
import { SendAnsoegningService } from '../../../utils/service/send-ansoegning.service';
import { UarbejdsdygtigService } from './uarbejdsdygtig.service';
import { UfmFejlhaandteringService } from '@ufmit/ufm-fejlhaandtering';
import { UfmUserTrackingService } from '@ufmit/ufm-user-tracking';

@Component({
  selector: 'app-uarbejdsdygtig',
  templateUrl: './uarbejdsdygtig.component.html',
  styleUrls: ['./uarbejdsdygtig.component.scss']
})
export class UarbejdsdygtigComponent implements OnInit, OnDestroy {

  form: UntypedFormGroup;
  private formWithEnvelope: UntypedFormGroup;
  bemaerkningerTaeller$: Observable<string>;
  maxFraDato: Date;
  minTilDato: Date;
  private formData: FormData;
  senderAnsoegning = false;
  faSpinner = faSpinner;
  fraDatovaeglerTilstand = 0;
  tilDatovaeglerTilstand = 0;
  private subscriptions: Subscription[] = [];

  constructor(
    private appService: AppService,
    private datoService: DatoService,
    private sendAnsoegningService: SendAnsoegningService,
    private router: Router,
    private toastrService: ToastrService,
    private uarbejdsdygtigService: UarbejdsdygtigService,
    private ufmFejlhaandteringService: UfmFejlhaandteringService,
    private ufmUserTrackingService: UfmUserTrackingService) { }

  ngOnInit() {
    this.formWithEnvelope = this.uarbejdsdygtigService.initialiserAnsoegningFormGroup();
    this.form = this.formWithEnvelope.controls.uarbejdsDygtig as UntypedFormGroup;
    this.bemaerkningerTaeller$ = this.form.controls.bemaerkninger.valueChanges
      .pipe(
        map((bemaerkninger) => {
          if (!bemaerkninger) {
            bemaerkninger = '';
          }
          return JSON.stringify(bemaerkninger.length) + '/1200';
        })
      );

    this.subscriptions.push(
      this.form.controls.fraDato.valueChanges.subscribe((tidspunkt) => {
        const dato: Date = this.datoService.konverterTilUTCDato(tidspunkt);
        this.minTilDato = dato;
        if (!(tidspunkt instanceof Date) || tidspunkt.getTime() !== dato.getTime()) {
          this.form.controls.fraDato.setValue(dato);
        }
      })
    );

    this.subscriptions.push(
      this.form.controls.tilDato.valueChanges.subscribe((tidspunkt) => {
        const dato: Date = this.datoService.konverterTilUTCDato(tidspunkt);
        this.maxFraDato = dato;
        if (!(tidspunkt instanceof Date) || tidspunkt.getTime() !== dato.getTime()) {
          this.form.controls.tilDato.setValue(dato);
        }
      })
    );
  }

  ngOnDestroy() {
    let s: Subscription;
    while (this.subscriptions.length) {
      s = this.subscriptions.shift();
      if (!s.closed) {
        s.unsubscribe();
      }
    }
  }

  fraDatoVaeglerSkjul(): void {
    this.fraDatovaeglerTilstand++;
  }

  fraDatoVaeglerVis(): void {
    this.fraDatovaeglerTilstand++;
  }

  tilDatoVaeglerSkjul(): void {
    this.tilDatovaeglerTilstand++;
  }

  tilDatoVaeglerVis(): void {
    this.tilDatovaeglerTilstand++;
  }

  sendAnsoegning(): void {
    this.senderAnsoegning = true;
    this.sendAnsoegningService.erDioKlar().subscribe((erDioKlar) => {
      if (erDioKlar) {
        console.log('DIO er klar. Sender ansøgning');
        this.dioErKlar();
      } else {
        this.senderAnsoegning = false;
        this.toastrService.error('We are currently unable to accept applications. Please try again in a few hours. Sorry for the inconvenience.', 'Error', { tapToDismiss: true, disableTimeOut: true, closeButton: true });
      }
    });
  }

  private dioErKlar(): void {
    this.formData = this.sendAnsoegningService.initialiserFormData(this.formWithEnvelope, true);
    if (!this.formData) {
      this.senderAnsoegning = false;
      return;
    }
    this.subscriptions.push(
      this.appService.uarbejdsdygtigKvitteringErKlar$.subscribe(() => {
        this.uarbejdsdygtigKvitteringPdfErklar();
      })
    );
    this.appService.danUarbejdsdygtigKvittering();
  }

  private uarbejdsdygtigKvitteringPdfErklar(): void {
    timer(0).subscribe(() => {
      this.sendAnsoegningService.sendAnsoegning(this.formData)
        .subscribe(
          (ok) => {
            this.senderAnsoegning = false;
            if (ok && ok.receiptId) {
              // alt OK. Ansøgning findes i US2000 og 360
              this.ufmUserTrackingService.sendUrl('upload/uarbejdsdygtig/ok');
              this.router.navigate(['upload/kvittering/uarbejdsdygtig']);
            } else {
              // fejl. OK svar fra POST, men ansøgning findes ikke i US2000 og 360
              this.sendAnsoegningService.enableFormGroup(this.form);
              this.ufmUserTrackingService.sendUrl('upload/uarbejdsdygtig/fejl/receipt-id-mangler');
              const msg: string =
                'Unfortunately, a technical error occurred and we could not receive the application. user agent = ' +
                window.navigator.userAgent;
              console.error(msg);
              this.ufmFejlhaandteringService.sendFejlTilAPI(msg);
              this.toastrService.error(msg, 'Error', { tapToDismiss: true, disableTimeOut: true, closeButton: true });
            }
          },
          (error) => {
            console.error(error);
            this.senderAnsoegning = false;
            this.sendAnsoegningService.enableFormGroup(this.form);
            this.ufmUserTrackingService.sendUrl('upload/uarbejdsdygtig/fejl');
          },
          () => {
            if (this.senderAnsoegning) {
              this.senderAnsoegning = false;
              this.sendAnsoegningService.enableFormGroup(this.form);
              this.ufmUserTrackingService.sendUrl('upload/uarbejdsdygtig/fejl');
            }
            this.senderAnsoegning = false;
          }
        );
    });
  }

}
