import {Component, Input, OnChanges, OnDestroy, OnInit} from '@angular/core';
import {UntypedFormArray, UntypedFormGroup} from '@angular/forms';
import {Subscription} from 'rxjs';
import {DatoService} from '../../../../shared/dato/dato.service';

@Component({
  selector: 'app-ferie-periode',
  templateUrl: './ferie-periode.component.html',
  styleUrls: ['./ferie-periode.component.scss']
})
export class FeriePeriodeComponent implements OnInit, OnChanges, OnDestroy {

  @Input() form: UntypedFormGroup;
  @Input() id: string;
  @Input() index: number;

  private subscriptions: Subscription[] = [];
  fradatoId: string;
  tildatoId: string;
  antalPerioder: number;
  hideButton: boolean = true;

  constructor(private datoService: DatoService) {}

  ngOnInit() {
    this.subscriptions.push(
      this.form.controls.fradato.valueChanges.subscribe((tidspunkt) => {
        if (tidspunkt && this.form.controls.fradato.valid) {
          const dato: Date = this.datoService.konverterTilUTCDato(tidspunkt);
          if (!(tidspunkt instanceof Date) || tidspunkt.getTime() && dato.getTime() && (tidspunkt.getTime() !== dato.getTime())) {
            this.form.controls.fradato.setValue(dato);
          }
        }
      })
    );

    this.subscriptions.push(
      this.form.controls.tildato.valueChanges.subscribe((tidspunkt) => {
        if (tidspunkt && this.form.controls.fradato.valid) {
          const dato: Date = this.datoService.konverterTilUTCDato(tidspunkt);
          if (!(tidspunkt instanceof Date) || tidspunkt.getTime() && dato.getTime() && (tidspunkt.getTime() !== dato.getTime())) {
            this.form.controls.tildato.setValue(dato);
          }
        }
      })
    );
  }

  ngOnChanges() {
    this.fradatoId = this.id + 'Fradato' + (this.index + 1);
    this.tildatoId = this.id + 'Tildato' + (this.index + 1);
    const perioder: UntypedFormArray = this.form.parent as UntypedFormArray;
    this.antalPerioder = perioder.length;
    if (this.antalPerioder > 1) {
      this.hideButton = false;
    }
  }

  ngOnDestroy() {
    let s: Subscription;
    while (this.subscriptions.length) {
      s = this.subscriptions.shift();
      s.unsubscribe();
    }
  }

  fjernPeriode(): void {
    const perioder: UntypedFormArray = this.form.parent as UntypedFormArray;
    perioder.removeAt(this.index);
  }
}
