import {Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {Observable, Subscription, timer} from 'rxjs';
import {map} from 'rxjs/operators';
import {Router} from '@angular/router';
import {faSpinner} from '@fortawesome/free-solid-svg-icons';
import {ToastrService} from 'ngx-toastr';
import {AppService} from '../../../../app.service';
import {DatoService} from '../../../../shared/dato/dato.service';
import {foldudAmination} from '../../../../shared/animationer/animationer';
import {SendAnsoegningService} from '../../../utils/service/send-ansoegning.service';
import {NytArbejdeService} from './nyt-arbejde.service';
import {UfmFejlhaandteringService} from '@ufmit/ufm-fejlhaandtering';
import {UfmUserTrackingService} from '@ufmit/ufm-user-tracking';

@Component({
  selector: 'app-nyt-arbejde',
  templateUrl: './nyt-arbejde.component.html',
  styleUrls: ['./nyt-arbejde.component.scss'],
  animations: [foldudAmination]
})
export class NytArbejdeComponent implements OnInit, OnDestroy {

  private formWithEnvelope: UntypedFormGroup;
  form: UntypedFormGroup;
  bemaerkningerTaeller$: Observable<string>;
  private formData: FormData;
  senderAnsoegning = false;
  faSpinner = faSpinner;
  datovaeglerTilstand = 0; // skjul rød besked "Date is missing" der vises kort når dato vælges
  private harStadigvaekTidligereArbejde: UntypedFormControl;
  private subscriptions: Subscription[] = [];
  tidligereArbejdeJaVis = false;
  startNytArbejde: Date;
  @ViewChild('startdato', {static: false}) private startdato: ElementRef;

  constructor(private appService: AppService,
              private datoService: DatoService,
              private sendAnsoegningService: SendAnsoegningService,
              private nytArbejdeService: NytArbejdeService,
              private router: Router,
              private toastrService: ToastrService,
              private ufmFejlhaandteringService: UfmFejlhaandteringService,
              private ufmUserTrackingService: UfmUserTrackingService) {}

  ngOnInit() {
    this.formWithEnvelope = this.nytArbejdeService.initialiserAnsoegningFormGroup();
    this.form = this.formWithEnvelope.controls.nytArbejde as UntypedFormGroup;
    const loenoplysningerTidligereArbejde = this.form.controls.loenoplysningerTidligereArbejde as UntypedFormGroup;
    this.harStadigvaekTidligereArbejde = loenoplysningerTidligereArbejde.controls.harStadigvaekTidligereArbejde as UntypedFormControl;
    this.form.addControl('harStadigvaekTidligereArbejde', this.harStadigvaekTidligereArbejde);
    this.bemaerkningerTaeller$ = this.form.controls.bemaerkninger.valueChanges
      .pipe(
        map((bemaerkninger) => {
          if (!bemaerkninger) {
            bemaerkninger = '';
          }
          return JSON.stringify(bemaerkninger.length) + '/1200';
        })
      );

    this.subscriptions.push(this.harStadigvaekTidligereArbejde.valueChanges.subscribe((value) => {
      if (value) {
        this.tidligereArbejdeJaVis = true;
      } else {
        this.tidligereArbejdeJaVis = false;
      }
      loenoplysningerTidligereArbejde.updateValueAndValidity();
    }));

    this.subscriptions.push(
      this.form.controls.startdato.valueChanges.subscribe((tidspunkt) => {
        const dato: Date = this.datoService.konverterTilUTCDato(tidspunkt);
        if (!(tidspunkt instanceof Date) || tidspunkt.getTime() !== dato.getTime()) {
          this.form.controls.startdato.setValue(dato);
        }
      })
    );
  }

  ngOnDestroy() {
    let s: Subscription;
    while (this.subscriptions.length) {
      s = this.subscriptions.shift();
      if (!s.closed) {
        s.unsubscribe();
      }
    }
  }

  datoVaeglerSkjul(): void {
    this.datovaeglerTilstand++;
  }

  datoVaeglerVis(): void {
    this.datovaeglerTilstand++;
  }

  startSendAnsoegning(): void {
    this.senderAnsoegning = true;
    this.sendAnsoegningService.erDioKlar().subscribe((erDioKlar) => {
      if (erDioKlar) {
        console.log('DIO er klar. Sender ansøgning');
        this.dioErKlar();
      } else {
        this.senderAnsoegning = false;
        this.toastrService.error('We are currently unable to accept applications. Please try again in a few hours. Sorry for the inconvenience.', 'Error', {
          tapToDismiss: true,
          disableTimeOut: true,
          closeButton: true
        });
      }
    });
  }

  private dioErKlar(): void {
    this.formData = this.sendAnsoegningService.initialiserFormData(this.formWithEnvelope, true);
    if (!this.formData) {
      this.senderAnsoegning = false;
      return;
    }
    this.subscriptions.push(
      this.appService.nytArbejdeKvitteringErKlar$.subscribe(() => {
        this.nytArbejdeKvitteringPdfErklar();
      })
    );
    this.appService.danNytArbejdeKvittering();
  }

  private nytArbejdeKvitteringPdfErklar(): void {
    timer(0).subscribe(() => {
      this.sendAnsoegningService.sendAnsoegning(this.formData)
        .subscribe(
          (ok) => {
            this.senderAnsoegning = false;
            if (ok && ok.receiptId) {
              // alt OK. Ansøgning findes i US2000 og 360
              this.ufmUserTrackingService.sendUrl('upload/nyt-arbejde/ok');
              this.router.navigate(['/upload/kvittering/nyt-arbejde']);
            } else {
              // fejl. OK svar fra POST, men ansøgning findes ikke i US2000 og 360
              this.sendAnsoegningService.enableFormGroup(this.form);
              this.ufmUserTrackingService.sendUrl('upload/nyt-arbejde/fejl/receipt-id-mangler');
              const msg: string =
                'Unfortunately, a technical error occurred and we could not receive the application. user agent = ' +
                window.navigator.userAgent;
              console.error(msg);
              this.ufmFejlhaandteringService.sendFejlTilAPI(msg);
              this.toastrService.error(msg, 'Error', { tapToDismiss: true, disableTimeOut: true, closeButton: true });
            }
          },
          (error) => {
            console.error(error);
            this.senderAnsoegning = false;
            this.sendAnsoegningService.enableFormGroup(this.form);
            this.ufmUserTrackingService.sendUrl('upload/nyt-arbejde/fejl');
          },
          () => {
            if (this.senderAnsoegning) {
              this.senderAnsoegning = false;
              this.sendAnsoegningService.enableFormGroup(this.form);
              this.ufmUserTrackingService.sendUrl('upload/nyt-arbejde/fejl');
            }
            this.senderAnsoegning = false;
          }
        );
    });
  }

}
