import { Injectable } from '@angular/core';
import { SmartScrollParameter } from './smart-scroll-parameter';
import { timer } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SmartScrollService {

  constructor() { }

  // Smart scroll efter fold ud:
  // 1) scroll til bund hvis bund ikke vises
  // 2) derefter scroll til top hvis top ikke vises

  startSmartScroll(smartScrollParameter: SmartScrollParameter): void {
    smartScrollParameter.count = 0;
    smartScrollParameter.bund.nativeElement.click();
  }

  stopSmartScroll(smartScrollParameter: SmartScrollParameter): void {
    smartScrollParameter.count = -1;
  }

  erElementSynligt(element: HTMLElement): boolean {
    const bounding: ClientRect | DOMRect = element.getBoundingClientRect();
    const erSynlig: boolean = 
      bounding.top >= 0 &&
      bounding.bottom + 80 <= (window.innerHeight || document.documentElement.clientHeight);
    return erSynlig;
  }

  erBundSynlig(erSynligt: boolean, smartScrollParameter: SmartScrollParameter): void {
    if (smartScrollParameter.count === -1) {
      return;
    }
    if (smartScrollParameter.count === 0) {
      // tjek om scrollIntoView skal startes
      if (!erSynligt) {
        // vis bund. start scroll
        smartScrollParameter.bund.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'nearest' });
        timer(100).subscribe(() => {
          // tjek om bund vises
          smartScrollParameter.count = 1;
          smartScrollParameter.bund.nativeElement.click();
        });
      }
      return;
    }

    if (smartScrollParameter.count > 10) {
      // afbryd scrollIntoView for bund
      smartScrollParameter.count = 0;
      smartScrollParameter.top.nativeElement.click();
      return;
    }
    if (smartScrollParameter.count > 5 && !erSynligt) {
      // scrollIntoView af bund tager for lang tid. scroll lidt mere. sandsynligvis problem med erElementSynligt
      window.scrollBy(0, 50);
    }

    // scrollIntoView er i gang
    if (erSynligt) {
      // scroll er færdig. tjek top
      smartScrollParameter.count = 0;
      smartScrollParameter.top.nativeElement.click();
    } else {
      // scroll er stadigvæk igang. vent og tjek bund igen om 100 ms
      timer(100).subscribe(() => {
        smartScrollParameter.count++;
        smartScrollParameter.bund.nativeElement.click();
      });
    }
  }

  erTopSynlig(erSynligt: boolean, smartScrollParameter: SmartScrollParameter): void {
    if (!erSynligt) {
      // vis top
      smartScrollParameter.top.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
    }
  }

}
