import { Component } from '@angular/core';

@Component({
  selector: 'app-side-bund',
  templateUrl: './side-bund.component.html',
  styleUrls: ['./side-bund.component.scss']
})
export class SideBundComponent {

  constructor() { }

}
