<div class="row" id="kvittering">
  <app-upload-kvittering-sidehoved class="col-12"></app-upload-kvittering-sidehoved>

  <div class="col-12 pt-4">
    <h4>You stop working</h4>
  </div>

  <div class="col-12 pt-3">
    When was/is your last working day?: <strong class="ufm-black">{{ kvittering.sidsteArbejdsdag | langdato }}</strong>
  </div>

  <div class="col-12 pt-3" *ngIf="kvittering.bemaerkninger !== null">
    You can provide supplemental information here: <br />
    <strong class="ufm-black">{{ kvittering.bemaerkninger }}</strong>
  </div>

  <app-upload-kvittering-gdpr class="col-12 pt-3"></app-upload-kvittering-gdpr>
</div>
