import {Component, OnInit, EventEmitter} from '@angular/core';
import {AppService} from '../../app.service';

@Component({
  selector: 'app-dan-pdf-filer',
  templateUrl: './dan-pdf-filer.component.html',
  styleUrls: ['./dan-pdf-filer.component.scss']
})
export class PdfDanFilerComponent implements OnInit {

  dannerNytArbejdeKvittering$: EventEmitter<boolean>;
  dannerStopArbejdeKvittering$: EventEmitter<boolean>;
  dannerAfskedigelseKvittering$: EventEmitter<boolean>;
  dannerOevrigeHenvendelserKvittering$: EventEmitter<boolean>;
  dannerUarbejdsdygtigKvittering$: EventEmitter<boolean>;
  dannerFerieKvittering$: EventEmitter<boolean>;

  constructor(private appService: AppService) {}

  ngOnInit() {
    this.dannerNytArbejdeKvittering$ = this.appService.dannerNytArbejdeKvittering$;
    this.dannerStopArbejdeKvittering$ = this.appService.dannerStopArbejdeKvittering$;
    this.dannerAfskedigelseKvittering$ = this.appService.dannerAfskedigelseKvittering$;
    this.dannerOevrigeHenvendelserKvittering$ = this.appService.dannerOevrigeHenvendelserKvittering$;
    this.dannerUarbejdsdygtigKvittering$ = this.appService.dannerUarbejdsdygtigKvittering$;
    this.dannerFerieKvittering$ = this.appService.dannerFerieKvittering$;
  }

  nytArbejdeKvitteringErklar(erKlar: boolean): void {
    if (erKlar) {
      this.appService.nytArbejdeKvitteringErKlar();
    }
  }

  stopArbejdeKvitteringErklar(erKlar: boolean): void {
    if (erKlar) {
      this.appService.stopArbejdeKvitteringErKlar();
    }
  }

  afskedigelseKvitteringErklar(erKlar: boolean): void {
    if (erKlar) {
      this.appService.afskedigelseKvitteringErKlar();
    }
  }

  oevrigeHenvendelserKvitteringErklar(erKlar: boolean): void {
    if (erKlar) {
      this.appService.oevrigeHenvendelserKvitteringErKlar();
    }
  }

  uarbejdsdygtigKvitteringErklar(erKlar: boolean): void {
    if (erKlar) {
      this.appService.uarbejdsdygtigKvitteringErKlar();
    }
  }

  ferieKvitteringErklar(erKlar: boolean): void {
    if (erKlar) {
      this.appService.ferieKvitteringErKlar();
    }
  }

}
