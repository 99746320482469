import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Fil} from '../../../utils/filer/fil/fil';
import {SendAnsoegningService} from '../../../utils/service/send-ansoegning.service';

import {timer} from 'rxjs';
import {environment} from '../../../../../environments/environment';
import html2pdf from 'html2pdf.js';

@Component({
  selector: 'app-ferie-kvittering',
  templateUrl: './ferie-kvittering.component.html',
  styleUrls: ['./ferie-kvittering.component.scss']
})
export class FerieKvitteringComponent implements OnInit {

  @Input() pdf: boolean;
  @Output() pdfErKlar$ = new EventEmitter<boolean>();
  kvittering: any;
  filer: Fil[] = [];

  constructor(private sendAnsoegningService: SendAnsoegningService) { }

  ngOnInit() {
    let jsonWithEnvelope: any;
    const jsonOgFiler: any = this.sendAnsoegningService.hentSendtAnsoegning();
    if (jsonOgFiler) {
      jsonWithEnvelope = jsonOgFiler[0];
      this.kvittering = jsonWithEnvelope.ferie;
      this.filer = jsonOgFiler[1];
    }
    window.scrollTo(0, 0);
    if (this.pdf) {
      timer(0).subscribe(() => {
        this.htmltoPDF();
        window.scrollTo(0, 0);
      });
    }
  }

  htmltoPDF() {
    var element = document.getElementById('kvittering');
    const options: any = {
      margin: 10,
      filename: 'Kvittering.pdf',
      pagebreak: {mode: 'avoid-all'},
      image: {type: 'jpeg', quality: 1},
      jsPDF: {unit: 'mm', format: 'letter', orientation: 'portrait'}
    };
    if (environment.test) {
      html2pdf().set(options).from(element).save().outputPdf('blob').then((blob) => {
        this.sendAnsoegningService.gemPdfKvittering(blob);
        this.pdfErKlar$.emit(true);
      });
    } else {
      html2pdf().set(options).from(element).outputPdf('blob').then((blob) => {
        this.sendAnsoegningService.gemPdfKvittering(blob);
        this.pdfErKlar$.emit(true);
      });
    }
  }

}
