import {Injectable} from '@angular/core';
import {UntypedFormGroup, UntypedFormBuilder, Validators} from '@angular/forms';

import {Ansoegningstype} from '../../../utils/ansoegning/ansoegningstype.enum';
import {SendAnsoegningService} from '../../../utils/service/send-ansoegning.service';

@Injectable({
  providedIn: 'root'
})
export class UarbejdsdygtigService {

  constructor(private formBuilder: UntypedFormBuilder, private sendAnsoegningService: SendAnsoegningService) {}

  initialiserAnsoegningFormGroup(): UntypedFormGroup {
    return this.formBuilder.group({
      ansoegning: 'upload',
      ansoegningstype: Ansoegningstype.UploadUarbejdsdygtig,
      uarbejdsDygtig: this.formBuilder.group({
        fraDato: [null, Validators.required],
        tilDato: [null, Validators.required],
        filer: this.formBuilder.array([this.sendAnsoegningService.nyFil('sygdom')]),
        bemaerkninger: [null, Validators.required],
        godkendt: [null, Validators.requiredTrue]
      })
    });
  }
}
