import { Component, DoCheck, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';

import { UfmBrowsercacheConfigService } from './ufm-browsercache-config.service';
import { UfmBrowsercacheConfig } from './ufm-browsercache.config';
import { UfmBrowsercacheService } from './ufm-browsercache.service';
import { UfmUserTrackingService } from '@ufmit/ufm-user-tracking';

@Component({
  selector: 'ufm-tjek-browsercache',
  templateUrl: './ufm-tjek-browsercache.component.html',
  styleUrls: ['./ufm-tjek-browsercache.component.scss']
})
export class UfmTjekBrowsercacheComponent implements OnInit, DoCheck {

  erFeVersionOK: boolean = true;

  @ViewChild('htmlTjekBesked', { static: false }) htmlTjekBesked: ElementRef;
  private erUfmModulTeksterOpdateret = false;

  constructor(private router: Router, private ufmBrowsercacheConfigService: UfmBrowsercacheConfigService, private ufmBrowsercacheService: UfmBrowsercacheService, private ufmUserTrackingService: UfmUserTrackingService) { }

  ngOnInit(): void {
    this.ufmBrowsercacheConfigService.browsercacheKonfigurationOpdateret$.subscribe(opdateret => {
      if (opdateret) {
        this.opdaterTeksterPgaOpdateretKonfiguration();
      }
    });
    // tjek version i FE imod fsVersions fra API
    const version: string = this.ufmBrowsercacheConfigService.getVersion();
    if (!version) {
      console.error('environment.version er tom');
      this.erFeVersionOK = false;
      this.router.navigate(['/clear-cache']);
      return;
    }
    this.ufmBrowsercacheService.hentBrowsercache().subscribe({
      next: browsercache => {
        if (!browsercache || !browsercache.feVersions) {
          return;
        }
        this.erFeVersionOK = (browsercache.feVersions + ',').indexOf(version) !== -1;
      }
    });
  }

  ngDoCheck(): void {
    if (this.erUfmModulTeksterOpdateret || !this.htmlTjekBesked) {
      return;
    }
    this.erUfmModulTeksterOpdateret = true;
    this.opdaterTeksterPgaOpdateretKonfiguration();
  }

  private opdaterTeksterPgaOpdateretKonfiguration(): void {
    const ufmBrowsercacheConfig: UfmBrowsercacheConfig = this.ufmBrowsercacheConfigService.getBrowsercacheConfig();
    if (ufmBrowsercacheConfig.tekster.tjekBrowsercacheBesked) {
      this.htmlTjekBesked.nativeElement.innerText = ufmBrowsercacheConfig.tekster.tjekBrowsercacheBesked;
    }
  }

  clearCache(): boolean {
    this.ufmUserTrackingService.sendUrl('/clear-cache');
    this.router.navigate(['/clear-cache']);
    return false;
  }

}
