import { Pipe, PipeTransform } from '@angular/core';
import { AppService } from '../../app.service';

@Pipe({
  name: 'jaNej'
})
export class JaNejPipe implements PipeTransform {

  constructor(private appServer: AppService) { }

  transform(value: string, pdf360?: boolean): string {
    if (pdf360) {
      if (value === 'J') {
        return 'Ja';
      } else {
        return 'Nej';
      }
    } else {
      if (value === 'J') {
        return this.appServer.hentSvarJa();
      } else {
        return this.appServer.hentSvarNej();
      }
    }
  }

}
