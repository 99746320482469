import { Injectable, Injector, Inject } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { KeycloakService } from 'keycloak-angular';

import { UfmFejlhaandteringConfig, UFM_FEJLHAANDTERING_CONFIG } from './ufm-fejlhaandtering.config';
import { UFM_PROPERTIES_CONFIG, UfmPropertiesConfig } from '@ufmit/ufm-properties';

@Injectable({
  providedIn: 'root'
})
export class UfmFejlhaandteringService {

  private cacheFejlbeskeder: { [key: string]: number } = {};
  private ufmFejlhaandteringConfig: UfmFejlhaandteringConfig = {};
  private ufmPropertiesConfig: UfmPropertiesConfig = {};
  private cachedSessionid: string; // fra JWT

  constructor(@Inject(Injector) private injector: Injector, private http: HttpClient, private keycloakService: KeycloakService) {
    // hent ufmFejlhaandteringConfig (apiPropertyNavn, endpoint)
    try {
      this.ufmFejlhaandteringConfig = this.injector.get(UFM_FEJLHAANDTERING_CONFIG);
    } catch (e) { }
    // hent ufmPropertiesConfig (frontendClientLogRequestUrl)
    try {
      this.ufmPropertiesConfig = this.injector.get(UFM_PROPERTIES_CONFIG);
    } catch (e) { }
  }

  // dynamisk config getter
  private getApi(): string {
    let apiPropertyNavn = 'api';
    if (this.ufmFejlhaandteringConfig && this.ufmFejlhaandteringConfig.environment) {
      apiPropertyNavn = this.ufmFejlhaandteringConfig.environment.apiPropertyNavn || 'api';
    }
    const environment: any = this.ufmPropertiesConfig.environment || {};
    const api: string = environment[apiPropertyNavn] ? environment[apiPropertyNavn] : '/api';
    return api;
  }
  private getEndpoint(): string {
    if (this.ufmFejlhaandteringConfig.environment && this.ufmFejlhaandteringConfig.environment.endpoint) {
      return this.ufmFejlhaandteringConfig.environment.endpoint;
    } else {
      return '/frontend-client-log';
    }
  }
  private getFrontendClientLogRequestUrl(): string {
    return this.getApi() + this.getEndpoint();
  }

  private get toastrService(): ToastrService {
    return this.injector.get(ToastrService);
  }

  foldUdFejlBesked(error: any) {
    let fejlBesked: any = error;
    while (fejlBesked.error) {
      fejlBesked = fejlBesked.error;
    }
    if (fejlBesked.message) {
      return fejlBesked.message;
    } else {
      return fejlBesked;
    }
  }

  private getSessionid(): string {
    const keycloakInstance: Keycloak.KeycloakInstance = this.keycloakService.getKeycloakInstance();
    const sessionid: string = keycloakInstance.sessionId;
    if (sessionid) {
      this.cachedSessionid = sessionid;
    }
    return this.cachedSessionid || 'n/a';
  }

  public hentSessionid(): string {
    return this.getSessionid() ;
  }

  public hentKortSessionid(): string {
    return this.getSessionid().split('-')[0] ;
  }

  sendFejlTilAPI(error: any, popupBesked?: boolean) {
    // send kun fejl til API én gang. Skriv i browser log hver gang
    if (typeof popupBesked === 'undefined') {
      popupBesked = true;
    } 
    console.error(error);
    const fejlbesked = this.foldUdFejlBesked(error);
    if (!this.cacheFejlbeskeder[fejlbesked]) {
      this.cacheFejlbeskeder[fejlbesked] = 0;
    }
    this.cacheFejlbeskeder[fejlbesked]++;
    const antalFejl = this.cacheFejlbeskeder[fejlbesked];
    if (antalFejl > 1) {
      if (antalFejl.toString(10).match(/^10+$/)) {
        console.error('sendFejlTilAPI: ', fejlbesked, 'set', antalFejl, 'gange');
      }
      return;
    }
    if (popupBesked) {
      // vis toast besked
      let popupOverskrift = 'Fejl';
      if (this.ufmFejlhaandteringConfig.tekster && this.ufmFejlhaandteringConfig.tekster.popupOverskrift) {
        popupOverskrift = this.ufmFejlhaandteringConfig.tekster.popupOverskrift;
      }
      try {
        this.toastrService.error(fejlbesked, popupOverskrift, { tapToDismiss: true, disableTimeOut: true, closeButton: true });
      } catch (e) {
        // todo: fix TypeError: Cannot read property 'create' of undefined at ToastrService._buildNotification (ngx-toastr.js:568)
        console.error(e);
      }
    } 
    // log fejl
    const body: HttpParams = new HttpParams().set('fejlbesked', this.getSessionid() + ' ' + fejlbesked);
    this.http.post(
      this.getFrontendClientLogRequestUrl(),
      body.toString(),
      { headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded') }
    ).subscribe();
  }

}
