<div *ngIf="fuldmagtsBruger && fuldmagtsBruger.harPrivilegier" class="container">
  <div class="alert alert-warning mt-3">
    <h2 #htmlOverskrift i18n="@@ufmit fuldmagtsvalg overskrift">Fuldmagtsvalg</h2>
    <p class="alert-warning" *ngIf="fuldmagtsBruger.erVirksomhed && !fuldmagtsBruger.agerendeCpr" #htmlCvrAgererSomFuldmagtshaver
    i18n="@@ufmit fuldmagtsvalg cvr agerer som fuldmagtshaver">
      Du er logget på med et medarbejdercertifikat med tildelt fuldmagts privilegier til at agere som en anden bruger.
    </p>
    <p class="alert-warning" #htmlVaelgPerson i18n="@@ufmit fuldmagtsvalg vælg person">
      Vælg den person du vil repræsentere:
    </p>
    <span *ngIf="fuldmagtsBruger.egetCpr">
      <input type="radio" name="fuldmagt" [value]="fuldmagtsBruger.egetCpr"
        [checked]="fuldmagtsBruger.agerendeCpr === fuldmagtsBruger.egetCpr"
        (change)="onSelectionChange(fuldmagtsBruger.egetCpr)">
      <span #htmlDuAgererSomDigSelv i18n="@@ufmit fuldmagtsvalg dig selv">Dig selv</span>
      (CPR {{ fuldmagtsBruger.egetCpr }})<br>
    </span>
    <span *ngFor="let cpr of fuldmagtsBruger.fuldmagtsPrivilegier">
      <input type="radio" name="fuldmagt" [value]="cpr" [checked]="fuldmagtsBruger.agerendeCpr === cpr"
        (change)="onSelectionChange(cpr)"> {{cpr}}
      <ufm-fuld-type></ufm-fuld-type>
      <br>
    </span>
    <div class="row">
      <div *ngIf="fuldmagtsBruger.agerendeCpr" class="col-6 col-lg-3 col-xl-2 mt-5">
        <button (click)="annuller()" type="submit" class="mt-auto btn btn-secondary d-block w-100" #htmlAnnullerKnap i18n="@@ufmit fuldmagtsvalg annuller knap">
          Annuller
        </button>
      </div>
      <div *ngIf="!fuldmagtsBruger.agerendeCpr" class="col-6 col-lg-3 col-xl-2 mt-5">
        <button (click)="logAf()" type="submit" class="mt-auto btn btn-secondary d-block w-100" #htmlLogAfKnap i18n="@@ufmit fuldmagtsvalg log af knap">
          Log af
        </button>
      </div>
      <div class="col-6 col-lg-3 col-xl-2 mt-5">
        <button (click)="vaelg()" type="submit" class="btn btn-primary d-block w-100" #htmlVaelgKnap i18n="@@ufmit fuldmagtsvalg vælg knap">
          Vælg
        </button>
      </div>
    </div>
  </div>
</div>
<div *ngIf="erLoggetPaa && !fuldmagtsBruger" class="alert alert-warning">
  <p class="alert-warning" #htmlIngenFuldmagt i18n="@@ufmit fuldmagtsvalg ingen fuldmagt">ingen fuldmagt</p>
</div>