import { InjectionToken } from '@angular/core';

export const UFM_FULDMAGT_CONFIG = new InjectionToken<UfmFuldmagtConfig>('UFM_FULDMAGT_CONFIG');

// default tekster findes i html templates. Alternative tekster kan injectes på 3 måder:
// 1) statisk/byg: brug i18n tags. Bruges i ligestilling. Se messages.en.xlf.
// 2) statisk/byg: UfmFuldmagtModule.forRoot(ufmFuldmagtConfig) fra app.module.ts
// 3) dynamisk: opdater ufmFuldmagtConfig dynamisk efter opstart og kald ufmFuldmagtConfigService.genindlaesOpdateretKonfiguration når tekster er blevet opdateret
export interface UfmFuldmagtConfigTekster {
    fuldmagt?: {
        overskrift?: string; // i18n=@@ufmit fuldmagt overskrift: Fuldmagt
        cvrAgererSomFuldmagtshaver?: string; // i18n=@@fuldmagt cvr agerer som fuldmagtshaver: Du agerer med fuldmagtprivilegier på vegne af personen
        cprAgererSomFuldmagtshaver?: string; // i18n=@@fuldmagt cpr agerer som fuldmagtshaver: Du agerer med fuldmagtprivilegier på vegne af personen
        harFuldmagt?: string; // i18n=@@ufmit fuldmagt har fuldmagt: Du har fuldmagtsprivilegier til at agere på vegne af en anden person
        skiftBrugerKnap?: string; // i18n=@@ufmit fuldmagt skift bruger knap: Skift bruger
    };
    fuldmagtsValg?: {
        overskrift?: string; // i18n=@@ufmit fuldmagtsvalg overskrift = Fuldmagtsvalg
        cvrAgererSomFuldmagtshaver?: string; // i18n=@@ufmit fuldmagtsvalg cvr agerer som fuldmagtshaver: Du er logget på med et medarbejdercertifikat med tildelt fuldmagts privilegier til at agere som en anden bruger.
        vaelgPerson?: string; // i18n=@@ufmit fuldmagtsvalg vælg person: Vælg den person du vil repræsentere:
        duAgererSomDigSelv?: string; // i18n=@@ufmit fuldmagtsvalg dig selv: Dig selv
        fuldmagtstype?: string; // i18n=@@ufmit fuldmagtsvalg fuldmagtstype: Fuldmagt til SU-stipendie
        annullerKnap?: string; // i18n=@@ufmit fuldmagtsvalg annuller knap: Annuller
        logAfKnap?: string; // i18n=@@ufmit fuldmagtsvalg log af knap: Log af
        vaelgKnap?: string; // i18n=@@ufmit fuldmagtsvalg vælg knap: Vælg
        ingenFuldmagt?: string; // i18n=@@ufmit fuldmagtsvalg ingen fuldmagt: ingen fuldmagt
    };
    fuldmagtMangler?: {
        tekst1?: string; // i18n=@@ufmit fuldmagt mangler tekst 1: Du er logget på med et medarbejdercertifikat uden at have fået tildelt fuldmagts privilegier og kan derfor ikke foretage ændringer; hverken for dig selv eller på vegne af andre.
        tekst2?: string; // i18n=@@ufmit fuldmagt mangler tekst 2: = Hvis du forventede at kunne handle under fuldmagt på vegne af en anden bruger, skal du kontakte denne bruger og få vedkommende til at sikre fuldmagtens gyldighed ved hjælp af NemLog-in.
        logAfKnap?: string; // i18n="@@ufmit fuldmagt mangler log af knap: Log af
    };
}

export interface UfmFuldmagtConfig {
    tekster?: UfmFuldmagtConfigTekster;
    environment?: {
        apiPropertyNavn?: string; // api = environment.api. Dvs. navn på api property i environment.json filen. Bruges i /api/logout kald
    };
    apiLoginEndpoint?: 'loginsb'; // null eller loginsb hvis api/loginsb skal kaldes efter skift af fuldmagtsgiver
}
