import { Pipe, PipeTransform, Inject, LOCALE_ID } from '@angular/core';
import { DatePipe } from '@angular/common';

@Pipe({
  name: 'tid'
})
export class TidPipe implements PipeTransform {

  constructor(private datePipe: DatePipe, @Inject(LOCALE_ID) private locale: string) { }

  transform(value: Date): any {
    if (value) {
      return this.datePipe.transform(value, 'HH:mm, dd-MM-yyyy');
    }
  }

}
