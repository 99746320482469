import {Injectable} from '@angular/core';
import {FormArray, UntypedFormBuilder, UntypedFormGroup, ValidatorFn, Validators} from '@angular/forms';
import {Ansoegningstype} from '../../../utils/ansoegning/ansoegningstype.enum';
import {SendAnsoegningService} from '../../../utils/service/send-ansoegning.service';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {DatoService} from '../../../../shared/dato/dato.service';

const ValiderMindstEnPeriode: ValidatorFn = (fa: FormArray) => {
  if (!fa) {
    return null;
  }
  for (let i = 0; i < fa.length; i++) {
    const periode: UntypedFormGroup = fa.at(i) as UntypedFormGroup;
    if (periode.controls.fradato.value || periode.controls.tildato.value) {
      return null;
    }
  }
  return {required: true};
};

@Injectable({
  providedIn: 'root'
})
export class FerieService {

  private pdfKvittering: Blob;

  constructor(
    private formBuilder: UntypedFormBuilder, 
    private datoService: DatoService, 
    private sendAnsoegningService: SendAnsoegningService, 
    private http: HttpClient
  ) {}

  initialiserAnsoegningFormGroup(): UntypedFormGroup {
    return this.formBuilder.group({
      ansoegning: 'upload',
      ansoegningstype: Ansoegningstype.UploadFerie,
      ferie: this.formBuilder.group({
        feriePerioder: this.formBuilder.array([this.nyPeriode()], [ValiderMindstEnPeriode]),
        feFerieDok: this.sendAnsoegningService.nyRequiredFil('ferie'),
        ydeligereInstruktioner: null,
        ydeligereBemaerkninger: null,
        bemaerkninger: null,
        godkendt: [null, Validators.requiredTrue]
      })
    });
  }

  gemPdfKvittering(pdf: Blob) {
    this.pdfKvittering = pdf;
  }

  // ferie periode: tildato kan være efter fradato
  ValiderFeriePeriode: ValidatorFn = (fg: UntypedFormGroup) => {
    if (!fg) {
      return null;
    }
    const fradato1: any = fg.controls.fradato ? fg.controls.fradato.value : null;
    const fradato2: Date = this.datoService.konverterTilUTCDato(fradato1);
    const tildato1: any = fg.controls.tildato ? fg.controls.tildato.value : null;
    const tildato2: Date = this.datoService.konverterTilUTCDato(tildato1);
    try {
      // intet er udfyldt
      if (!fradato2 && !tildato2) {
        return {intetUdfyldt: true};
      }
      // fra dato er ikke udfyldt
      if (!fradato2) {
        return {fradato: {required: true}};
      }
      // til dato er ikke udfyldt.
      if (!tildato2) {
        return {tildato: {required: true}};
      }
      // fra dato er udfyldt, men fra dato er større end til dato
      if (fradato2.getTime() > tildato2.getTime()) {
        return {ugyldigPeriode: true};
      }
    } catch (e) {
      console.error(e);
    }
  };

  nyPeriode(): UntypedFormGroup {
    return this.formBuilder.group({
      fradato: [null],
      tildato: [null]
    }, {validator: this.ValiderFeriePeriode});
  }

  sendAnsoegning(formData: FormData): Observable<any> {
    formData.append('feKvittering', this.pdfKvittering, 'Kvittering.pdf');
    return this.http.post('/api/upload', formData, {headers: {NoInterceptorTimeout: 'x'}});
  }
}
